// Core
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';
import InputField from 'components/commonComponents/InputField';
import Button from 'components/commonComponents/Button';

// Styles
import './styles.scss';
import { Alert, Modal } from 'antd';
import { APP_ID } from 'config/constants';


const ResetPassword = (props) => {
  const { onClose, open, email, otp } = props;
  const { t } = useTranslation();
  const [passwordSet, setPasswords] = useState({});
  const [errorPswd, setErrorPswd] = useState({});
  const [isLoadingPswd, setIsLoadingPswd] = useState(false);
  const { newPassword, confirmPassword } = passwordSet;
  const [errorAlert, setErrorAlert] = useState({ msg: '', key: Math.random(), open: false, severity: 'error' })

  const handleOnChangePassword = (key) => (_value) => {
    if (_value) {
      setErrorPswd({
        ...errorPswd,
        [key]: null
      });
    }
    setPasswords({
      ...passwordSet,
      [key]: _value
    });
  };


  const handlePasswordSave = async () => {
    const errors = {
      newPassword: !newPassword,
      confirmPassword: !confirmPassword
    };

    setErrorPswd(errors);
    let isFormValid = Object.values(errors).every((item) => !item);
    if (newPassword !== confirmPassword) {
      setErrorPswd((prev) => ({ ...prev, confirmPassword: 'Passwords must be same' }));
      isFormValid = false;
    }
    if (!isFormValid) return;
    setIsLoadingPswd(true);
    const formData = new FormData();
    formData.append('email', email)
    formData.append('otp', otp)
    formData.append('newPassword', newPassword)
    formData.append('appId', APP_ID)

    const res = await fetchData(restAPIs.resetPassword(formData));
    if (res.statusCode === 200) {
      setPasswords({});
      setErrorPswd({});
      setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true, severity: 'success' });
      setTimeout(() => {
        setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
      }, 3000);
      handleClose();
    } else {
      setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true, severity: 'error' });
      setTimeout(() => {
        setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
      }, 3000);
    }
    setIsLoadingPswd(false);
  };

  const handleClose = (event, reason) => {
    setPasswords({});
    setErrorPswd({});
    setErrorAlert({})
    onClose();
    // if (reason === "backdropClick") {
    //   console.log(reason);
    // } else {
    //   onClose();
    // }
  };


  return (
    <>
      <Modal open={open} footer={null} onCancel={handleClose} closable={false} className='__reset_modal' keyboard={false} maskClosable={false}>
        <div className='__reset_content'>
          <h3 className='____reset_head'>{t('reset_password')}</h3>
          <div style={{ marginBottom: '20px', width: '100%' }}>
            <InputField
              onChange={handleOnChangePassword('newPassword')}
              value={newPassword}
              placeholder={t('new_password')}
              type='text'
              error={errorPswd.newPassword}
            />
          </div>
          <div style={{ marginBottom: '20px', width: '100%' }}>
            <InputField
              onChange={handleOnChangePassword('confirmPassword')}
              value={confirmPassword}
              placeholder={t('confirm_password')}
              type='text'
              error={errorPswd.confirmPassword}
            />
          </div>
          <div className='d-flex align-items-center justify-content-end' style={{ marginTop: ' 15px', gridGap: '10px' }}>
            <a onClick={() => handleClose(false)} style={{ cursor: 'pointer', margin: '0 10px' }}>{t('cancel')}</a>
            <Button
              withLoader
              loading={isLoadingPswd}
              disabled={isLoadingPswd}
              handleOnClick={handlePasswordSave}
              label={t('submit')}
            />
          </div>
        </div>
        {errorAlert && errorAlert.open ?
          <Alert type={errorAlert.type} showIcon message={errorAlert.msg} />
          : ''}
      </Modal>
    </>
  );
};

export default ResetPassword;

ResetPassword.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  email: PropTypes.any,
  otp: PropTypes.any,
};
