// Core
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';

import Button from 'components/commonComponents/Button';

// Styles
import './styles.scss';
import OtpInput from 'react-otp-input';
import { useDispatch, useSelector } from 'react-redux';
import { APP_ID } from 'config/constants';
import { Alert, Modal } from 'antd';

// import ResetPassword from '../ResetPassword';

const Otp = (props) => {
  const { userDetails } = useSelector((store) => store.commonData);
  const { onClose, open, email, type, value } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [otp, setData] = useState('');
  const [otpTemp, setDataTemp] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorAlert, setErrorAlert] = useState({ msg: '', key: Math.random(), open: false, type: '' })
  const [resetOpen, setResetOpen] = useState(false);

  const handleOnChange = (value) => {
    setData(value)
  };

  const handleSave = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('email', email)
    formData.append('otp', otp)
    formData.append('appId', APP_ID)
    setDataTemp(otp)
    const res = type == 'email' ? await fetchData(restAPIs.changeEmail(formData)) : await fetchData(restAPIs.otpVerify(formData));
    if (res.statusCode === 200) {
      setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true, type: 'success' });
      setTimeout(() => {
        setErrorAlert({ msg: '', key: Math.random(), open: false, type: '' });
      }, 3000);

      if (type == 'email') {
        let localDetails = JSON.parse(localStorage.getItem('userDetails'));
        localDetails.email = email
        dispatch({
          type: 'userDetails',
          value: localDetails
        });
        localStorage.setItem('userDetails', JSON.stringify(localDetails));
        handleClose();
      }

      if (type == 'signup') {
        let full = { ...value, ...res.user }
        dispatch({
          type: 'userDetails',
          value: full
        });
        localStorage.setItem('userDetails', JSON.stringify(full));
        localStorage.setItem('sessiontoken', value.sessionToken);
        handleClose();
      }

      if (type == 'login') {
        onClose()
        dispatch({
          type: 'userDetails',
          value: res.user
        });
        localStorage.setItem('userDetails', JSON.stringify(res.user));
        localStorage.setItem('sessiontoken', res.user.sessionToken);
        Modal.destroyAll();
      }
      if (type == 'forgot') {
        setResetOpen(true);
      }
      setData('');

    } else {
      setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true, type: 'error' });
      setTimeout(() => {
        setErrorAlert({ msg: '', key: Math.random(), open: false, type: '' });
      }, 3000);
    }
    setIsLoading(false);
  };

  const handleClose = () => {
    setData('');
    setErrorAlert({})
    onClose();
  };

  const handleCloseReset = () => {
    setResetOpen(false);
    onClose();
  };


  return (
    <>
      <Modal
        open={open}
        onCancel={handleClose}
        className='__common_model_class'
        title={'OTP'}
        keyboard={false}
        maskClosable={false}
        footer={null}
        closable={false}
      >
        <div className='__otp_content'>
        <h1 className='mt-3' style={{fontSize:'1.3rem', marginBottom : '10px'}}>An OTP is sent to your email for verification</h1>
                  <h1 className='mt-2'>Please check your email inbox or spam folders for the OTP email.</h1>
          <div className='mt-4'>
            <OtpInput
              value={otp}
              onChange={handleOnChange}
              numInputs={4}
              isInputNum={true}
              className='_custom_input'
              renderInput={(props) => <input {...props} />}
            />
          </div>
        </div>
        <div>
          <div className='d-flex align-items-center justify-content-end' style={{ marginTop: ' 15px', gridGap: '10px' }}>
            <a onClick={() => handleClose(false)} style={{ cursor: 'pointer', margin: '0 10px' }}>{t('cancel')}</a>
            <Button
              withLoader
              loading={isLoading}
              disabled={isLoading || otp.length != 4}
              handleOnClick={handleSave}
              label={t('submit')}
              style={{ margin: 0 }}
            />
          </div>
        </div>

        {errorAlert && errorAlert.open ? <div className='mt-3'>
          <Alert type={errorAlert.type} showIcon message={errorAlert.msg} />
        </div>
          : ''}

        {/* <ResetPassword onClose={handleCloseReset} open={resetOpen} email={email} otp={otpTemp} /> */}
      </Modal>
    </>
  );
};

export default Otp;


Otp.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  type: PropTypes.any,
  email: PropTypes.any,
  value: PropTypes.any,
};
