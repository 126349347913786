// Core
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/commonComponents/Button';
import PropTypes from 'prop-types';


// Styles
import './styles.scss';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { useState } from 'react';
import { Collapse, Modal } from 'antd';

const { Panel } = Collapse;

const FAQ = (props) => {
  const { currentLang } = useSelector((store) => store.commonData);
  const { onClose, open, subSubjectId, subjectId } = props;
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      onClose();
    }
  };

  const fetchFAQ = async () => {
    const res = await fetchData(restAPIs.getChatFAQ(subjectId, currentLang?.id));
    if (res.statusCode === 200) {
      setData(res?.AiFAQ)
    }
    setLoading(false);
  }
  useEffect(() => {
    fetchFAQ();
  }, []);


  return (
    <>


      <Modal
        open={open}
        onClose={handleClose}
        className='__common_model_class'
        footer={null}
        closable={false}
      >
        <div className='__common_model_content'>
          <h3 className='____common_model_class_head' style={{ marginBottom: '10px' }}> {t('frequently_asked_questions')}</h3>
          <div className='.____common_model_class_desc'>

            <Collapse ghost className='__collapse__items' accordion >
              {data && data?.map(item => (
                <Panel key={item.id} header={item.name}>
                  <p className='__descr' style={{ padding: '0 30px' }} dangerouslySetInnerHTML={{ __html: item.description }} ></p>
                </Panel>
              ))}
            </Collapse>
          </div>
          <div className='d-flex align-items-center justify-content-end' style={{ marginTop: ' 15px', gridGap: '10px' }}>
            <Button
              label={t('cancel')}
              handleOnClick={() => handleClose(false)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FAQ;

FAQ.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  subjectId: PropTypes.string,
  subSubjectId: PropTypes.string,
};


