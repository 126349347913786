// Core
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// assets
import { Alert, Modal } from 'antd';

// Style
import './styles.scss';
import Button from 'components/commonComponents/Button';
import InputField from 'components/commonComponents/InputField';

import FacebookLogo from 'assets/images/facebookLogo.png'
import GoogleLogo from 'assets/images/googleLogo.png'

import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';
import { APP_ID } from 'config/constants';
import { useDispatch, useSelector } from 'react-redux';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import validator from 'validator';
import Otp from '../Otp';
import OtpInput from 'react-otp-input';
import ResetPassword from '../ResetPassword';

import {
  LoginSocialGoogle,
  LoginSocialFacebook,
} from 'reactjs-social-login'
import { FACEBOOK_ID, GOOGLE_ID } from 'config/constants';

import Logo from 'assets/images/logo-EV-4-dark green.png'

const REDIRECT_URI = window.location.href;

const Login = (props) => {
  const { t } = useTranslation();
  const { open, onClose } = props;
  const { currentLang, userDetails } = useSelector((store) => store.commonData);
  const dispatch = useDispatch();

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [loginEye, setLoginEye] = useState(true);
  const [islogin, setIsLogin] = useState(true);
  const [signupEye, setSignupEye] = useState(true)
  const [signupData, setSignupData] = useState({});
  const [signupLoading, setSignupLoading] = useState(false);
  const [signupError, setSignupError] = useState('');
  const [response, setSignupResp] = useState({});
  const [openOtp, setOpenOtp] = useState(false);
  const [errorAlert, setErrorAlert] = useState({ msg: '', key: Math.random(), open: false, type: '' })

  const [type, setOTPType] = useState('')
  const [otpEmail, setOTPEmail] = useState('')

  const [otp, setDataOTP] = useState('');
  const [otpTemp, setDataOTPTemp] = useState('');
  const [isLoadingOTP, setIsLoadingOTP] = useState(false);

  const [openForget, setopenForgot] = useState(false)
  const [dataSetForgot, setDataForgot] = useState({});
  const [errorForgot, setErrorForgot] = useState('');
  const [isLoadingForgotEmail, setIsLoadingForgot] = useState(false);
  const { forgotemail } = dataSetForgot;

  const [resetOpen, setResetOpen] = useState(false)
  const [profile, setProfile] = useState(null)

  const { email = '', password = '' } = data;
  const { name, signup_email = '', signup_password = '' } = signupData;

  const handleOnChange = (key) => (value) => {
    setError({
      ...error,
      [key]: null
    });
    setData({
      ...data,
      [key]: value
    });
  };

  const handleLoginIn = async () => {
    const errors = {
      email: !validator.isEmail(email) && 'Enter valid email',
      password: !password && 'You must enter a value'
    };
    setError(errors);
    const isFormValid = Object.values(errors).every((item) => !item);
    if (!isFormValid) return;
    const formData = new FormData();
    formData.append('email', data.email.toLowerCase())
    formData.append('password', data.password)
    formData.append('appid', APP_ID)
    setLoading(true);
    const res = await fetchData(restAPIs.login(formData));
    setOTPEmail(data.email.toLowerCase())
    if (res.statusCode === 200) {
      dispatch({
        type: 'userDetails',
        value: res.userinfo
      });
      localStorage.setItem('userDetails', JSON.stringify(res.userinfo));
      localStorage.setItem('sessiontoken', res.userinfo.sessionToken);
      onClose();
    }
    else if (res.statusCode === 100) {
      setOpenOtp(true);
      setOTPType('login')
    }
    else {
      setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true, type: 'error' });
      setTimeout(() => {
        setErrorAlert({ msg: '', key: Math.random(), open: false });
      }, 3000);
    }
    setLoading(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleLoginIn();
    }
  };

  const loginEyeClick = () => {
    setLoginEye(!loginEye);
  }

  const handleIsLoginClick = () => {
    setIsLogin(!islogin);
  }

  const handleOnChangeSignUp = (key) => (value) => {
    setSignupError({
      ...signupError,
      [key]: null
    });
    setSignupData({
      ...signupData,
      [key]: value
    });
  };

  const signupEyeClick = () => {
    setSignupEye(!signupEye)
  }

  const handleSignUp = async () => {
    const errors = {
      name: !name && 'You must enter a value',
      signup_email: !validator.isEmail(signup_email) && 'Enter valid email',
      signup_password: !signup_password && 'You must enter a value'
    };
    setSignupError(errors);
    const isFormValid = Object.values(errors).every((item) => !item);
    if (!isFormValid) return;
    const formData = new FormData();
    formData.append('fullname', signupData.name)
    formData.append('email', signupData.signup_email)
    formData.append('password', signupData.signup_password)
    formData.append('auth', '0')
    formData.append('language', currentLang?.id)
    formData.append('appid', APP_ID)
    setSignupLoading(true);
    setOTPEmail(signupData.signup_email.toLowerCase())
    const res = await fetchData(restAPIs.signUp(formData));
    console.log("rees", res);
    if (res.statusCode === 200) {
      setSignupResp(res.user)
      setOpenOtp(true)
      setOTPType('signup')
      setSignupData({})
    } else {
      setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true, type: 'error' });
      setTimeout(() => {
        setErrorAlert({ msg: '', key: Math.random(), open: false });
      }, 3000);
    }
    setSignupLoading(false);
  }

  const handleClose = () => {
    onClose();
    setDataForgot('')
    setopenForgot(false)
  }

  const handleSaveOTP = async () => {
    setIsLoadingOTP(true);
    const formData = new FormData();
    formData.append('email', otpEmail)
    formData.append('otp', otp)
    formData.append('appId', APP_ID)
    setDataOTPTemp(otp)
    const res = await fetchData(restAPIs.otpVerify(formData));
    if (res.statusCode === 200) {
      setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true, type: 'success' });
      setTimeout(() => {
        setErrorAlert({ msg: '', key: Math.random(), open: false, type: '' });
      }, 3000);

      if (type == 'signup') {
        let full = { ...response, ...res.user }
        dispatch({
          type: 'userDetails',
          value: full
        });
        localStorage.setItem('userDetails', JSON.stringify(full));
        localStorage.setItem('sessiontoken', response.sessionToken);
        handleClose();
      }

      if (type == 'login') {
        dispatch({
          type: 'userDetails',
          value: res.user
        });
        localStorage.setItem('userDetails', JSON.stringify(res.user));
        localStorage.setItem('sessiontoken', res.user.sessionToken);
        handleClose();
      }
      if (type == 'forgot') {
        setResetOpen(true);
      }
      setDataOTP('');
      setOpenOtp(false);
    } else {
      setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true, type: 'error' });
      setTimeout(() => {
        setErrorAlert({ msg: '', key: Math.random(), open: false, type: '' });
      }, 3000);
    }
    setIsLoadingOTP(false);
  };

  const handleOnChangeOTP = (value) => {
    setDataOTP(value)
  };

  const handleCancelOTP = () => {
    setOpenOtp(false)
    setDataOTP('')
  }

  const handleOnChangeForgot = (key) => (_value) => {
    if (_value) {
      setErrorForgot({
        ...errorForgot,
        [key]: null
      });
    }
    setDataForgot({
      ...dataSetForgot,
      [key]: _value
    });
  };


  const handleSaveForgotEmail = async () => {
    const errors = {
      forgotemail: !validator.isEmail(forgotemail) && 'Enter valid email',
    };

    setErrorForgot(errors);
    let isFormValid = Object.values(errors).every((item) => !item);
    if (!isFormValid) return;
    setIsLoadingForgot(true);
    const formData = new FormData();
    formData.append('email', forgotemail)
    formData.append('appId', APP_ID)
    setOTPEmail(forgotemail.toLowerCase())
    const res = await fetchData(restAPIs.forgotPassword(formData));
    if (res.statusCode === 200) {
      setErrorForgot({});
      setOpenOtp(true);
      setOTPType('forgot')

    } else {
      setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true, severity: 'error' });
      setTimeout(() => {
        setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
      }, 3000);
    }
    setIsLoadingForgot(false);
  };

  const handleCloseForgot = () => {
    setDataForgot('')
    setopenForgot(false)
  }

  const handleCloseReset = () => {
    setDataForgot('')
    setopenForgot(false)
    setOpenOtp(false)
    setResetOpen(false)
  }

  const handleSocialLogin = async (user, auth) => {
    const formData = new FormData();
    formData.append('fullname', user.name)
    formData.append('email', user.email)
    formData.append('auth', auth.toString())
    formData.append('language', currentLang?.id)
    formData.append('appid', APP_ID)
    // setLoading(true);
    const res = await fetchData(restAPIs.signUp(formData));
    if (res.statusCode === 200) {
      dispatch({
        type: 'userDetails',
        value: res.user
      });
      localStorage.setItem('userDetails', JSON.stringify(res.user));
      localStorage.setItem('sessiontoken', res.user.sessionToken);
      handleClose();
    } else {
      setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true });
      setTimeout(() => {
        setErrorAlert({ msg: '', key: Math.random(), open: false });
      }, 3000);
    }
  }

  const onLoginStart = useCallback(() => {
    // alert('login start')
  }, []);

  const onLogoutSuccess = useCallback(() => {
    setProfile(null)
    // alert('logout success')
  }, []);

  return (
    <>
      <Modal open={open} footer={null} onCancel={handleClose} closable={true} className='__login_modal' keyboard={false} maskClosable={false}>
        <div className='__login_full_wrap'>
        <div className='__login_wrap_left'>
            <div className='__left_inner'>
              <div className="__login__header">
                <h3 className="__login__title">{t('welcome_to')}</h3>
              </div>
              <div className="__login__content">
                <img src={Logo} alt="" />
              </div>
              {!openForget && <>{islogin ? <div className="__login__footer">{t('new_here')} <strong className="link-underlined"><a onClick={handleIsLoginClick}>{t('signup_now')}</a></strong></div>
                :
                <div className="__login__footer">{t('already_a_member')} <strong className="link-underlined"><a onClick={handleIsLoginClick}>{t('sign_in')}</a></strong></div>
              }</>}
            </div>

        </div>
        <div className='__login_wrap_right'>
        {
          !openOtp && !openForget ?
            <div className='__log_content'>
              {errorAlert && errorAlert.open ?
                <Alert type={errorAlert.type} showIcon message={errorAlert.msg} />
                : ''}
              {
                islogin ?
                  <>
                    <h3 className='__loginhead'>{t('sign_in')}</h3>
                    <div style={{ marginBottom: '20px', width: '100%' }}>
                      <InputField
                        onChange={handleOnChange('email')}
                        value={email}
                        type="email"
                        placeholder={t('email')}
                        error={error.email}
                        onKeyDown={handleKeyDown}
                      />
                    </div>
                    <div style={{ marginBottom: '40px', width: '100%' }}>
                      <InputField
                        onChange={handleOnChange('password')}
                        value={password}
                        placeholder={t('password')}
                        type={loginEye ? 'password' : 'text'}
                        error={error.password}
                        onKeyDown={handleKeyDown}
                        onIconClick={loginEyeClick}
                        icon={loginEye ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                      />
                    </div>
                    <Button
                      withLoader
                      loading={loading}
                      disabled={loading}
                      handleOnClick={handleLoginIn}
                      label={t('login')}
                      style={{ width: '100%' }}
                    />
                    <p className='__forget' ><strong className="link-underlined"><a onClick={() => setopenForgot(true)}>{t('forget_password')} ?</a></strong></p>
                    <div className='__login__footer mt-3 text-justify' style={{width:'100%', textAlign :'justify'}}>{t('new_here')} <strong className="link-underlined"><a onClick={handleIsLoginClick}>{t('signup_now')}</a></strong></div>
                    
                  </>
                  : <>
                    <h3 className='__loginhead'>{t('sign_up')}</h3>
                    <div style={{ marginBottom: '20px', width: '100%' }}>
                      <InputField
                        onChange={handleOnChangeSignUp('name')}
                        value={name}
                        type="text"
                        placeholder={t('name')}
                        error={signupError.name}
                      />
                    </div>
                    <div style={{ marginBottom: '20px', width: '100%' }}>
                      <InputField
                        onChange={handleOnChangeSignUp('signup_email')}
                        value={signup_email}
                        type="email"
                        placeholder={t('email')}
                        error={signupError.signup_email}
                      />
                    </div>
                    <div style={{ marginBottom: '40px', width: '100%' }}>
                      <InputField
                        onChange={handleOnChangeSignUp('signup_password')}
                        value={signup_password}
                        placeholder={t('password')}
                        type={signupEye ? 'password' : 'text'}
                        error={signupError.signup_password}
                        onIconClick={signupEyeClick}
                        icon={signupEye ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                      />
                    </div>
                    <Button
                      withLoader
                      loading={signupLoading}
                      disabled={signupLoading}
                      handleOnClick={handleSignUp}
                      label={t('sign_up')}
                      style={{ width: '100%' }}
                      className='mt-3'
                    />

              <div className='mt-3 text-justify' style={{width:'100%', textAlign :'justify'}}>{t('already_a_member')} <strong className="link-underlined"><a onClick={handleIsLoginClick}>{t('sign_in')}</a></strong></div>
                    
                  </>
              }
              <div className='__border'></div>
              <div className='_______social_media'>
                <button className='__facebook'>

                  <LoginSocialFacebook

                    fieldsProfile={
                      'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
                    }
                    redirect_uri={REDIRECT_URI}
                    appId={FACEBOOK_ID}
                    onLoginStart={onLoginStart}
                    onResolve={({ data }) => {
                      setProfile(data)
                      handleSocialLogin(data, 1)
                    }}
                    onReject={(err) => {
                      console.log(err)
                    }}

                  >
                    <div style={{ display: 'flex', gridGap: '10px', alignItems: 'center' }}>
                      <img src={FacebookLogo} alt="Facebook" /> {t('sign_in_with_facebook')}
                    </div>

                  </LoginSocialFacebook>


                </button>
                <button className='__google' >

                  <LoginSocialGoogle

                    redirect_uri={REDIRECT_URI}
                    scope="openid profile email"
                    discoveryDocs="claims_supported"
                    access_type="offline"
                    client_id={GOOGLE_ID}
                    onLoginStart={onLoginStart}
                    onResolve={({ provider, data }) => {
                      setProfile(data)
                      handleSocialLogin(data, 2)
                    }}
                    onReject={(err) => {
                      console.log(err)
                    }}
                  >
                    <div style={{ display: 'flex', gridGap: '10px', alignItems: 'center' }}>
                      <img src={GoogleLogo} alt="Google" /> {t('sign_in_with_google')}
                    </div>
                  </LoginSocialGoogle>

                </button>
              </div>
              <div className='__border'></div>
              <p className='__login__footer text-center'>{t('ev_account_can_be')}</p>
            </div>
            :
            !openOtp && openForget ?
              <>
                <div className='__log_content'>
                  <div style={{width:'100%'}}>
                  <div className='mb-5'>
                  <h3 className='__loginhead'>{t('forget_password')}</h3>
                    <p>Enter your email address and we will send an one time passowrd (OTP) to reset your password.</p>
                  </div>
                  <div style={{ marginBottom: '20px', width: '100%' }}>
                    <InputField
                      onChange={handleOnChangeForgot('forgotemail')}
                      value={forgotemail}
                      placeholder={t('enter_your_email_address')}
                      type='email'
                      error={errorForgot.forgotemail}
                    />
                  </div>
                  <div className='d-flex align-items-center justify-content-end flex-column' style={{ marginTop: ' 30px', gridGap: '30px' }}>
                    <Button
                      withLoader
                      loading={isLoadingForgotEmail}
                      disabled={isLoadingForgotEmail}
                      handleOnClick={handleSaveForgotEmail}
                      label={t('reset_password')}
                      style={{width:'100%'}}
                    />
                    <strong className="link-underlined"><a onClick={() => handleCloseForgot()}>{t('back')}</a></strong>
                  </div>
                  {errorAlert && errorAlert.open ?
                    <Alert type={errorAlert.type} showIcon message={errorAlert.msg} />
                    : ''}
                  </div>
                </div>
              </>
              :
              <div className='__log_content'>
                <div>
                <div className='__otp_content'>
                  <h1 className='mt-3' style={{fontSize:'1.3rem', marginBottom : '10px'}}>An OTP is sent to your email for verification</h1>
                  <h1 className='mt-2'>Please check your email inbox or spam folders for the OTP email.</h1>
                  <div className='mt-4'>
                    <OtpInput
                      value={otp}
                      onChange={handleOnChangeOTP}
                      numInputs={4}
                      isInputNum={true}
                      className='_custom_input'
                      renderInput={(props) => <input {...props} />}
                    />
                  </div>
                </div>
                <div>
                  <div className='d-flex align-items-center justify-content-end' style={{ marginTop: ' 15px', gridGap: '10px' }}>
                    <a onClick={() => handleCancelOTP(false)} style={{ cursor: 'pointer', margin: '0 10px' }}>{t('cancel')}</a>
                    <Button
                      withLoader
                      loading={isLoadingOTP}
                      disabled={isLoadingOTP || otp.length != 4}
                      handleOnClick={handleSaveOTP}
                      label={t('submit')}
                      style={{ margin: 0 }}
                    />
                  </div>
                  {errorAlert && errorAlert.open ?
                    <Alert type={errorAlert.type} showIcon message={errorAlert.msg} />
                    : ''}
                </div>
                </div>
              </div>}
        </div>
        </div>
        <ResetPassword email={otpEmail} onClose={handleCloseReset} open={resetOpen} otp={otpTemp} />
      </Modal>
    </>
  );
};

export default Login;

Login.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};
