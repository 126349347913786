// Core
import React, { useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Styles
import './styles.scss';
import { useSelector } from 'react-redux';
import Login from 'components/modals/Login';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { useEffect } from 'react';

import Button from 'components/commonComponents/Button';
import { ArrowRightOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { Fade } from 'react-reveal';
import InputField from 'components/commonComponents/InputField';

// icons

const Subscription = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const { userDetails, currentLang } = useSelector((store) => store.commonData);
  const [data, setData] = useState('')
  const [appData, setAppData] = useState('')
  const [isLoading, setLoading] = useState(false)
  const { appId } = useParams();
  const [referralCode, setreferralCode] = useState('')
  const [discountCode, setdiscountCode] = useState('')
  const [searchParams] = useSearchParams();
  const [dataSet, setDataSet] = useState({});
  const [error, setError] = useState('');
  const [isLoadingApply, setIsLoadingApply] = useState(false);
  const { code } = dataSet;
  const [errorAlert, setErrorAlert] = useState({ msg: '', key: Math.random(), open: false, severity: '' })


  const handleClick = (id) => {
    userDetails ? referralCode ? navigate('/subscription-payment/' + id + '/' + appId + '?refcode=' + referralCode) : discountCode ? navigate('/subscription-payment/' + id + '/' + appId + '?discountCode=' + discountCode) : navigate('/subscription-payment/' + id + '/' + appId) : setShow(true)
  }

  const handleCloseModel = () => {
    setShow(false);
  };

  const fetchSubscriptions = async () => {
    setreferralCode(searchParams.get('refcode'))
    setdiscountCode(searchParams.get('discountCode'))
    setLoading(true)
    const formData = new FormData();
    formData.append('language', currentLang?.id)
    formData.append('appId', appId)
    if (searchParams.get('refcode'))
      formData.append('refCode', searchParams.get('refcode'))
    if (searchParams.get('discountCode'))
      formData.append('discountCode', searchParams.get('discountCode'))
    const res = await fetchData(restAPIs.getSubscriptions(formData));
    setData(res.info);
    setLoading(false)
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setreferralCode(searchParams.get('refcode'))
    setdiscountCode(searchParams.get('discountCode'))
    fetchSubscriptions();
    fetchApp();
  }, [currentLang]);


  const fetchApp = async () => {
    setLoading(true)
    const res = await fetchData(restAPIs.applicationListing(currentLang?.id, 1, appId));
    setAppData(res.applications[0])
    setLoading(false);
  }


  const handleSave = async () => {
    const errors = {
      code: !code,
    };

    setError(errors);
    let isFormValid = Object.values(errors).every((item) => !item);
    if (!isFormValid) return;
    setdiscountCode(code);
    setIsLoadingApply(true);
    setLoading(true);
    const formData = new FormData();
    formData.append('language', currentLang?.id);
    formData.append('appId', appId);
    formData.append('discountCode', code);
    const res = await fetchData(restAPIs.getSubscriptions(formData));
    setData(res.info);
    setError({});
    if (!res.isCodeValid) {
      setErrorAlert({ msg: 'Please check your code', key: Math.random(), open: true, severity: 'error' });
      setTimeout(() => {
        setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
      }, 3000);
    }
    setIsLoadingApply(false);
    setLoading(false);
  }

  const handleOnChange = (key) => (_value) => {
    if (_value) {
      setError({
        ...error,
        [key]: null
      });
    }
    setDataSet({
      ...dataSet,
      [key]: _value
    });
  };

  return (
    <>

      <div className='container-fluid __articles_list' style={{ paddingTop: '11vh', paddingBottom: '6%' }}>
        <div className='inner'>
          <div className="c-heading text-center">
            <div className="c-heading__top">
              <h2 className="text-default"> {appData?.name} </h2>
            </div>
            <div className="c-heading__middle">
              <h3 className="heading-3 text-uppercase">{t('subscription')}</h3>
            </div>
            <div className="c-heading__bottom">
              <div className="c-heading__short-desc">
                <span>{appData?.tagline}</span>
              </div>
            </div>
          </div>
        </div>

        <div className='block'>
          <div className='inner'>
            <div className='row justify-content-center mb-2'>
              <div className='col-md-6 d-flex align-items-end justify-content-center __dis_inp' style={{ gridGap: '10px' }}>
                <InputField
                  onChange={handleOnChange('code')}
                  value={code}
                  placeholder={'Discount Code'}
                  type='text'
                  label={'Discount Code'}
                  error={error.code}
                />
                <Button
                  style={{ margin: 0 }}
                  withLoader
                  loading={isLoadingApply}
                  disabled={isLoadingApply}
                  handleOnClick={handleSave}
                  label={t('submit')}
                />
              </div>
            </div>
            {errorAlert && errorAlert.open ?
              <p className='error_mg'>{errorAlert.msg}</p> : ''}
          </div>

        </div>
        <div className="block ">
          <div className="inner">
            <div className="has-sticker">
              {isLoading ?
                <div className="row justify-content-center">
                  <div className='text-center'>
                    <Spin className='__spin_color' size="large" tip="Loading" />
                  </div>
                </div> :
                <div className="row justify-content-center" style={{ gap: '40px' }}>
                  {data && data.map((item, idx) => {
                    return (
                      <Fade left cascade>
                        <div className="col-md-4 mb-4 mt-5" key={idx}>
                          <div className="__subBlock" style={{ border: item?.duration !== 30 ? '3px solid #e3a83a' : '' }}>
                            {item?.duration !== 30 ? <span className='recommend'>{t('recommended')}</span> : ''}
                            <h1>{item?.duration === 30 ? t('monthly') : t('yearly')} </h1>
                            <h2>AED {item?.price}.00</h2>
                            <p><CheckCircleOutlined style={{ color: '#18B943', margin: '0 10px' }} />{item?.subscriptionName}</p>
                            <p><CheckCircleOutlined style={{ color: '#18B943', margin: '0 10px' }} />{item?.descline1}</p>
                            <p><CheckCircleOutlined style={{ color: '#18B943', margin: '0 10px' }} />{item?.descline2}</p>
                            <p><CheckCircleOutlined style={{ color: '#18B943', margin: '0 10px' }} /><b style={{ margin: '0 2px' }} >{item?.duration}</b> {t('days')}</p>
                            <p className='mb-4'><CheckCircleOutlined style={{ color: '#18B943', margin: '0 10px' }} />AED {item?.price}.00</p>
                            <Button
                              label={item?.duration === 30 ? t('subscribe_monthly') : t('subscribe_yearly')}
                              icon={<ArrowRightOutlined />}
                              handleOnClick={() => handleClick(item?.subscriptionId)}
                              style={{ marginLeft: '0', width: '100%' }}
                            />
                          </div>
                        </div>
                      </Fade>

                    )
                  })}

                </div>
              }
            </div>
          </div>
        </div>
      </div>

      <Login
        open={show}
        onClose={handleCloseModel}
      />

    </>
  );
};

export default Subscription;