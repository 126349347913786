// Core
import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
// Style
import { InputWrapper } from './style';
import './styles.scss'; 

const InputField = (props) => {
  const { type, placeholder, onChange, value = '', label, error, onKeyDown, icon, onIconClick, disabled } = props;
  const onChangeHandle = (e) => onChange?.(e.target.value);
  return (
    <InputWrapper error={error} className='__arb'>
      {label && <label>{label}</label>}
      <input
        autoComplete="off"
        value={value}
        placeholder={placeholder}
        onChange={onChangeHandle}
        type={type}
        onKeyDown={onKeyDown}
        disabled={disabled}
      />
      {!!error && <Fade bottom collapse><p>{error}</p></Fade>}
      {icon && <div className='__icon' onClick={onIconClick}>{icon}</div>}
    </InputWrapper>
  );
};

export default InputField;

InputField.propTypes = {
  label: PropTypes.any,
  error: PropTypes.any,
  value: PropTypes.any,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  onKeyDown: PropTypes.func,
  onChange: PropTypes.func,
  icon:PropTypes.any,
  onIconClick: PropTypes.func,
  disabled: PropTypes.bool
};
