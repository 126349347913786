
import { Routes, BrowserRouter, Route } from 'react-router-dom';

// Components
import Header from 'components/commonComponents/Header';

import Footer from 'components/commonComponents/Footer';
import Settings from 'components/pages/Settings';
import About from 'components/pages/About';
import Founder from 'components/pages/About/Founder';
import Contact from 'components/pages/Contact';
import Policy from 'components/pages/StaticPages/Policy';
import Subscription from 'components/pages/Subscription';
import SubscribePayment from 'components/pages/SubscribePayment';
import Etisalat from 'components/pages/Etisalat';
import HelpAndSupport from 'components/pages/HelpAndSupport';
import SearchPage from 'components/pages/Search';
import HomePage from '../components/pages/HomePage';
import ViewArticle from 'components/pages/Articles/ViewArticle';
import Articles from 'components/pages/Articles';
import News from 'components/pages/News';
import ViewNews from 'components/pages/News/ViewNews';
import Competitions from 'components/pages/Competitions';
import ViewCompetition from 'components/pages/Competitions/ViewCompetition';
import Projects from 'components/pages/Projects';
import ViewApp from 'components/pages/Projects/ViewApp';
import HEArticles from 'components/pages/HEArticles';
import ApplicationArticles from 'components/pages/ApplicationArticles';
import GeneralArticles from 'components/pages/GeneralArticles';
import Awards from 'components/pages/Awards';
import AwardsAll from 'components/pages/Awards/AwardsAll';
import BookView from 'components/pages/Awards/BookView';
import BookMap from 'components/pages/Awards/Maps';
import Thanks from 'components/pages/Thanks';
import HealthCheck from 'components/pages/HealthCheck';
import Gallery from 'components/pages/Gallery';

const AppRouter = () => {
  return (
    <>
      <BrowserRouter >
        <div className='__dark'>
          <Header />
          
          <div className="content-wrapper">
            <Routes>
              <Route path="/home" element={<HomePage />} />
              <Route path="*" element={<HomePage />} />

              <Route path="/about" element={<About />} />
              <Route path="/founder" element={<Founder />} />
              <Route path="/contact" element={<Contact />} />

              <Route path="/privacy" element={<Policy id='59' />} />
              <Route path="/cookie" element={<Policy id='46' />} />
              <Route path="/terms" element={<Policy id='47' />} />
              <Route path='/help-and-support' element={<HelpAndSupport/>} />

              <Route path="/settings" element={<Settings />} />

              <Route path="/subscribe/:appId" element={<Subscription />} />
              <Route path="/subscription-payment/:subId/:appId" element={<SubscribePayment />} />
              <Route path="/etisalat/:subId" element={<Etisalat />} />

              <Route path="/search" element={<SearchPage />} />

              <Route path="/awards" element={<Awards />} />
              <Route path="/awards/list/:type/:typeId" element={<AwardsAll />} />
              <Route path="/book/:bookId" element={<BookView />} />
              <Route path="/book/map" element={<BookMap />} />

              
              <Route path="/articles" element={<Articles />} />
              <Route path="/general-articles" element={<GeneralArticles />} />
              <Route path="/article/:articleId" element={<ViewArticle />} />
              <Route path="/he-articles" element={<HEArticles />} />
              <Route path="/application-articles" element={<ApplicationArticles />} />


              <Route path="/news" element={<News />} />

              <Route path="/competitions" element={<Competitions />} />

              <Route path="/projects" element={<Projects />} />
              <Route path="/project/:appId" element={<ViewApp />} />

              <Route path="/thanks" element={<Thanks />} />

              <Route path="/healthcheck" element={<HealthCheck />} />
              <Route path="/gallery" element={<Gallery />} />

              


            </Routes>
          </div>
          <Footer/>
        </div>
      </BrowserRouter>
    </>
  );
};
export default AppRouter;
