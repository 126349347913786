import axios from 'axios';
export const fetchData = async (request) => {
  const { method, endpoint, body } = request;
  const sessiontoken = localStorage.getItem('sessiontoken');
  const requestModel = {
    method,
    url: endpoint,
    data: body
  };
  if (sessiontoken)
    requestModel.headers = {
      sessiontoken: sessiontoken
    };
  try {
    const response = await axios.request(requestModel);
    return response.data;
  } catch (e) {
    return {
      status: e.response?.status,
      data: e.response?.data
    }
  }
};
