// Core
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Styles
import './styles.scss';

// Assets

import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';
import { useSelector } from 'react-redux';
import ReactPlayer from 'react-player';
import { Collapse, Spin } from 'antd';
import { Fade } from 'react-reveal';
const { Panel } = Collapse;

const HelpAndSupport = () => {
    const { currentLang } = useSelector((store) => store.commonData);
    const { t } = useTranslation();
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [supports, setSupports] = useState([])

    const fetchSupport = async () => {
        const res = await fetchData(restAPIs.helpAndSupport(currentLang?.id));
        if (res.statusCode === 200) {
            setData(res)
            setSupports(res.supports)
        }
        setLoading(false);
    }

    useEffect(() => {
        fetchSupport();
        window.scrollTo(0, 0);
    }, [currentLang]);

    return (
        <>
            <div className='container __innerTopPad'>
                {isLoading ?
                    <div className='text-center'>
                        <Spin className='__spin_color' size="large" tip="Loading" />
                    </div> :
                    <div className='row'>
                        <Fade bottom cascade>
                            <div className='col-md-3 mb-5'>
                                <img src={data?.applicationIcon} alt="" width={'100%'} height={'200px'} style={{ objectFit: 'contain' }} />
                            </div>
                        </Fade>
                        <Fade bottom cascade>
                            <div className='col-md-9 mb-5'>
                                <h4 style={{ color: '#a19c74', marginBottom: '10px' }}>{data?.tagline}</h4>
                                <h1 className='__common-head' style={{ color: '#e3a83a', fontSize: '2rem' }}>{data?.name}</h1>
                                <p dangerouslySetInnerHTML={{ __html: data?.description }} style={{ lineHeight: '2', fontSize: '17px' }}></p>
                                {data?.videoURL ? <div className='row justify-content-center'>
                                    <div className='col-md-8'>
                                        <div className='player-wrapper'>
                                            <ReactPlayer url={data?.videoURL} controls={true}
                                                className='react-player'
                                                width='100%'
                                                height='100%'
                                            />
                                        </div>
                                    </div>
                                </div> : ''}

                                <div className='mt-4 __coll' style={{ padding: '10px', }} dir={currentLang?.id == 1 ? 'rtl' : 'ltr'}>
                                    <Collapse accordion>
                                        {supports && supports.map((item, idx) => {
                                            return (
                                                <Panel header={item?.name} key={idx}>
                                                    <p dangerouslySetInnerHTML={{ __html: item?.description }}></p>
                                                </Panel>
                                            )
                                        })}
                                    </Collapse>
                                </div>
                            </div>
                        </Fade>
                    </div>
                }

            </div>
        </>
    );
};

export default HelpAndSupport;
