import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ViewArticleModal from 'components/modals/ViewArticleModal';
import { Fade } from 'react-reveal';
import { Skeleton } from 'antd';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import './styles.scss';

import AllLogo from "assets/images/all.jpg";
import NoResult from "assets/images/no_result.png"

const ApplicationArticles = () => {
    const { currentLang } = useSelector((store) => store.commonData);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingPage, setLoadingPage] = useState(false);
    const [isLastPage, setIsLastPage] = useState(false);
    const [page, setPage] = useState(1);
    const [content, setContent] = useState('');
    const { t } = useTranslation();
    const [applications, setApplications] = useState('')
    const [appId, setAppId] = useState(null)
    const [list, setArticleList] = useState([])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleArticleNavigate = (id) => {
        navigate('/article/' + id);
    };

    const fetchArticles = async (applicationId) => {
        setAppId(applicationId)
        setIsLoading(true);
        const res = await fetchData(restAPIs.articlesListing(currentLang?.id, 1, null, applicationId, null));
        setIsLoading(false);

        if (res?.statusCode === 200) {
            setArticleList(res.articles);
            setIsLastPage(res.isLastPage);
        }
    };

    useEffect(() => {
        fetchArticles();
        fetchApplications();
    }, [currentLang]);

    const handleOpenViewArticle = (data) => {
        setContent(data);
        setOpen(true);
    };

    const handleScroll = () => {
        const windowHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        if (windowHeight + scrollTop >= documentHeight - 200 && !isLoadingPage && !isLastPage) {
            fetchPaginateArticles(page + 1); // Load more articles on scroll
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isLoadingPage, isLastPage, page]);

    const fetchPaginateArticles = async (pageToFetch) => {
        setLoadingPage(true);
        const res = await fetchData(restAPIs.articlesListing(currentLang?.id, pageToFetch, null, appId, null));
        setLoadingPage(false);

        if (res?.statusCode === 200) {
            setArticleList(prevList => [...prevList, ...res.articles]);
            setIsLastPage(res.isLastPage);
            setPage(pageToFetch);
        }
    };

    const transformDate = (date) => {
        const currentDate = new Date(date);
        const monthNames = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];

        const day = currentDate.getDate();
        const month = currentDate.getMonth(); // Month is zero-based, so add 1
        const year = currentDate.getFullYear();
        const formattedDate = `${day} ${monthNames[month]}, ${year}`;
        return formattedDate;
    };

    const fetchApplications = async () => {
        setIsLoading(true)
        const res = await fetchData(restAPIs.applicationListing(currentLang?.id, 1));
        setApplications(res.applications)
        setIsLoading(false);
    }


    return (
        <>
            <div className='container-fluid __articles_list' style={{ paddingTop: '12vh', paddingBottom: '6%' }}>
                <div className='inner'>
                    <div className="c-heading text-center">
                        <div className="c-heading__top">
                            <h2 className="text-default"> </h2>
                        </div>
                        <div className="c-heading__middle">
                            <h3 className="heading-3 text-uppercase"> {t('applicationArticles')}</h3>
                        </div>
                        <div className="c-heading__bottom">
                            <div className="c-heading__short-desc" >
                                <span>{t('articlesOfApplicationsAndProjects')}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="__filterEnabled block __articles ">
                    <div className="inner">
                        <div className="has-sticker">
                            <Fade bottom duration={800} delay={500}>
                                <ul className="grid-courses grid-courses--one-row">
                                    {
                                        isLoading ?
                                            <>
                                                {[...Array(4)].map((item, idx) => {
                                                    return (
                                                        <li className="grid-academy__item" key={idx}>
                                                            <div className="card-academy">
                                                                <figure className="card-academy__figure">
                                                                    <Skeleton.Button active block style={{ height: '200px' }} />
                                                                </figure>
                                                                <div className="card-academy__info">
                                                                    <div className="card-academy__header">
                                                                        <div className="card-academy__row">
                                                                            <Skeleton active />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>

                                                    )
                                                })}
                                            </>
                                            :
                                            <>
                                                {list?.map((item, idx) => (
                                                    <li className="grid-academy__item" key={idx}>
                                                        <div className="card-academy">
                                                            <figure className="card-academy__figure">
                                                                <a onClick={() => handleArticleNavigate(item?.id)}>
                                                                    <img className="card-academy__media lazy lazy--loaded" src={item?.publicationIcon} alt={item?.name} />
                                                                </a>
                                                            </figure>
                                                            <div className="card-academy__info">
                                                                <div className="card-academy__header">
                                                                    <div className="card-academy__row">
                                                                        <p>{item?.categoryName}</p>
                                                                        {item?.creationdate && <a style={{ color: '#97a51a' }}>{transformDate(item?.creationdate)}</a>}
                                                                        <h3 className="card-academy__title">
                                                                            <a onClick={() => handleArticleNavigate(item?.id)}>{item?.name}</a>
                                                                        </h3>
                                                                        <span className="card-academy__desc">{item?.subbody}</span>
                                                                        <a className="__anchor" onClick={() => handleOpenViewArticle(item)}>
                                                                            {t('view_article')}
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                ))}
                                                {list?.length == 0 && <div className='__no_result'><img src={NoResult} /> <strong>No Articles found.!</strong> <span>Please change your selection.</span></div>}
                                            </>

                                    }
                                    {isLoadingPage ?
                                        <>
                                            {[...Array(4)].map((item, idx) => {
                                                return (
                                                    <li className="grid-academy__item" key={idx}>
                                                        <div className="card-academy">
                                                            <figure className="card-academy__figure">
                                                                <Skeleton.Button active block style={{ height: '200px' }} />
                                                            </figure>
                                                            <div className="card-academy__info">
                                                                <div className="card-academy__header">
                                                                    <div className="card-academy__row">
                                                                        <Skeleton active />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>

                                                )
                                            })}
                                        </>
                                        : ''
                                    }
                                </ul>
                            </Fade>
                        </div>
                    </div>
                    <div className='__filter_set'>
                        <div className='inner'>
                            <h2 className='__filter_title'>{t('applications')}</h2>
                            <ul>
                                <li className="__filt__item" onClick={() => fetchArticles(null)} key={-1}>
                                    <img loading="lazy" className="imageLoader __filt__media" src={AllLogo} />
                                    <h3 className={appId == null ? 'active' : ''}>
                                        All Articles
                                    </h3>
                                </li>
                                {applications && applications?.map((item, idx) => {
                                    return (
                                        <li className="__filt__item" onClick={() => fetchArticles(item?.appId)} key={idx}>
                                            <img loading="lazy" className="imageLoader __filt__media" src={item?.applicationIcon} />
                                            <h3 className={item?.appId === appId ? 'active' : ''}>
                                                {item?.name}
                                            </h3>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <ViewArticleModal open={open} onClose={() => setOpen(false)} content={content} />
        </>
    );
};

export default ApplicationArticles;
