// Core
import React from "react";
import { Select } from "antd";

// Style
import "./style.scss";

const { Option } = Select;

const SelectBox = (props) => {
  const { handleOnChange, width, options = [], label, value, error, placeholder, disabled, multiple, name, loading, showSearch } = props;

  return (
    <div className={`select-wrapper ${error ? 'select-wrapper---error' : ''}`}>
      {label && <label>{label}</label>}
      <Select
        value={value}
        style={{ width: width ? width : "100%" }}
        onChange={(e) => handleOnChange(e)}
        placeholder={placeholder}
        disabled={disabled}
        mode={multiple ? 'tags' : 'single'}
        maxTagCount={multiple ? 'responsive' : ''}
        loading={loading}
        showSearch={showSearch}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {
          options.map(item => {
            return(
              <Option key={`${name}-${item.id}`} value={item.id} extra={item.extra}>{item.label}</Option>
            )
          })
        }
      </Select>
    </div>
  );
};

export default SelectBox;
