
// Core
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ViewArticleModal from 'components/modals/ViewArticleModal';
import { Fade } from 'react-reveal';
import { Skeleton } from 'antd';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const HEArticles = () => {
    const { currentLang } = useSelector((store) => store.commonData);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingPage, setLoadingPage] = useState(false);
    const [isLastPage, setIsLastPage] = useState(false);
    const [page, setPage] = useState(1);
    const [content, setContent] = useState('');
    const { t } = useTranslation();
    const [list, setArticleList] = useState([])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleArticleNavigate = (id) => {
        navigate('/article/' + id);
    };

    const fetchArticles = async () => {
        setIsLoading(true);
        const res = await fetchData(restAPIs.articlesListing(currentLang?.id, 1, null, null, 21));
        setIsLoading(false);

        if (res?.statusCode === 200) {
            setArticleList(res.articles)
            setIsLastPage(res.isLastPage);
        }
    };

    useEffect(() => {
        fetchArticles();
    }, [currentLang]);

    const handleOpenViewArticle = (data) => {
        setContent(data);
        setOpen(true);
    };

    const handleScroll = () => {
        const windowHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        if (windowHeight + scrollTop >= documentHeight - 200 && !isLoadingPage && !isLastPage) {
            fetchPaginateArticles(page + 1); // Load more articles on scroll
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isLoadingPage, isLastPage, page]);

    const fetchPaginateArticles = async (pageToFetch) => {
        setLoadingPage(true);
        const res = await fetchData(restAPIs.articlesListing(currentLang?.id, pageToFetch, null, null, 21));
        setLoadingPage(false);

        if (res?.statusCode === 200) {
            setArticleList(prevList => [...prevList, ...res.articles]);
            setIsLastPage(res.isLastPage);
            setPage(pageToFetch);
        }
    };

    const transformDate = (date) => {
        const currentDate = new Date(date);
        const monthNames = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];

        const day = currentDate.getDate();
        const month = currentDate.getMonth(); // Month is zero-based, so add 1
        const year = currentDate.getFullYear();
        const formattedDate = `${day} ${monthNames[month]}, ${year}`;
        return formattedDate;
    };

       return (
        <>
            <div className='container-fluid __articles_list' style={{ paddingTop: '12vh', paddingBottom: '6%' }}>
                <div className='inner'>
                    <div className="c-heading text-center">
                        {/* <div className="c-heading__top">
                            <h2 className="text-default"> Cultural</h2>
                        </div> */}
                        <div className="c-heading__middle">
                            <h3 className="heading-3 text-uppercase">{t('heArticles')}</h3>
                        </div>
                        <div className="c-heading__bottom">
                            <div className="c-heading__short-desc" style={{width:'100%'}}>
                                <span>{t('articlesOfHisExcellencyMohammedAlSuwaidi')} </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="block __articles">
                    <div className="inner">
                        <div className="has-sticker">
                            <Fade bottom duration={800} delay={500}>
                                <ul className="grid-courses grid-courses--one-row">
                                    {
                                        isLoading ?
                                            <>
                                                {[...Array(4)].map((item, idx) => {
                                                    return (
                                                        <li className="grid-academy__item" key={idx}>
                                                            <div className="card-academy">
                                                                <figure className="card-academy__figure">
                                                                    <Skeleton.Button active block style={{ height: '200px' }} />
                                                                </figure>
                                                                <div className="card-academy__info">
                                                                    <div className="card-academy__header">
                                                                        <div className="card-academy__row">
                                                                            <Skeleton active />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>

                                                    )
                                                })}
                                            </>
                                            :
                                            list?.map((item, idx) => {
                                                return (

                                                    <li className="grid-academy__item" key={idx}>
                                                        <div className="card-academy">
                                                            <figure className="card-academy__figure">
                                                                <a onClick={() => handleArticleNavigate(item?.id)}>
                                                                    <img className="card-academy__media lazy lazy--loaded" src={item?.publicationIcon} />
                                                                </a>
                                                            </figure>
                                                            <div className="card-academy__info">
                                                                <div className="card-academy__header">
                                                                    <div className="card-academy__row">
                                                                        <p>{transformDate(item?.creationdate)}</p>
                                                                        <h3 className="card-academy__title"><a onClick={() => handleArticleNavigate(item?.id)}>{item?.name}</a></h3>
                                                                        <span className="card-academy__desc">{item?.subbody}</span>
                                                                        <a className="__anchor" onClick={() => handleOpenViewArticle(item)}>{t('view_article')}</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                )
                                            })
                                    }
                                    {isLoadingPage ?
                                        <>
                                            {[...Array(4)].map((item, idx) => {
                                                return (
                                                    <li className="grid-academy__item" key={idx}>
                                                        <div className="card-academy">
                                                            <figure className="card-academy__figure">
                                                                <Skeleton.Button active block style={{ height: '200px' }} />
                                                            </figure>
                                                            <div className="card-academy__info">
                                                                <div className="card-academy__header">
                                                                    <div className="card-academy__row">
                                                                        <Skeleton active />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>

                                                )
                                            })}
                                        </>
                                        : ''

                                    }

                                </ul>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>

            <ViewArticleModal open={open} onClose={() => setOpen(false)} content={content} />
        </>
    );
};

export default HEArticles;
