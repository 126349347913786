// Core
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Styles
import './styles.scss';
import { useState } from 'react';
import { Modal } from 'antd';

import { useEffect } from 'react';

const ViewPhoto = (props) => {
  const { onClose, open, content } = props;
  const { t } = useTranslation();
  return (
    <>
      <Modal
        open={open}
        onCancel={onClose}
        width={1000}
        style={{ borderRadius: '10px', top: 20 }}
        className="__detail_modal"
        keyboard={false}
        footer={null}
        closable={false}
        centered
      >

        <div className="__photo">
            <img src={content?.photo} alt="" className='imageLoader' />
            <div className='___detail'>
              <h1>{content?.name}</h1>
              <p>{content?.body}</p>
            </div>
        </div>
      
      </Modal>
    </>
  );
};

export default ViewPhoto;

ViewPhoto.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
