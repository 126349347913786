// Core
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Styles
import './styles.scss';
import Button from 'components/commonComponents/Button';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Modal, Skeleton } from 'antd';

const BooksByLocationModal = (props) => {
  const { currentLang } = useSelector((store) => store.commonData);
  const { onClose, open, location } = props;
  const [articles, setBooks] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      onClose();
    }
  };

  const handleOnClickBook = (id) => {
    navigate(`/book/${id}`)
    onClose();
  }

  const fetchBooks = async () => {
    setIsLoading(true)
    const res = await fetchData(restAPIs.bookByLocation(currentLang?.id, location.latitude, location.longitude));
    setIsLoading(false)
    if (res?.statusCode === 200) {
      setBooks(res.books);
    }
  };


  useEffect(() => {
    fetchBooks();
  }, [location]);


  return (

    <>

      <Modal
        open={open}
        onClose={handleClose}
        className='__common_model_class'
        footer={null}
        closable={false}
      >
        <div className='__common_model_content'>
          <h3 className='____common_model_class_head' style={{ marginBottom: '10px' }}> {t('books')}</h3>
          <div className='.____common_model_class_desc'>

            <div className='__awards_list'>
              {isLoading ?
                [...Array(2)].map((item, idx) => {
                  return (
                    <Skeleton key={idx} />
                  )
                })
                :
                articles && articles?.map((item, i) => {
                  return (
                    <a className="singleBook" key={i} onClick={() => handleOnClickBook(item.bookid)}>
                      <div style={{ position: 'relative', background: '#0001' }}>
                        <img src={item.coverImage} />
                        <div className="bookBind"></div>
                        <span className="subJect">{item.subjectName}</span>
                      </div>
                      <div className="singleBookInner">
                        <h1>{item.name}</h1>
                        <h5>{item.author}</h5>
                      </div>
                    </a>
                  )
                })
              }
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>

          </div>
          <div className='d-flex align-items-center justify-content-end' style={{ marginTop: ' 15px', gridGap: '10px' }}>
            <Button
              label={t('cancel')}
              handleOnClick={() => handleClose(false)}
            />
          </div>
        </div>
      </Modal>

    </>
  );
};

export default BooksByLocationModal;

BooksByLocationModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  location: PropTypes.any
};
