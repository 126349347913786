// Core
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Styles
import './styles.scss';

// Assets

import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';

import Button from 'components/commonComponents/Button';

import { Fade } from "react-reveal";

import PremimImg from 'assets/images/premium-quality.png'
import { Skeleton, Tooltip } from 'antd';
import ViewPhoto from 'components/modals/ViewPhoto';

const Gallery = () => {
  const { currentLang } = useSelector((store) => store.commonData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const { t } = useTranslation();
  const [content, setContent] = useState('')
  const [viewPhoto, setViewPhoto] = useState(false)
  const [categoryId, setCategoryId] = useState(0)
  const [isLoadingPage, setLoadingPage] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);
  const [page, setPage] = useState(1);

  const fetchAwards = async (categoryId) => {
    setCategoryId(categoryId)
    setPage(1)
    setLoading(true)
    const res = await fetchData(restAPIs.galleryPhotoList(categoryId, currentLang?.id, 1));
    setData(res.entities)
    setIsLastPage(res.isLastPage);
    setLoading(false);
  }

  const fetchCategories = async () => {
    setLoading(true)
    const res = await fetchData(restAPIs.galleryCategories(currentLang?.id));
    setCategories(res.category)
    setLoading(false);
  }

  useEffect(() => {
    fetchAwards(0);
    fetchCategories();
  }, [currentLang]);

  const handleOpenPhoto = (data) => {
    setContent(data);
    setViewPhoto(true);
  };

  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    if (windowHeight + scrollTop >= documentHeight - 200 && !isLoadingPage && !isLastPage) {
      fetchPaginateAwards(page + 1); // Load more articles on scroll
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isLoadingPage, isLastPage, page]);

  const fetchPaginateAwards = async (pageToFetch) => {
    setLoadingPage(true);
    const res = await fetchData(restAPIs.galleryPhotoList(categoryId, currentLang?.id, pageToFetch));
    setLoadingPage(false);

    if (res?.statusCode === 200) {
      setData(prevList => [...prevList, ...res.entities]);
      setIsLastPage(res.isLastPage);
      setPage(pageToFetch);
    }
  };


  return (
    <>

      <div className='container-fluid __articles_list' style={{ paddingTop: '12vh', paddingBottom: '6%' }}>
        <div className='inner'>
          <div className="c-heading text-center">
            <div className="c-heading__middle">
              <h3 className="heading-3 text-uppercase">Gallery</h3>
            </div>
          </div>
        </div>
        {/* <div className="block __articles">
          <div className="inner">
            <div className="has-sticker">
              <Fade bottom duration={800} delay={500}>
                <div className='container mt-5' dir={currentLang?.id === 1 ? 'rtl' : 'ltr'}>

                  {isLoading ?
                    <div className='__gallery_list'>
                      {[...Array(5)].map((item, idx) => {
                        return (
                          <a className="__gallery_item" key={idx} >
                            <div style={{ position: 'relative', background: '#0001' }}>
                              <Skeleton.Button active block style={{ height: '250px' }} />
                            </div>
                          </a>
                        )
                      })}
                    </div>
                    :
                    <div className='__gallery_list' >
                    {awards && awards.map((award, idx) => {
                      return (
                        
                          <div className='__gallery_item' key={idx}>
                            <img src={PremimImg} alt="" />
                          </div>
                        
                      )
                    }
                    )
                  }</div>

                }
                </div>
              </Fade>
            </div>
          </div>
        </div> */}

        <div className="__filterEnabled block __articles ">
          <div className="inner">
            <div className="has-sticker">
              <Fade bottom duration={800} delay={500}>
                <div className='__gallery_list'>
                  {isLoading ?

                    <>
                      {[...Array(5)].map((item, idx) => {
                        return (
                          <a className="__gallery_item" key={idx} >
                            <div style={{ position: 'relative', background: '#0001' }}>
                              <Skeleton.Button active block style={{ height: '250px' }} />
                            </div>
                          </a>
                        )
                      })}
                    </>
                    :
                    <>
                      {
                        data && data.map((item, idx) => {
                          return (
                            <div className='__gallery_item' key={idx} title={item?.body} onClick={() => handleOpenPhoto(item)}>
                              <img src={item?.photo} alt="" />
                              <Tooltip title={item?.name}><h4>{item?.name}</h4></Tooltip>
                            </div>
                          )
                        })
                      }
                    </>
                  }

                  {isLoadingPage ?
                    <>
                      {[...Array(5)].map((item, idx) => {
                        return (
                          <a className="__gallery_item" key={idx} >
                            <div style={{ position: 'relative', background: '#0001' }}>
                              <Skeleton.Button active block style={{ height: '250px' }} />
                            </div>
                          </a>
                        )
                      })}
                    </>
                    : ''

                  }
                </div>
              </Fade>
            </div>
          </div>
          <div className='__filter_set'>
            <div className='inner'>
              <h2 className='__filter_title'>Categories</h2>
              <ul>
                <li className="__filt__item" onClick={() => fetchAwards(0)}>
                  All
                </li>
                {categories && categories?.map((item, idx) => {
                  return (
                    <li className="__filt__item" key={idx} onClick={() => fetchAwards(item?.categoryId)}>
                      {item?.name}
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <ViewPhoto
        onClose={() => setViewPhoto(false)}
        open={viewPhoto}
        content={content}
      />
    </>
  );
};

export default Gallery;
