// Core
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';
import Button from 'components/commonComponents/Button';

// Styles
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { APP_ID } from 'config/constants';
import { Alert, Modal } from 'antd';


const DeleteAccount = (props) => {
  const { onClose, open } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [errorAlert, setErrorAlert] = useState({ msg: '', key: Math.random(), open: false, severity: '' })

  const handleSave = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('action', 'delete')
    formData.append('appId', APP_ID)
    const res = await fetchData(restAPIs.deleteAccount(formData));
    if (res.statusCode === 200) {
      console.log("ever", res);
      let localDetails = JSON.parse(localStorage.getItem('userDetails'));
      localDetails.markDeletion = 1
      dispatch({
        type: 'userDetails',
        value: localDetails
      });
      localStorage.setItem('userDetails', JSON.stringify(localDetails));

      setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true, severity: 'success' });
      setTimeout(() => {
        setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });

      }, 2000);
      onClose();

    } else {
      console.log("ever", res);
      setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true, severity: 'error' });
      setTimeout(() => {
        setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
      }, 3000);
    }
    setIsLoading(false);
  };

  const handleClose = (event, reason) => {
    setErrorAlert({})
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      onClose();
    }
  };


  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className='__common_model_class'
        keyboard={false}
        maskClosable={false}
        footer={null}
        closable={false}
      >
        <div className='__common_model_content'>
        <h3 className='____common_model_class_head' style={{marginBottom:'10px'}}> {t('delete_account')}</h3>
        <div className='____common_model_class_desc' style={{ width: '100%' }}>
          Your account data will be deleted in 30 days, you can suspend deletion within this time period
        </div>
        <div className='d-flex align-items-center justify-content-end' style={{ marginTop: ' 15px', gridGap: '10px' }}>
          <a onClick={() => handleClose(false)} style={{ cursor: 'pointer', margin: '0 10px' }}>{t('cancel')}</a>
          <Button
            withLoader
            loading={isLoading}
            disabled={isLoading}
            handleOnClick={handleSave}
            label={t('submit')}
          />
        </div>
        {errorAlert && errorAlert.open ?
          <Alert severity={errorAlert.severity} sx={{ width: '100%' }}>
            {errorAlert.msg}
          </Alert>
          : ''}
        </div>
      </Modal>
    </>
  );
};

export default DeleteAccount;

DeleteAccount.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
