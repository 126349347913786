// Core
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import './styles.scss';
import { Modal } from 'antd';
import Highlight from 'components/commonComponents/Highlight';
import { useNavigate } from 'react-router-dom';

import { ArrowRightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const ViewArticleModal = (props) => {
  const { onClose, open, content, searchkey } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();

  const transformDate = (date) => {
    const currentDate = new Date(date)
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const day = currentDate.getDate();
    const month = currentDate.getMonth(); // Month is zero-based, so add 1
    const year = currentDate.getFullYear();
    const formattedDate = `${day} ${monthNames[month]}, ${year}`;
    return formattedDate
  }

  const highlightText = (text, highlight) => {
    const parts = text?.split(new RegExp(`(${highlight})`, 'gi'));
    let highlightedHTML = '';
    parts?.forEach((part, i) => {
      if (part.toLowerCase() === highlight.toLowerCase()) {
        highlightedHTML += `<span key=${i} style="font-weight: bold; color: red;">${part}</span>`;
      } else {
        highlightedHTML += part;
      }
    });
    return highlightedHTML;
  };

  const handleNavigate = () => {
    onClose();
    navigate('/article/' + content?.id);
  }

  return (
    <>
      <Modal
        open={open}
        onCancel={onClose}
        width={1000}
        style={{ borderRadius: '10px', top: 20 }}
        className="__detail_modal"
        keyboard={false}
        footer={null}
        closable={false}
        centered
      >

        <div className="inner">
        </div>
        <div className="inner" style={{ paddingTop: '50px', paddingBottom: '50px' }}>
          <div className="c-work">
            <div className="c-work__left">
              <div className="m-asset">
                <div>
                  {content?.categoryName}
                </div>
                <div className="m-asset__header">
                  <h3 className="m-asset__title"><Highlight text={content?.name ? content?.name : ''} highlight={searchkey} /></h3>
                </div>
                <div className="m-asset__desc" style={{ marginBottom: '14px' }}>
                  {searchkey ? <p dangerouslySetInnerHTML={{ __html: highlightText(content?.subbody, searchkey) }}></p>
                    : <p dangerouslySetInnerHTML={{ __html: content?.subbody }}></p>
                  }
                </div>
                <div className='__navigation_to'>
                  <a onClick={() => handleNavigate()} className="bt-ico-left">
                    <strong className="link-underlined">{t('learn_more')}</strong>
                    <ArrowRightOutlined />
                  </a>
                </div>
                <div className="m-asset__footer">
                  <div className="m-asset__date">
                    {transformDate(content?.creationdate)}
                  </div>
                </div>
              </div>
            </div>
            <div className="c-work__right" dir='ltr'>
              <img alt="" className='imageLoader' src={content?.publicationIcon} />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ViewArticleModal;

ViewArticleModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
