// Core
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation  } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Styles
import './styles.scss';

import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';

import { Collapse } from 'antd';
import { Fade } from 'react-reveal';

const { Panel } = Collapse;

const Founder = () => {
    const { currentLang } = useSelector((store) => store.commonData);
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [activePanel, setActivePanel] = useState('5');
    const { hash } = useLocation();
    const panelRef = useRef(null);
    const { t } = useTranslation();

    const fetchResult = async () => {
        try {
            const res = await fetchData(restAPIs.getFounderInfo(currentLang?.id));
            setData(res.founder);
            setLoading(false);
            const fragmentPanelKey = hash.substring(1);
            setActivePanel(fragmentPanelKey)
            if (fragmentPanelKey) {
                panelRef.current = fragmentPanelKey;
            }

            if (panelRef.current) {
                const panelElement = document.getElementById(panelRef.current);
                if (panelElement) {
                    panelElement.scrollIntoView({ behavior: 'smooth' });
                }
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchResult();
    }, [currentLang, hash ]); 

    const onCollapseChange = (key) => {
        setActivePanel(key);
    };

    return (
        <>
            <Fade bottom>
                <div className='container __articles_list' style={{ paddingTop: '20vh', paddingBottom: '6%' }}>
                    <div className='inner'>
                        <div className="c-heading text-center">
                            <div className="c-heading__top">
                                <h2 className="text-default">{data && data[0]?.name}</h2>
                            </div>
                            <div className="c-heading__middle">
                                <h3 className="heading-3 text-uppercase">{t('muhammed_ahmed_suwaidi')}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="block __articles">
                        <div className="inner">
                            <p className='__descr mt-4 mb-4' dangerouslySetInnerHTML={{ __html: data && data[0]?.description }} ></p>
                            <Collapse ghost className='__collapse__items' accordion activeKey={activePanel} onChange={onCollapseChange}>
                                {data.slice(1).map(item => (
                                    <Panel key={item.id} header={item.name}>
                                        <p className='__descr' style={{ padding: '0 30px' }} dangerouslySetInnerHTML={{ __html: item.description }} ></p>
                                    </Panel>
                                ))}
                            </Collapse>
                        </div>
                    </div>
                </div>
            </Fade>
        </>
    );
};

export default Founder;
