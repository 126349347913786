// Core
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { TypeAnimation } from "react-type-animation";
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';


// components

// Styles
import "./styles.scss";

// Assets
import { restAPIs } from "utils/restAPIs";
import { fetchData } from "utils/fetch";

import Logo1 from "assets/images/trusted/applicationIcon1.jpg";
import Logo2 from "assets/images/trusted/applicationIcon2.jpg";
import Logo3 from "assets/images/trusted/applicationIcon3.jpg";
import Logo4 from "assets/images/trusted/applicationIcon4.jpg";
import Logo5 from "assets/images/trusted/applicationIcon5.jpg";
import Logo6 from "assets/images/trusted/applicationIcon6.jpg";
import Logo7 from "assets/images/trusted/applicationIcon7.jpg";
import Logo8 from "assets/images/trusted/applicationIcon8.jpg";
import Logo9 from "assets/images/trusted/applicationIcon9.jpg";
import Logo10 from "assets/images/trusted/applicationIcon10.jpg";
import Logo11 from "assets/images/trusted/applicationIcon11.jpg";

import bookArb from "assets/images/banner/book-arb.png";
import bookEng from "assets/images/banner/book-eng.png";

import festArb from "assets/images/banner/festival-arb.png";
import festEng from "assets/images/banner/festival-eng.png";

import ibnArb from "assets/images/banner/ibn-arabic.png";
import ibnEng from "assets/images/banner/ibn-eng.png";

import motaArb from "assets/images/banner/poem-arb.png";
import motaEng from "assets/images/banner/poems-eng.png";

import { Fade, Slide, Zoom } from "react-reveal";


import { Avatar, Skeleton } from 'antd';
import ViewArticleModal from "components/modals/ViewArticleModal";
import ViewAppModal from "components/modals/ViewAppModal";

import Slider from 'react-slick';
import Button from "components/commonComponents/Button";

const HomePage = () => {
  const { userDetails, currentLang } = useSelector((store) => store.commonData);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false)
  const [applications, setApplcations] = useState([])

  const [open, setOpen] = useState(false);
  const [data, setData] = useState([])
  const [content, setContent] = useState('')

  const [openApp, setOpenApp] = useState(false);
  const [contentApp, setContentApp] = useState('')


  const [sliderRefAward, setSliderRefAwards] = useState('')
  const [awards, setAwards] = useState([])
  const [awardPage, setAwardPage] = useState(1)
  const [awardPageTitle, setAwardPageTitle] = useState('')
  const [awardPageDesc, setAwardPageDesc] = useState('')
  const [awardLastPage, setAwardLastPage] = useState(false)


  useEffect(() => {
    window.scrollTo(0, 0);
    fetchApplications();
    fetchArticles();
    fetchAwards();
  }, [currentLang]);


  const fetchApplications = async () => {
    setIsLoading(true)
    const res = await fetchData(restAPIs.applicationListing(currentLang?.id, 1));
    setApplcations(res.applications)
    setIsLoading(false);
  }

  const fetchArticles = async () => {
    setIsLoading(true)
    const res = await fetchData(restAPIs.articlesListing(currentLang?.id, 1, null, null, 21));
    setData(res.articles)
    setIsLoading(false);
  }

  const handleArticleNavigate = (id) => {
    navigate('/article/' + id)
  }

  const handleOpenViewArticle = (data) => {
    setContent(data)
    setOpen(true)
  }

  const handleOpenViewApp = (data) => {
    setContentApp(data)
    setOpenApp(true)
  }

  const transformDate = (date) => {
    const currentDate = new Date(date)
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const day = currentDate.getDate();
    const month = currentDate.getMonth(); // Month is zero-based, so add 1
    const year = currentDate.getFullYear();
    const formattedDate = `${day} ${monthNames[month]}, ${year}`;
    return formattedDate
  }

  const Animator = () => {
    if (currentLang?.id === 2) {
      console.log("hrere");
      return (
        <TypeAnimation
          sequence={[
            " cultures",
            1000,
            " innovation",
            1000,
            " technology",
            1000,
          ]}
          wrapper="span"
          speed={30}
          repeat={Infinity}
          style={{ color: '#818d26', textTransform: 'uppercase', }}
        />
      );
    } else {
      console.log("or hrere");
      return (
        <TypeAnimation
          sequence={[
            " ثقافات ",
            1000,
            " ابتكار ",
            1000,
            " تقنيات ",
            1000,
          ]}
          wrapper="span"
          speed={30}
          repeat={Infinity}
          style={{ color: '#818d26', textTransform: 'uppercase', fontSize: '3.5rem' }}
        />
      );
    }
  };




  const fetchAwards = async () => {
    const res = await fetchData(restAPIs.awardsList(currentLang?.id, null, null, 1));
    setAwards(res.items)
    setAwardPageTitle(res.pageTitle)
    setAwardPageDesc(res.pageDesc)
    setAwardLastPage(res.isLastPage)
  }

  const fetchAwardsPaginate = async () => {
    const res = await fetchData(restAPIs.awardsList(currentLang?.id, '', '', awardPage));
    let awardsLis = awards
    setAwards(awardsLis.concat(res.items))
  }

  const slickPrevAward = () => {
    sliderRefAward.slickPrev();
  }

  const slickNextAward = () => {
    sliderRefAward.slickNext();
    if (!awardLastPage) {
      let currenPage = awardPage + 1
      setAwardPage(currenPage)
      fetchAwardsPaginate()
    }
  }

  const settingsAwards = {
    dots: false,
    autoplay: false,
    infinite: false,
    slidesToShow: 7,
    slidesToScroll: 7,
    arrows: false,
    // afterChange: onSliderArticleChange,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {

        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
        }
      }
    ]
  };

  const gotoIBAwards = () => {
    navigate('/awards')
  }


  return (
    <>
      <div className="__back_texture">
        <div className="container">
          <div className="__home_banner row justify-content-between align-items-center">
            <div className="col-md-6 __bnr_content">
              <Fade bottom duration={900} delay={1000}>
                <a className='__toph'>{t('culture_partner_of')}</a>
                <h1 className="_home_title">{t('spreading_knowledge')}</h1>
                <h2 className="_home_title2" style={{ margin: 0, marginTop: '20px' }}>{t('connecting')}</h2>
                <h2 className="__hme_animator"><Animator /></h2>
                <p style={{ color: '#888' }}>{t('1998')}</p>
              </Fade>

              <Fade bottom duration={900} delay={1000}>
                <div className="__bnr-actions">
                  <a className="button large primary w-inline-block">
                    <div className="btn-text">{t('explore_more')}</div>
                    <div
                      className="button__circle"
                      style={{
                        backgroundColor: "rgb(255, 255, 255)",
                        willChange: "auto",
                      }}
                    ></div>
                  </a>
                </div>
              </Fade>
            </div>

            <div className="col-md-6 __bnr_images">
              <div className="d-flex __side_img">
                <div className="d-flex flex-column align-items-end">
                  <Zoom duration={400} delay={550}>
                    <img src={currentLang?.id === 2 ? bookEng : bookArb} alt="Alwaraq" className="__img1" />
                  </Zoom>
                  <Slide right>
                    <img src={currentLang?.id === 2 ? festEng : festArb} alt="Cinemapedia" className="__img3" />
                  </Slide>
                </div>
                <div className="d-flex flex-column">
                  <Slide bottom duration={1000}>
                    <img src={currentLang?.id === 2 ? motaEng : motaArb} alt="Waha Al Motanabbi" className="__img4" />
                  </Slide>
                  <Slide bottom duration={1000} delay={40}>
                    <img
                      src={currentLang?.id === 2 ? ibnEng : ibnArb}
                      alt="Ibn Battuta"
                      className="__img4"
                    />
                  </Slide>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="__business_strip">
        <h3>TRUSTED BY +500K CUSTOMERS</h3>
        <div className="container">
          <div className="__logos_roll">
            <img src={Logo1} alt="Alwaraq" />
            <img src={Logo2} alt="Waha Al Motanabbi" />
            <img src={Logo3} alt="EV Quiz" />
            <img src={Logo11} alt="WAQT" />
            <img src={Logo10} alt="Ibn Battuta" />
            <img src={Logo6} alt="UAE History" />
            <img src={Logo7} alt="Cuisines" />
            <img src={Logo8} alt="Al Shajarah Family Tree" />
            <img src={Logo9} alt="Egyptology" />
          </div>
        </div>
      </div>

      <div style={{ background: '#F8F8F8', padding: '40px 0' }}>
        <div className="container-fluid">

          <div className="__block">
            <div className="inner">
              <div className="c-courses">
                <div className="c-heading c-heading--small">
                  <div className="c-heading__top">
                    <h2 className="text-default">{t('spreading_knowledge')}</h2>
                  </div>
                  <div className="c-heading__middle">
                    <div className="c-heading__between">
                      <h3 className="heading-5">{t('applications_and_projects')}</h3>
                      <div>{t('connecting_cul_inn_tech')}</div>
                    </div>
                  </div>
                </div>
                <div className="has-sticker">
                  <Fade bottom duration={800} delay={300}>
                    <ul className="grid-courses grid-courses--one-row">
                      {applications?.map((item, idx) => {
                        return (
                          idx < 4 &&

                          <li className="grid-academy__item" key={idx}>
                            <div className="card-academy">
                              <figure className="card-academy__figure" onClick={() => navigate('/project/' + item?.appId)} style={{ cursor: 'pointer' }}>
                                <a >
                                  <img className="card-academy__media lazy lazy--loaded" data-controller="lazyload-image" src={item?.coverImage} />
                                </a>
                              </figure>
                              <div className="card-academy__info">
                                <div className="card-academy__header" onClick={() => navigate('/project/' + item?.appId)} style={{ cursor: 'pointer' }}>
                                  <div className="card-academy__row d-flex flex-column">
                                    <h3 className="card-academy__title">
                                      <a>{item?.tagline}</a>
                                    </h3>
                                    <span className="card-academy__desc" dangerouslySetInnerHTML={{ __html: item?.description }} ></span>
                                  </div>
                                </div>
                                <div className="card-academy__footer">
                                  <div className="card-academy__row" onClick={() => navigate('/project/' + item?.appId)} style={{ cursor: 'pointer' }}>
                                    <div className="card-academy__by">
                                      <img src={item?.applicationIcon} style={{ width: '35px', height: '35px', borderRadius: '40px', objectFit: 'cover' }} />
                                      <strong style={{ margin: '0 10px' }}>{item?.name}</strong>
                                    </div>
                                  </div>
                                  <div className="card-academy__row">
                                    <div className="card-academy__stats">
                                      <div className="card-academy__stat">
                                        <strong><a href={item?.googleStoreLink} target="_blank">{t('playstore')}</a></strong>
                                      </div>
                                      <div className="card-academy__stat">
                                        <strong><a href={item?.appStoreLink} target="_blank">{t('appstore')}</a></strong>
                                      </div>
                                      <div className="card-academy__stat">
                                        <strong><a href={item?.websiteLink} target="_blank">{t('web')}</a></strong>
                                      </div>
                                    </div>
                                    <div className="card-academy__more">
                                      <a onClick={() => handleOpenViewApp(item)}>
                                        <ArrowRightOutlined />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        )
                      })
                      }

                    </ul>
                  </Fade>
                </div>

              </div>
            </div>
          </div>

          <div className="__block">
            <div className="inner">
              <h3 className="heading-hr"><strong>{t('applications_and_projects')}</strong></h3>
              <div className="l-list-sites">
                <div className="l-list-sites__col">
                  <Fade bottom duration={800} delay={300}>
                    <ul className="list-sites">
                      {applications?.map((item, idx) => {
                        return (
                          idx < 5 &&
                          <li  key={idx} className="list-sites__item" onClick={() => navigate('/project/' + item?.appId)} style={{ cursor: 'pointer' }}>
                            <div className="list-sites__card ">
                              <figure className="list-sites__figure">
                                <a >
                                  <img width="72" height="72" loading="lazy" className="imageLoader list-sites__media" src={item?.applicationIcon} />
                                </a>
                              </figure>
                              <div className="list-sites__info">
                                <div className="list-sites__label">
                                  {item?.tagline}
                                </div>
                                <h3 className="list-sites__title list-sites__title--truncate">
                                  <a >
                                    {item?.name}
                                  </a>
                                </h3>
                              </div>
                            </div>

                          </li>
                        )
                      })
                      }

                    </ul>
                  </Fade>
                </div>
                <div className="l-list-sites__col">
                  <Fade bottom duration={800} delay={300}>
                    <ul className="list-sites">
                      {applications?.map((item, idx) => {
                        return (
                          idx > 4 && idx < 10 &&
                          <li key={idx} className="list-sites__item" onClick={() => navigate('/project/' + item?.appId)} style={{ cursor: 'pointer' }}>
                            <div className="list-sites__card ">
                              <figure className="list-sites__figure">
                                <a >
                                  <img width="72" height="72" loading="lazy" className="imageLoader list-sites__media" src={item?.applicationIcon} />
                                </a>
                              </figure>
                              <div className="list-sites__info">
                                {item?.tagline}
                                <h3 className="list-sites__title list-sites__title--truncate">
                                  <a >
                                    {item?.name}
                                  </a>
                                </h3>
                              </div>
                            </div>

                          </li>
                        )
                      })
                      }
                    </ul>
                  </Fade>
                </div>
                <div className="l-list-sites__col">
                  <Fade bottom duration={800} delay={300}>
                    <ul className="list-sites">

                      {applications?.map((item, idx) => {
                        return (
                          idx > 9 && idx < 15 &&

                          <li  key={idx} className="list-sites__item" onClick={() => navigate('/project/' + item?.appId)} style={{ cursor: 'pointer' }}>
                            <div className="list-sites__card ">
                              <figure className="list-sites__figure">
                                <a >
                                  <img width="72" height="72" loading="lazy" className="imageLoader list-sites__media" src={item?.applicationIcon} />
                                </a>
                              </figure>
                              <div className="list-sites__info">
                                {item?.tagline}
                                <h3 className="list-sites__title list-sites__title--truncate">
                                  <a >
                                    {item?.name}
                                  </a>
                                </h3>
                              </div>
                            </div>
                          </li>
                        )
                      })
                      }
                    </ul>
                  </Fade>
                </div>
              </div>

              <div className="c-view-all">
                <div className="c-view-all__row  text-medium">
                  <a onClick={() => navigate('/projects')} className="bt-ico-left">
                    <strong className="link-underlined">{t('view_all_projects')}</strong>
                    <ArrowRightOutlined />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Ib Awards */}
      <div style={{ padding: '60px 0' }} className='mb-2'>
        <div className='container' style={{ position: 'relative' }}>
          <h1 className='heading-5 text-center mb-4' style={{ maxWidth: '70%', margin: '0 auto' }}>{awardPageTitle}</h1>
          <p className='mb-5' style={{textAlign:'justify'}}>{awardPageDesc}</p>
          <div className='__award_lists'>
            <button className="__award_button" onClick={() => slickPrevAward()} >
              <ArrowLeftOutlined />
            </button>
            <div style={{ width: 'calc(100% - 86px)', zIndex: 1 }}>
              <Slider {...settingsAwards} style={{ height: '100%' }} ref={c => setSliderRefAwards(c)}>
                {awards && awards.map((item, i) => {
                  return (
                    i % 2 == 0 ? <div className='__single_award' key={i}>
                      <Avatar alt={item.authorName} src={item.authorImage} />
                      <h1>{item.authorName}</h1>
                      <div className='__round'></div>
                      <div className='__dashed'></div>
                      <div className='__year'>{item.year}</div>
                      <div style={{ position: 'relative' }}>
                        <img className='imgss imageLoader' src={item.coverImage} alt={item.bookName} />
                        <span className='__category'>{item.categoryName}</span>
                      </div>
                    </div> :
                      <div className='__single_award' key={i}>
                        <div style={{ position: 'relative' }}>
                          <img className='imgss imageLoader' src={item.coverImage} alt={item.bookName} />
                          <span className='__category_bot'>{item.categoryName}</span>
                        </div>
                        <div className='__year'>{item.year}</div>
                        <div className='__dashed'></div>
                        <div className='__round'></div>
                        <h1>{item.authorName}</h1>
                        <Avatar alt={item.authorName} src={item.authorImage} />
                      </div>
                  )
                })}
              </Slider>
            </div>
            <button className="__award_button" onClick={() => slickNextAward()} >
              <ArrowRightOutlined />
            </button>
            <div className='__line'></div>
          </div>

        </div>
        <div className="__block">
          <div className="inner">
            <div className="c-view-all">
              <div className="c-view-all__row  text-medium">
                <a onClick={() => navigate('/awards')} className="bt-ico-left">
                  <strong className="link-underlined">{t('learn_more')}</strong>
                  <ArrowRightOutlined />
                </a>
              </div>
            </div>
          </div>


        </div>

      </div>

      <div style={{ background: '#fbfbfb', padding: '40px 0' }}>
        <div className="container-fluid">
          <Fade bottom duration={800} delay={300}>
            <div className="block __articles">
              <div className="inner">
                <div className="c-courses">
                  <div className="c-heading c-heading--small">
                    <div className="c-heading__top">
                      <h2 className="text-default">{t('articles')}</h2>
                    </div>
                    <div className="c-heading__middle">
                      <div className="c-heading__between">
                        <h3 className="heading-5" dangerouslySetInnerHTML={{ __html: t('latest_articles_of_he_muha') }}></h3>
                      </div>
                    </div>
                  </div>
                  <div className="has-sticker">

                    <ul className="grid-courses grid-courses--one-row">
                      {
                        isLoading ?
                          <>
                            {[...Array(4)].map((item, idx) => {
                              return (
                                <li className="grid-academy__item"  key={idx}>
                                  <div className="card-academy">
                                    <figure className="card-academy__figure">
                                      <Skeleton.Button active block style={{ height: '200px' }} />
                                    </figure>
                                    <div className="card-academy__info">
                                      <div className="card-academy__header">
                                        <div className="card-academy__row">
                                          <Skeleton active />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>

                              )
                            })}
                          </>
                          :
                          data?.map((item, idx) => {
                            return (
                              idx < 4 &&
                              <li className="grid-academy__item" key={idx}>
                                <div className="card-academy">
                                  <figure className="card-academy__figure">
                                    <a onClick={() => handleArticleNavigate(item?.id)} aria-label="Design Systems for Websites using Figma" data-controller="preview" data-preview-url-value="/academy/new/courses/5031-design-systems-for-websites-using-figma/modal" data-action="click->preview#preview">
                                      <img className="card-academy__media lazy lazy--loaded" src={item?.publicationIcon} />
                                    </a>
                                  </figure>
                                  <div className="card-academy__info">
                                    <div className="card-academy__header">
                                      <div className="card-academy__row">
                                        <p>{transformDate(item?.creationdate)}</p>
                                        <h3 className="card-academy__title"><a onClick={() => handleArticleNavigate(item?.id)}>{item?.name}</a></h3>
                                        <span className="card-academy__desc" onClick={() => handleArticleNavigate(item?.id)}>{item?.subbody}</span>
                                        <a className="__anchor" onClick={() => handleOpenViewArticle(item)}>{t('view_article')}</a>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                              </li>


                            )
                          })
                      }

                    </ul>



                  </div>

                </div>
              </div>
            </div>
          </Fade>
          <div className="__block">
            <div className="inner">
              <div className="c-view-all">
                <div className="c-view-all__row  text-medium">
                  {/* <span>Choose from over <strong>thousands</strong> of articles</span> */}
                  <a onClick={() => navigate('/he-articles')} className="bt-ico-left">
                    <strong className="link-underlined">{t('view_articles')}</strong>
                    <ArrowRightOutlined />
                  </a>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
      <ViewArticleModal open={open} onClose={() => setOpen(false)} content={content} />
      <ViewAppModal open={openApp} onClose={() => setOpenApp(false)} content={contentApp} />

    </>
  );
};

export default HomePage;
