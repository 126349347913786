// Core
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Styles
import './styles.scss';

// Assets

import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';

import Button from 'components/commonComponents/Button';
import { Button as ButtonAntD, Radio, Skeleton } from 'antd';
import PremimImg from 'assets/images/premium-quality.png'

import InfoModal from 'components/modals/InfoModal';

import { Fade } from "react-reveal";
import SubmitEntry from 'components/modals/SubmitAwardEntryModal';

const Awards = () => {
    const { currentLang } = useSelector((store) => store.commonData);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [awards, setData] = useState([]);
    const [type, setAlignment] = useState('year');
    const { t } = useTranslation();
    const [showInfo, setShowInfo] = useState(false)
    const [info, setInfo] = useState({ title: 'info', description: '' })
    const [showCondition, setShowCondition] = useState(false)
    const [condition, setCondition] = useState({ title: 'info', description: '' })
    const [showSubmitform, setShowSubmitform] = useState(false)
    const [isAuthor, setAuthor] = useState(false)

    const filterOptions = [
        {
            label: 'By Year',
            value: 'year',
        },
        {
            label: 'By Category',
            value: 'categoryId',
        }

    ];


    const filterIcons = {
        CameraswitchIcon: <svg xmlns="http://www.w3.org/2000/svg" height="16" width="20" viewBox="0 0 640 512"><path d="M213.1 64.8L202.7 96H128c-35.3 0-64 28.7-64 64V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H437.3L426.9 64.8C420.4 45.2 402.1 32 381.4 32H258.6c-20.7 0-39 13.2-45.5 32.8zM448 256c0 8.8-7.2 16-16 16H355.3c-6.2 0-11.3-5.1-11.3-11.3c0-3 1.2-5.9 3.3-8L371 229c-13.6-13.4-31.9-21-51-21c-19.2 0-37.7 7.6-51.3 21.3L249 249c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l19.7-19.7C257.4 172.7 288 160 320 160c31.8 0 62.4 12.6 85 35l23.7-23.7c2.1-2.1 5-3.3 8-3.3c6.2 0 11.3 5.1 11.3 11.3V256zM192 320c0-8.8 7.2-16 16-16h76.7c6.2 0 11.3 5.1 11.3 11.3c0 3-1.2 5.9-3.3 8L269 347c13.6 13.4 31.9 21 51 21c19.2 0 37.7-7.6 51.3-21.3L391 327c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-19.7 19.7C382.6 403.3 352 416 320 416c-31.8 0-62.4-12.6-85-35l-23.7 23.7c-2.1 2.1-5 3.3-8 3.3c-6.2 0-11.3-5.1-11.3-11.3V320z" /></svg>,
        FileUploadIcon: <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M288 109.3V352c0 17.7-14.3 32-32 32s-32-14.3-32-32V109.3l-73.4 73.4c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l128-128c12.5-12.5 32.8-12.5 45.3 0l128 128c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L288 109.3zM64 352H192c0 35.3 28.7 64 64 64s64-28.7 64-64H448c35.3 0 64 28.7 64 64v32c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V416c0-35.3 28.7-64 64-64zM432 456a24 24 0 1 0 0-48 24 24 0 1 0 0 48z" /></svg>,
        InfoOutlinedIcon: <svg xmlns="http://www.w3.org/2000/svg" height="16" width="6" viewBox="0 0 192 512"><path d="M48 80a48 48 0 1 1 96 0A48 48 0 1 1 48 80zM0 224c0-17.7 14.3-32 32-32H96c17.7 0 32 14.3 32 32V448h32c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H64V256H32c-17.7 0-32-14.3-32-32z" /></svg>,
        MapIcon: <svg xmlns="http://www.w3.org/2000/svg" height="16" width="18" viewBox="0 0 576 512"><path d="M565.6 36.2C572.1 40.7 576 48.1 576 56V392c0 10-6.2 18.9-15.5 22.4l-168 64c-5.2 2-10.9 2.1-16.1 .3L192.5 417.5l-160 61c-7.4 2.8-15.7 1.8-22.2-2.7S0 463.9 0 456V120c0-10 6.1-18.9 15.5-22.4l168-64c5.2-2 10.9-2.1 16.1-.3L383.5 94.5l160-61c7.4-2.8 15.7-1.8 22.2 2.7zM48 136.5V421.2l120-45.7V90.8L48 136.5zM360 422.7V137.3l-144-48V374.7l144 48zm48-1.5l120-45.7V90.8L408 136.5V421.2z" /></svg>,
        SearchIcon: <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" /></svg>,
        LightbulbIcon: <svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512"><path d="M297.2 248.9C311.6 228.3 320 203.2 320 176c0-70.7-57.3-128-128-128S64 105.3 64 176c0 27.2 8.4 52.3 22.8 72.9c3.7 5.3 8.1 11.3 12.8 17.7l0 0c12.9 17.7 28.3 38.9 39.8 59.8c10.4 19 15.7 38.8 18.3 57.5H109c-2.2-12-5.9-23.7-11.8-34.5c-9.9-18-22.2-34.9-34.5-51.8l0 0 0 0c-5.2-7.1-10.4-14.2-15.4-21.4C27.6 247.9 16 213.3 16 176C16 78.8 94.8 0 192 0s176 78.8 176 176c0 37.3-11.6 71.9-31.4 100.3c-5 7.2-10.2 14.3-15.4 21.4l0 0 0 0c-12.3 16.8-24.6 33.7-34.5 51.8c-5.9 10.8-9.6 22.5-11.8 34.5H226.4c2.6-18.7 7.9-38.6 18.3-57.5c11.5-20.9 26.9-42.1 39.8-59.8l0 0 0 0 0 0c4.7-6.4 9-12.4 12.7-17.7zM192 128c-26.5 0-48 21.5-48 48c0 8.8-7.2 16-16 16s-16-7.2-16-16c0-44.2 35.8-80 80-80c8.8 0 16 7.2 16 16s-7.2 16-16 16zm0 384c-44.2 0-80-35.8-80-80V416H272v16c0 44.2-35.8 80-80 80z" /></svg>
    }



    const handleChange = ({ target: { value } }) => {
        if (value !== null) {
            setAlignment(value);
            fetchAwards(value)
        }
    };

    const fetchAwards = async (type = 'year') => {
        setLoading(true)
        const res = await fetchData(restAPIs.awardsByType(currentLang?.id, type));
        setData(res.awards)
        setLoading(false);
    }
    useEffect(() => {
        fetchAwards();
        fetchInfo();
    }, [currentLang]);

    const handleOnClickAward = (typeId) => {
        navigate(`/awards/list/${type}/${typeId}`)
    }

    const fetchInfo = async () => {
        const res = await fetchData(restAPIs.getEVPages(currentLang?.id, 57));
        setInfo({ title: res.page.name, description: res.page.description })
        const res2 = await fetchData(restAPIs.getEVPages(currentLang?.id, 56));
        setCondition({ title: res2.page.name, description: res2.page.description })
    }

    const handleCloseModelInfo = () => {
        setShowInfo(false)
    }

    const handleCloseModelCond = () => {
        setShowCondition(false)
    }

    const handleOpenModelInfo = () => {
        setShowInfo(true)
    }

    const handleOpenModelCond = () => {
        setShowCondition(true)
    }

    const handleCloseModelSubmit = () => {
        setShowSubmitform(false)
    }

    const handleBook = (bookId) => {
        navigate(`/book/${bookId}`)
    }
    const handleMap = () => {
        navigate(`/book/map`)
    }

    const toggleImg = () => {
        let isauth = isAuthor
        setAuthor(!isauth)
    }

    return (
        <>

            <div className='container-fluid __articles_list' style={{ paddingTop: '12vh', paddingBottom: '6%' }}>
                <div className='inner'>
                    <div className="c-heading text-center">
                        {/* <div className="c-heading__top">
                            <h2 className="text-default"> Cultural</h2>
                        </div> */}
                        <div className="c-heading__middle">
                            <h3 className="heading-3 text-uppercase">{t('ib_awards')}</h3>
                        </div>
                        {/* <div className="c-heading__bottom">
                            <div className="c-heading__short-desc" >
                                <span>Some general articles among Cultural competitions</span>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="block __articles">
                    <div className="inner">
                        <div className="has-sticker">
                            <Fade bottom duration={800} delay={500}>
                                <div className='container mt-5' dir={currentLang?.id === 1 ? 'rtl' : 'ltr'}>
                                    <div className='d-flex justify-content-between mb-5'>
                                        <div></div>
                                        <div className='d-flex align-items-center' style={{ gap: '20px' }}>
                                            <ButtonAntD className='__filt_btn' title={isAuthor ? 'Switch to see book images' : 'Switch to see author images'} onClick={() => toggleImg()} type="primary" shape="circle" icon={filterIcons?.CameraswitchIcon} />
                                            <ButtonAntD className='__filt_btn' title='Map' onClick={() => handleMap()} type="primary" shape="circle" icon={filterIcons?.MapIcon} />
                                            {/* <ButtonAntD className='__filt_btn' type="primary" shape="circle" icon={filterIcons?.SearchIcon} /> */}
                                            <ButtonAntD title={info?.title} className='__filt_btn' onClick={() => handleOpenModelInfo()} type="primary" shape="circle" icon={filterIcons?.InfoOutlinedIcon} />
                                            <ButtonAntD title={condition?.title} className='__filt_btn' onClick={() => handleOpenModelCond()} type="primary" shape="circle" icon={filterIcons?.LightbulbIcon} />
                                            <ButtonAntD className='__filt_btn' onClick={() => setShowSubmitform(true)} type="primary" shape="circle" icon={filterIcons?.FileUploadIcon} />
                                            <Radio.Group
                                                options={filterOptions}
                                                onChange={handleChange}
                                                value={type}
                                                optionType="button"
                                                buttonStyle="solid"
                                            />
                                        </div>
                                    </div>

                                    {isLoading ?
                                        <div className='__awards_list'>
                                            {[...Array(5)].map((item, idx) => {
                                                return (
                                                    <a className="singleBook" key={idx} >
                                                        <div style={{ position: 'relative', background: '#0001' }}>
                                                            <Skeleton.Button active block style={{ height: '250px' }} />
                                                        </div>
                                                        <div className="singleBookInner">
                                                            <Skeleton active />
                                                        </div>
                                                    </a>
                                                )
                                            })}
                                        </div>
                                        :
                                        awards && awards.map((award, idx) => {
                                            return (
                                                <div className='__single_set mb-5' key={idx}>
                                                    <div className='d-flex justify-content-between mb-3'>
                                                        <h1 className='__sec_hd'>{award.typeName}</h1>
                                                        <Button
                                                            label={t('see_all')}
                                                            style={{ margin: 0, height: '42px' }}
                                                            handleOnClick={() => handleOnClickAward(award.typeId)}
                                                        />
                                                    </div>
                                                    <div className='__awards_list'>
                                                        {
                                                            award?.winners?.map((item, i) => {
                                                                return (
                                                                    <a className="singleBook" key={i} onClick={() => handleBook(item.bookId)}>
                                                                        <div style={{ position: 'relative', background: '#0001' }}>
                                                                            {isAuthor ? <img src={item.authorImage} /> : <img src={item.coverImage} />}
                                                                            <div className="bookBind"></div>
                                                                            <span className="subJect">{item.categoryName}</span>
                                                                            {item?.winner === 1 && <img src={PremimImg} alt="" className="__premim_qlit" />}
                                                                        </div>
                                                                        <div className="singleBookInner">
                                                                            <h1>{item.bookName}</h1>
                                                                            <h5>{item.authorName}</h5>
                                                                            <h6>{item?.year}</h6>
                                                                        </div>
                                                                    </a>
                                                                )
                                                            })
                                                        }
                                                        <div className='__dummy' onClick={() => handleOnClickAward(award.typeId)}>
                                                            {t('see_all')}
                                                        </div>
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        )
                                    }

                                    <InfoModal
                                        open={showInfo}
                                        onClose={handleCloseModelInfo}
                                        title={info.title}
                                        description={info.description}
                                    />

                                    <InfoModal
                                        open={showCondition}
                                        onClose={handleCloseModelCond}
                                        title={condition.title}
                                        description={condition.description}
                                    />
                                    <SubmitEntry open={showSubmitform} onClose={handleCloseModelSubmit} />

                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
};

export default Awards;
