// Core
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/commonComponents/Button';
import PropTypes from 'prop-types';

// Styles
import './styles.scss';
import { useState } from 'react';
import InputField from 'components/commonComponents/InputField';
import TextArea from 'components/commonComponents/TextArea';

import { useRef } from 'react';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import validator from 'validator';
import SelectBox from 'components/commonComponents/SelectBox';

import { Alert, Checkbox, Modal } from 'antd';
import { FilePdfOutlined, CloseOutlined, PictureOutlined } from '@ant-design/icons';

const SubmitEntry = (props) => {
  const { currentLang } = useSelector((store) => store.commonData);
  const { onClose, open, title } = props;
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [errorAlert, setErrorAlert] = useState({ msg: '', key: Math.random(), open: false, severity: '' })
  const [error, setError] = useState('');
  const [data, setData] = useState({});

  const identity = useRef(null);
  const [identityCard, setIdentityCard] = useState(null);

  const personalPicRef = useRef(null);
  const [personalPic, setPersonalPic] = useState(null);

  const pdfRef = useRef(null);
  const [pdfDoc, setPDF] = useState(null);

  const [checked, setChecked] = React.useState(false);
  const [countries, setCountries] = useState([])

  const { literaryname,
    manuscriptname,
    nomineename,
    phone,
    email,
    nationality,
    countryofresidence,
    description,
    biography,
  } = data

  useEffect(() => {
    fetchCountry();
  }, [currentLang]);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      setData({})
      setError('')
      setErrorAlert({})
      setIdentityCard(null)
      setPersonalPic(null)
      setPDF(null)
      onClose();
    }
  };

  const handleIdentityClick = event => {
    identity.current.click();
  };

  const handleIdentityInput = (file) => {
    let files = file.target.files
    var reader = new FileReader();
    reader.readAsDataURL(files.item(0));
    reader.onload = (_event) => {
      setIdentityCard({ img: reader.result, file: files.item(0) })
    }
  }

  const handlePersonalPicClick = event => {
    personalPicRef.current.click();
  };

  const handlePersonalPicInput = (file) => {
    let files = file.target.files
    var reader = new FileReader();
    reader.readAsDataURL(files.item(0));
    reader.onload = (_event) => {
      setPersonalPic({ img: reader.result, file: files.item(0) })
    }
  }

  const handlePDFClick = event => {
    pdfRef.current.click();
  };

  const handlePDFInput = (file) => {
    let files = file.target.files
    setPDF({ extension: files.item(0).name.split('.').pop(), file: files.item(0) })
  }

  const fetchCountry = async () => {
    const res = await fetchData(restAPIs.countryInfos(currentLang?.id));
    let countries = []
    for (let i = 0; i < res.country.length; i++) {
      const element = res.country[i];
      countries.push({ value: element.value, label: element.label })
    }
    setCountries(countries)

  }

  const submitForm = async () => {
    const errors = {
      literaryname: !literaryname,
      manuscriptname: !manuscriptname,
      nomineename: !nomineename,
      phone: !phone,
      email: !validator.isEmail(email) && 'Enter valid email',
      nationality: !nationality,
      countryofresidence: !countryofresidence,
      description: !description,
      biography: !biography,
    };
    setError(errors);
    let isFormValid = Object.values(errors).every((item) => !item);
    if (!isFormValid) return;
    if (pdfDoc === null) return;
    if (identityCard === null) return;
    if (personalPic === null) return;
    setIsLoading(true);
    const formData = new FormData();
    formData.append('action', 'contact')
    formData.append('literaryname', literaryname)
    formData.append('manuscriptname', manuscriptname)
    formData.append('nomineename', nomineename)
    formData.append('phone', phone)
    formData.append('email', email)
    formData.append('nationality', nationality)
    formData.append('countryofresidence', countryofresidence)
    formData.append('description', description)
    formData.append('biography', biography)
    formData.append('literaryFile', pdfDoc.file)
    formData.append('idFile', identityCard.file)
    formData.append('nomineePhotoFile', personalPic.file)
    const res = await fetchData(restAPIs.nominationForm(formData));
    if (res.statusCode === 200) {
      setData({})
      setIdentityCard(null)
      setPersonalPic(null)
      setPDF(null)
      setErrorAlert({ msg: 'Form submitted successfully', key: Math.random(), open: true, severity: 'success' });
      setTimeout(() => {
        setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
      }, 3000);
    }
    setIsLoading(false);
  }

  const handleOnChange = (key) => (value) => {
    const isCategory = ['nationality'].includes(key) || ['countryofresidence'].includes(key);
    const _value = isCategory ? value.target.value : value;
    setError({
      ...error,
      [key]: null
    });
    setData({
      ...data,
      [key]: _value
    });
  };

  return (
    <>



      <Modal
        open={open}
        onClose={handleClose}
        className='__common_model_class'
        keyboard={false}
        maskClosable={false}
        footer={null}
        closable={false}
      >
        <div className='__common_model_content'>
          <h3 className='____common_model_class_head' style={{ marginBottom: '10px' }}> Submit your entry for the prize</h3>
          <div className='____common_model_class_desc' style={{ width: '100%' }}>
            <div className='mt-2'>
              <div className='mb-3'>
                <InputField
                  type="text"
                  placeholder='Literary name'
                  label='Literary name'
                  onChange={handleOnChange('literaryname')}
                  error={error.literaryname}
                  value={literaryname}
                />
              </div>
              <div className='mb-3'>
                <InputField
                  type="text"
                  placeholder='Manuscript Name'
                  label='Manuscript Name'
                  onChange={handleOnChange('manuscriptname')}
                  error={error.manuscriptname}
                  value={manuscriptname}
                />
              </div>
              <div className='mb-3'>
                <InputField
                  type="text"
                  placeholder='Nominee Name'
                  label='Nominee Name'
                  onChange={handleOnChange('nomineename')}
                  error={error.nomineename}
                  value={nomineename}
                />
              </div>
              <div className='mb-3'>
                <InputField
                  type="number"
                  placeholder='Phone'
                  label='Phone'
                  onChange={handleOnChange('phone')}
                  error={error.phone}
                  value={phone}
                />
              </div>
              <div className='mb-3'>
                <InputField
                  type="email"
                  placeholder='Email'
                  label='Email'
                  onChange={handleOnChange('email')}
                  error={error.email}
                  value={email}
                />
              </div>

              <div className='mb-3'>
                <SelectBox
                  value={nationality}
                  handleOnChange={handleOnChange('nationality')}
                  label={'Nationality'}
                  name='Nationality'
                  placeholder={'Nationality'}
                  options={countries}
                  error={error.nationality}
                />
              </div>

              <div className='mb-3'>
                <SelectBox
                  value={countryofresidence}
                  handleOnChange={handleOnChange}
                  label={'Country of Residence'}
                  name='countryofresidence'
                  placeholder={'Country of Residence'}
                  options={countries}
                  error={error.countryofresidence}
                />



              </div>

              <div className='mb-3'>
                <TextArea
                  type="text"
                  placeholder='Description'
                  label='Description'
                  height={100}
                  onChange={handleOnChange('description')}
                  error={error.description}
                  value={description}
                />
              </div>
              <div className='mb-3'>
                <TextArea
                  type="text"
                  placeholder='Biography'
                  label='Biography'
                  height={100}
                  onChange={handleOnChange('biography')}
                  error={error.biography}
                  value={biography}
                />
              </div>
              <div className='__border_dashed'></div>
              <div className='mb-3'>
                <h6 style={{ marginBottom: '10px' }}>An electronic copy of the manuscript (PDF / Word)</h6>
                <div className='d-flex __more_img_arb' >
                  <div className='__more_img_subm' onClick={handlePDFClick} style={{ borderColor: pdfDoc !== null ? 'initial' : 'red' }}>
                    <FilePdfOutlined />
                    <input type="file" ref={pdfRef} accept=".pdf, .doc, .docx" style={{ display: 'none' }} onChange={handlePDFInput} />
                  </div>

                  {pdfDoc && <div className='__more_img_image' style={{ width: '70px', height: '70px', border: 0 }}>
                    <img src={`https://ibnbatuta.ae/${pdfDoc.extension}.png`} alt='image' />
                    <span className='__closbt' onClick={() => setPDF(null)}>
                      <CloseOutlined />
                    </span>
                  </div>}
                </div>
              </div>
              <div className='mb-3'>
                <h6 style={{ marginBottom: '10px' }}>A copy of the passport or identity card</h6>
                <div className='d-flex __more_img_arb' >
                  <div className='__more_img_subm' onClick={handleIdentityClick} style={{ borderColor: identityCard !== null ? 'initial' : 'red' }}>
                    <PictureOutlined />
                    <input type="file" ref={identity} accept=".jpg, .jpeg" style={{ display: 'none' }} onChange={handleIdentityInput} />
                  </div>

                  {identityCard && <div className='__more_img_image' style={{ width: '70px', height: '70px' }}>
                    <img src={identityCard.img} alt='image' />
                    <span className='__closbt' onClick={() => setIdentityCard(null)}>
                      <CloseOutlined />
                    </span>
                  </div>}
                </div>
              </div>
              <div className='mb-3'>
                <h6 style={{ marginBottom: '10px' }}>A print-sized personal photo</h6>
                <div className='d-flex __more_img_arb' >
                  <div className='__more_img_subm' onClick={handlePersonalPicClick} style={{ borderColor: personalPic !== null ? 'initial' : 'red' }}>
                    <PictureOutlined />
                    <input type="file" ref={personalPicRef} accept=".jpg, .jpeg" style={{ display: 'none' }} onChange={handlePersonalPicInput} />
                  </div>

                  {personalPic && <div className='__more_img_image' style={{ width: '70px', height: '70px' }}>
                    <img src={personalPic.img} alt='image' />
                    <span className='__closbt' onClick={() => setPersonalPic(null)}>
                      <CloseOutlined />
                    </span>
                  </div>}
                </div>
              </div>
              <div className='mb-3'>
                <p><Checkbox
                  checked={checked}
                  onChange={handleChange}
                /> I agree to the <a href="#">terms of nomination for the award</a></p>
              </div>
            </div>
          </div>

          {errorAlert && errorAlert.open ?
            <Alert type={errorAlert.severity} sx={{ width: '100%' }} message={errorAlert.msg} />
            : ''}
          <div className='d-flex align-items-center justify-content-end' style={{ marginTop: ' 15px', gridGap: '10px' }}>
            <a onClick={() => handleClose(false)} style={{ cursor: 'pointer', margin: '0 10px' }}>{t('cancel')}</a>
            <Button
              withLoader
              loading={isLoading}
              disabled={checked ? isLoading : true}
              handleOnClick={submitForm}
              label={t('submit')}
            />
          </div>
        </div>
      </Modal>










      {/* 
      <Dialog
        open={open}
        onClose={handleClose}
        className='__common_model_class'
        maxWidth={'sm'}
        fullWidth={true}
        disableEscapeKeyDown
      >
        <DialogTitle id="alert-dialog-title">
          Submit your entry for the prize
        </DialogTitle>
        <DialogContent>
          <div className='mt-2'>
            <div className='mb-3'>
              <InputField
                type="text"
                placeholder='Literary name'
                label='Literary name'
                onChange={handleOnChange('literaryname')}
                error={error.literaryname}
                value={literaryname}
              />
            </div>
            <div className='mb-3'>
              <InputField
                type="text"
                placeholder='Manuscript Name'
                label='Manuscript Name'
                onChange={handleOnChange('manuscriptname')}
                error={error.manuscriptname}
                value={manuscriptname}
              />
            </div>
            <div className='mb-3'>
              <InputField
                type="text"
                placeholder='Nominee Name'
                label='Nominee Name'
                onChange={handleOnChange('nomineename')}
                error={error.nomineename}
                value={nomineename}
              />
            </div>
            <div className='mb-3'>
              <InputField
                type="number"
                placeholder='Phone'
                label='Phone'
                onChange={handleOnChange('phone')}
                error={error.phone}
                value={phone}
              />
            </div>
            <div className='mb-3'>
              <InputField
                type="email"
                placeholder='Email'
                label='Email'
                onChange={handleOnChange('email')}
                error={error.email}
                value={email}
              />
            </div>

            <div className='mb-3'>
              <SelectBox
                value={nationality}
                handleChange={handleOnChange('nationality')}
                label={'Nationality'}
                name='Nationality'
                placeholder={'Nationality'}
                options={countries}
                error={error.nationality}
              />
            </div>

            <div className='mb-3'>
              <SelectBox
                value={countryofresidence}
                handleChange={handleOnChange('countryofresidence')}
                label={'Country of Residence'}
                name='countryofresidence'
                placeholder={'Country of Residence'}
                options={countries}
                error={error.countryofresidence}
              />
            </div>

            <div className='mb-3'>
              <TextArea
                type="text"
                placeholder='Description'
                label='Description'
                height={100}
                onChange={handleOnChange('description')}
                error={error.description}
                value={description}
              />
            </div>
            <div className='mb-3'>
              <TextArea
                type="text"
                placeholder='Biography'
                label='Biography'
                height={100}
                onChange={handleOnChange('biography')}
                error={error.biography}
                value={biography}
              />
            </div>
            <div className='__border_dashed'></div>
            <div className='mb-3'>
              <h6>An electronic copy of the manuscript (PDF / Word)</h6>
              <div className='d-flex __more_img_arb' >
                <div className='__more_img_subm' onClick={handlePDFClick} style={{borderColor: pdfDoc !== null ?'initial':'red'}}>
                  <FilePdfOutlined />
                  <input type="file" ref={pdfRef} accept=".pdf, .doc, .docx" style={{ display: 'none' }} onChange={handlePDFInput} />
                </div>

                {pdfDoc && <div className='__more_img_image' style={{ width: '70px', height: '70px', border: 0 }}>
                  <img src={`https://ibnbatuta.ae/${pdfDoc.extension}.png`} alt='image' />
                  <span onClick={() => setPDF(null)}>
                    
                  <CloseOutlined />
                    </span>
                </div>}
              </div>
            </div>
            <div className='mb-3'>
              <h6>A copy of the passport or identity card</h6>
              <div className='d-flex __more_img_arb' >
                <div className='__more_img_subm' onClick={handleIdentityClick} style={{borderColor: identityCard !== null ?'initial':'red'}}>
                <PictureOutlined />
                  <input type="file" ref={identity} accept=".jpg, .jpeg" style={{ display: 'none' }} onChange={handleIdentityInput} />
                </div>

                {identityCard && <div className='__more_img_image' style={{ width: '70px', height: '70px' }}>
                  <img src={identityCard.img} alt='image' />
                  <span onClick={() => setIdentityCard(null)}>
                  <CloseOutlined />
                  </span>
                </div>}
              </div>
            </div>
            <div className='mb-3'>
              <h6>A print-sized personal photo</h6>
              <div className='d-flex __more_img_arb' >
                <div className='__more_img_subm' onClick={handlePersonalPicClick} style={{borderColor: personalPic !== null ?'initial':'red'}}>
                <PictureOutlined />
                  <input type="file" ref={personalPicRef} accept=".jpg, .jpeg" style={{ display: 'none' }} onChange={handlePersonalPicInput} />
                </div>

                {personalPic && <div className='__more_img_image' style={{ width: '70px', height: '70px' }}>
                  <img src={personalPic.img} alt='image' />
                  <span onClick={() => setPersonalPic(null)}>
                  <CloseOutlined />
                  </span>
                </div>}
              </div>
            </div>
            <div className='mb-3'>
              <p><Checkbox
                checked={checked}
                onChange={handleChange}
              /> I agree to the <a href="#">terms of nomination for the award</a></p>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div className='d-flex align-items-center' style={{ margin: '15px' }}>
            <a onClick={() => handleClose(false)} style={{ cursor: 'pointer', margin: '0 10px' }}>{t('cancel')}</a>
            <Button
              withLoader
              loading={isLoading}
              disabled={checked?isLoading:true}
              handleOnClick={submitForm}
              label={t('submit')}
            />
          </div>
        </DialogActions>
        {errorAlert && errorAlert.open ? 
          <Alert type={errorAlert.severity} sx={{ width: '100%' }} message={errorAlert.msg} />
         : ''}
      </Dialog> */}
    </>
  );
};

export default SubmitEntry;

SubmitEntry.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
};
