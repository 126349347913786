// Core
import styled, { css } from 'styled-components';

export const StyledButton = styled.button`
  font-weight: 600;
  border: none;
  outline: none;
  height: 55px;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in;
  position: relative;
  background: #97a51a;
  border-radius: 15px;
  color: white;
  margin: 0;
  padding: 0px 40px;
  min-width: 100px;
  line-height: 1;
  padding-top: 2px;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
      pointer-events: none;
    `}
`;
