// Core
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Styles
import './styles.scss';

// Assets

import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';

import Button from 'components/commonComponents/Button';
import { Button as ButtonAntD, Radio, Skeleton } from 'antd';
import PremimImg from 'assets/images/premium-quality.png'

import { Fade } from "react-reveal";

import { PhotoProvider, PhotoView } from 'react-photo-view';
import ReactPlayer from 'react-player';

const AwardsAll = () => {
    const { currentLang } = useSelector((store) => store.commonData);
    const [isLoading, setLoading] = useState(false);
    const [awards, setData] = useState([]);
    const [photos, setPhotos] = useState([])
    const [videos, setVideos] = useState([])
    const [page, setPage] = useState('')
    const { type, typeId } = useParams();
    const [awardStatementTitle, setAwardStatementTitle] = useState('')
    const [awardStatementDesc, setAwardStatementDesc] = useState('')
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isAuthor, setAuthor] = useState(false)

    const filterIcons = {
        CameraswitchIcon: <svg xmlns="http://www.w3.org/2000/svg" height="16" width="20" viewBox="0 0 640 512"><path d="M213.1 64.8L202.7 96H128c-35.3 0-64 28.7-64 64V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H437.3L426.9 64.8C420.4 45.2 402.1 32 381.4 32H258.6c-20.7 0-39 13.2-45.5 32.8zM448 256c0 8.8-7.2 16-16 16H355.3c-6.2 0-11.3-5.1-11.3-11.3c0-3 1.2-5.9 3.3-8L371 229c-13.6-13.4-31.9-21-51-21c-19.2 0-37.7 7.6-51.3 21.3L249 249c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l19.7-19.7C257.4 172.7 288 160 320 160c31.8 0 62.4 12.6 85 35l23.7-23.7c2.1-2.1 5-3.3 8-3.3c6.2 0 11.3 5.1 11.3 11.3V256zM192 320c0-8.8 7.2-16 16-16h76.7c6.2 0 11.3 5.1 11.3 11.3c0 3-1.2 5.9-3.3 8L269 347c13.6 13.4 31.9 21 51 21c19.2 0 37.7-7.6 51.3-21.3L391 327c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-19.7 19.7C382.6 403.3 352 416 320 416c-31.8 0-62.4-12.6-85-35l-23.7 23.7c-2.1 2.1-5 3.3-8 3.3c-6.2 0-11.3-5.1-11.3-11.3V320z" /></svg>,
    }

    const fetchAwards = async () => {
        setLoading(true)
        const res = type === 'year' ? await fetchData(restAPIs.awardsList(currentLang?.id, null, typeId)) : await fetchData(restAPIs.awardsList(currentLang?.id, typeId, null))
        setData(res.items)
        setPage(res)
        if (res.photos)
            setPhotos(res.photos)
        if (res.videos)
            setVideos(res.videos)
        if (res.awardStatementTitle)
            setAwardStatementTitle(res.awardStatementTitle)
        if (res.awardStatementDesc)
            setAwardStatementDesc(res.awardStatementDesc)

        setLoading(false);
    }
    useEffect(() => {
        fetchAwards();
    }, [currentLang]);

    const handleBook = (bookId) => {
        navigate(`/book/${bookId}`)
    }

    const toggleImg = () => {
        let isauth = isAuthor
        setAuthor(!isauth)
    }


    return (
        <>


            <div className='container-fluid __articles_list' style={{ paddingTop: '12vh', paddingBottom: '6%' }}>
                <div className='inner'>
                    <div className="c-heading text-center">
                        {/* <div className="c-heading__top">
                            <h2 className="text-default"> Cultural</h2>
                        </div> */}
                        <div className="c-heading__middle">
                            <h3 className="heading-3 text-uppercase">{t('ib_awards')}</h3>
                        </div>
                        <div className="c-heading__bottom">
                            <div className="c-heading__short-desc" >
                                <span style={{ fontSize: '2em' }}>{type !== 'year' ? awards[0]?.categoryName : awards[0]?.year}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="block __articles">
                    <div className="inner">
                        <div className="has-sticker">
                            <Fade bottom duration={800} delay={500}>
                                <div className='container mt-5' dir={currentLang?.id === 1 ? 'rtl' : 'ltr'}>
                                    <div className='d-flex justify-content-between mb-5'>
                                        <div></div>
                                        <div className='d-flex align-items-center' style={{ gap: '20px' }}>
                                            <span>{isAuthor ? 'Books' : 'Awardees'}</span>
                                            <ButtonAntD className='__filt_btn' title={isAuthor ? 'Switch to see book images' : 'Switch to see author images'} onClick={() => toggleImg()} type="primary" shape="circle" icon={filterIcons?.CameraswitchIcon} />
                                        </div>
                                    </div>

                                    {isLoading ?
                                        <div className='__awards_list'>
                                            {[...Array(5)].map((item, idx) => {
                                                return (
                                                    <a className="singleBook" key={idx} >
                                                        <div style={{ position: 'relative', background: '#0001' }}>
                                                            <Skeleton.Button active block style={{ height: '250px' }} />
                                                        </div>
                                                        <div className="singleBookInner">
                                                            <Skeleton active />
                                                        </div>
                                                    </a>
                                                )
                                            })}
                                        </div>
                                        :
                                        <div className='__awards_list'>
                                            {
                                                awards.map((item, i) => {
                                                    return (
                                                        <a className="singleBook" key={i} onClick={() => handleBook(item.bookId)}>
                                                            <div style={{ position: 'relative', background: '#0001' }}>
                                                                {isAuthor ? <img src={item.authorImage} /> : <img src={item.coverImage} />}
                                                                <div className="bookBind"></div>
                                                                <span className="subJect">{item.categoryName}</span>
                                                                {item?.winner === 1 && <img src={PremimImg} alt="" className="__premim_qlit" />}
                                                            </div>
                                                            <div className="singleBookInner">
                                                                <h1>{item.bookName}</h1>
                                                                <h5>{item.authorName}</h5>
                                                                <h6>{item?.year}</h6>
                                                            </div>
                                                        </a>
                                                    )
                                                })
                                            }
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                    }
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>

                <div className='__block __articles'>
                    <div className='inner'>
                        <div className="has-sticker">
                            <Fade bottom duration={800} delay={500}>

                                <div className='container mt-5' dir={currentLang?.id === 1 ? 'rtl' : 'ltr'}>
                                    <h1 className='__sec_head mt-5'>{awardStatementTitle && awardStatementTitle}</h1>
                                    <div>
                                        <p dangerouslySetInnerHTML={{ __html: awardStatementDesc && awardStatementDesc }}></p>
                                    </div>

                                    <h1 className='__sec_head mt-5'>{photos.length > 0 ? t('photos') : ''}</h1>
                                    <PhotoProvider>
                                        <div className='__photos-list mt-3'>
                                            {photos && photos.map((item, idx) => {
                                                return (
                                                    <PhotoView src={item.picture} >
                                                        <img src={item.picture} alt="" key={idx} loading="lazy" />
                                                    </PhotoView>
                                                )
                                            })}
                                            <div></div>
                                            <div></div>
                                        </div>
                                    </PhotoProvider>
                                    <h1 className='__sec_head mt-5'>{videos.length > 0 ? t('videos') : ''}</h1>
                                    <div className='__videos-list mt-3'>
                                        {videos && videos.map((item, idx) => {
                                            return (
                                                <div className='player-wrapper'>
                                                    <ReactPlayer url={item.url} controls={true}
                                                        className='react-player'
                                                        width='100%'
                                                        height='100%'
                                                    />
                                                </div>
                                            )
                                        })}
                                        <div></div>
                                        <div></div>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default AwardsAll;
