
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import './style.scss';
import { Spin } from 'antd';
import { Fade } from 'react-reveal';

const Policy = (props) => {
  const { id } = props;
  const { currentLang } = useSelector((store) => store.commonData);
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { t } = useTranslation();

  const fetchContent = async () => {
    setLoading(true)
    const res = await fetchData(restAPIs.getEVPages(currentLang?.id, id));
    setData(res.page);
    setLoading(false)
  };


  useEffect(() => {
    window.scrollTo(0, 0);
    fetchContent();
  }, [currentLang, id]);
  return (
    <>
      <Fade bottom>
        <div className='container __articles_list' style={{ paddingTop: '20vh', paddingBottom: '6%' }}>
          <div className='inner'>
            <div className="c-heading text-center">
              <div className="c-heading__middle">
                <h3 className="heading-3 text-uppercase">{data?.name}</h3>
              </div>
            </div>
          </div>
          <div className="block">
            <p className='_____descr' dangerouslySetInnerHTML={{ __html: data?.description }} ></p>
          </div>
        </div>
      </Fade>
    </>

  );
};

export default Policy;
