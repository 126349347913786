// Core
import React, { useEffect, useState } from 'react';

// Styles
import './styles.scss';

// Assets

import { useNavigate } from 'react-router-dom';
import ViewArticleModal from 'components/modals/ViewArticleModal';
import { Fade } from 'react-reveal';
import { Skeleton } from 'antd';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';


const Articles = () => {
    const { currentLang } = useSelector((store) => store.commonData);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState([])
    const [content, setContent] = useState('')
    const {t} = useTranslation()

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleArticleNavigate = (id) => {
        navigate('/article/' + id)
    }

    const fetchArticles = async () => {
        setIsLoading(true)
        const res = await fetchData(restAPIs.articlesListing(currentLang?.id,1));
        setData(res.articles)
        setIsLoading(false);
    }
    useEffect(() => {
        fetchArticles();
    }, [currentLang]);

    const transformDate = (date) => {
        const currentDate = new Date(date)
        const monthNames = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
          ];

        const day = currentDate.getDate();
        const month = currentDate.getMonth(); // Month is zero-based, so add 1
        const year = currentDate.getFullYear();
        const formattedDate = `${day} ${monthNames[month]}, ${year}`;
        return formattedDate
    }

    const handleOpenViewArticle = (data) =>{
        setContent(data)
        setOpen(true)
    }


    return (
        <>
            <div className='container-fluid __articles_list' style={{ paddingTop: '11vh', paddingBottom: '6%' }}>
                <div className='inner'>
                    <div className="c-heading text-center">
                        <div className="c-heading__top">
                            <h2 className="text-default"> H.E - General</h2>
                        </div>
                        <div className="c-heading__middle">
                            <h3 className="heading-3 text-uppercase">{t('articles')}</h3>
                        </div>
                        <div className="c-heading__bottom">
                            <div className="c-heading__short-desc">
                                <span>Some fantastic articles of H.E - General listed below</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="block __articles">
                    <div className="inner">
                        <div className="has-sticker">
                        <Fade bottom duration={800} delay={300}>
                            <ul className="grid-courses grid-courses--one-row">
                                {
                                    isLoading ?
                                        <>
                                            {[...Array(4)].map((item, idx) => {
                                                return (
                                                    
                                                        <li className="grid-academy__item" key={idx}>
                                                            <div className="card-academy">
                                                                <figure className="card-academy__figure">
                                                                    <Skeleton.Button active block style={{ height: '200px' }} />
                                                                </figure>
                                                                <div className="card-academy__info">
                                                                    <div className="card-academy__header">
                                                                        <div className="card-academy__row">
                                                                            <Skeleton active />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>

                                                )
                                            })}
                                        </>
                                        :
                                        data?.map((item, idx) => {
                                            return (
                                                    <li className="grid-academy__item"  key={idx}>
                                                        <div className="card-academy">
                                                            <figure className="card-academy__figure">
                                                                <a onClick={() => handleArticleNavigate(item?.id)}>
                                                                    <img className="card-academy__media lazy lazy--loaded" src={item?.publicationIcon}  />
                                                                </a>
                                                            </figure>
                                                            <div className="card-academy__info">
                                                                <div className="card-academy__header">
                                                                    <div className="card-academy__row">
                                                                        <p>{transformDate(item?.creationdate)}</p>
                                                                        <h3 className="card-academy__title"><a onClick={() => handleArticleNavigate(item?.id)}>{item?.name}</a></h3>
                                                                        <span className="card-academy__desc">{item?.subbody}</span>
                                                                        <a className="__anchor" onClick={() => handleOpenViewArticle(item)}>{t('view_article')}</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                    </li>

                                            )
                                        })
                                }

                            </ul>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>

            <ViewArticleModal open={open} onClose={() => setOpen(false)} content={content}/>
        </>
    );
};

export default Articles;
