// Core
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// components

// Styles
import './styles.scss';

// Assets
import { Collapse, Popconfirm, message } from 'antd';

import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';
import ChangePassword from 'components/modals/ChangePassword';
import ChangeName from 'components/modals/ChangeName';
import ChangeEmail from 'components/modals/ChangeEmail';
import AddInvitation from 'components/modals/AddInvitation';
import DeleteAccount from 'components/modals/DeleteAccount';
import { useRef } from 'react';
import { APP_ID } from 'config/constants';
import { CameraOutlined, CheckCircleFilled, DeleteOutlined, EditOutlined, EllipsisOutlined, HistoryOutlined, LogoutOutlined, MailOutlined, CrownOutlined } from '@ant-design/icons';
import RestoreAccount from 'components/modals/RestoreAccount';
import LogOut from 'components/modals/LogOut';
import { Fade } from 'react-reveal';

import Logo from 'assets/images/ev_logo.png'
import { useNavigate } from 'react-router-dom';

const { Panel } = Collapse;
const Settings = () => {
    const { userDetails, currentLang } = useSelector((store) => store.commonData);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [openLogOut, setLogOutOpen] = useState(false);
    const [openRestoreAccount, setOpenRestoreAccount] = useState(false);
    const [openDeleteAccount, setOpenDeleteAccount] = useState(false);
    const [openPswd, setOpenPaswd] = useState(false);
    const [openName, setOpenName] = useState(false);
    const [openEmail, setOpenEmail] = useState(false);
    const [openInvitation, setOpenInvitation] = useState(false)
    const [changeImg, setChangeImage] = useState({});
    const hiddenChangeImg = useRef(null);

    const [messageApi, contextHolder] = message.useMessage();
    const [activePanel, setActivePanel] = useState('');
    const [subscriptionList, setSubscriptionList] = useState('')

    const [confirmLoadingAppId, setConfirmLoadingAppId] = useState('');
    const [appId, setAppId] = useState('')
    const [isOpenConfirm, setOpenConfirm] = useState(false)

    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
        userSubscriptionList();
    }, [currentLang]);

    const handleOpenChangePaswd = () => {
        setOpenPaswd(true);
    };

    const handleClosePaswd = (value) => {
        setOpenPaswd(false);
    };

    const handleOpenChangeName = () => {
        setOpenName(true);
    };
    const handleCloseName = () => {
        setOpenName(false);
    };

    const handleOpenChangeEmail = () => {
        setOpenEmail(true);
    };
    const handleCloseEmail = () => {
        setOpenEmail(false);
    };

    const handleOpenDeleteAccount = () => {
        setOpenDeleteAccount(true);
    };
    const handleCloseDeleteAccount = () => {
        setOpenDeleteAccount(false);
    };

    const handleOpenRestoreAccount = () => {
        setOpenRestoreAccount(true);
    };

    const handleCloseRestoreAccount = () => {
        setOpenRestoreAccount(false);
    };

    const handleCloseInvitation = (value) => {
        setOpenInvitation(false);
    };

    const handleProfileInput = (file) => {
        let files = file.target.files
        const reader = new FileReader();
        if (files.item(0)) {
            reader.readAsDataURL(files.item(0));
            reader.onload = (_event) => {
                setChangeImage({ img: reader.result, file: files.item(0) })
            }
        }
    }

    const handleOpenChangePicture = event => {
        hiddenChangeImg.current.click();
    };

    const changeProfilePic = async () => {
        const formData = new FormData();
        formData.append('profile_pic', changeImg.file)
        formData.append('appid', APP_ID)
        const res = await fetchData(restAPIs.changeProfPic(formData));
        if (res.statusCode === 200) {
            messageApi.open({
                type: 'success',
                content: res.errormessage,
            });
            let localDetails = JSON.parse(localStorage.getItem('userDetails'));
            localDetails.profile_pic = res.profile_pic + '&' + Math.random()
            dispatch({
                type: 'userDetails',
                value: localDetails
            });
            localStorage.setItem('userDetails', JSON.stringify(userDetails));
            setChangeImage({})

        } else {
            messageApi.open({
                type: 'error',
                content: res.errormessage,
            });
        }
    }

    const onCollapseChange = (key) => {
        setActivePanel(key);
    };

    const userSubscriptionList = async () => {
        const formData = new FormData();
        formData.append('language', currentLang?.id)
        const res = await fetchData(restAPIs.subscriptionList(formData));
        if (res.statusCode === 200)
            setSubscriptionList(res.entities)
    }


    const transformDate = (date) => {
        const currentDate = new Date(date)
        const monthNames = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];

        const day = currentDate.getDate();
        const month = currentDate.getMonth()
        const year = currentDate.getFullYear();
        const formattedDate = `${day} ${monthNames[month]}, ${year}`;
        return formattedDate

    }

    const openConfirm = (appid) => {
        setAppId(appid)
    }

    const onConfirm = async (appid) => {
        setConfirmLoadingAppId(appid);
        const formData = new FormData();
        formData.append('appId', appid)
        const res = await fetchData(restAPIs.unSubscribe(formData));
        console.log("res", res);
        if (res.statusCode === 200) {
            setAppId('');
            setConfirmLoadingAppId('');
            userSubscriptionList();
        }
    }

    const handleCancel = () => {
        setAppId('');
    };

    return (

        <>
            <Fade bottom >
                <div className='container __articles_list' style={{ paddingTop: '20vh', paddingBottom: '6%' }}>
                    <div className='inner'>
                        <div className="c-heading text-center">
                            <div className="c-heading__top">
                                <h2 className="text-default"> {t('profile')}</h2>
                            </div>
                            <div className="c-heading__middle">
                                <h3 className="heading-3 text-uppercase">{t('settings')}</h3>
                            </div>
                        </div>
                    </div>
                    <div className='block mt-5'>
                        <div className='inner'>
                            <div>
                                {contextHolder}
                                <div className="profile">
                                    <div style={{ flex: 1, paddingTop: '30px' }}>
                                        <div className="prfImg">
                                            {changeImg?.img ?
                                                <img src={changeImg?.img} alt={userDetails?.username} />
                                                :
                                                <img src={userDetails?.profile_pic} alt={userDetails?.username} />
                                            }
                                            {changeImg?.img && <a className='savePic' onClick={changeProfilePic}><CheckCircleFilled /></a>}


                                        </div>
                                        <h3>{userDetails?.username}</h3>
                                        <p style={{ marginBottom: '30px', textAlign: 'center' }}>{userDetails?.email}</p>
                                    </div>
                                    <div className="profCont">

                                        <a style={{ margin: '0 10px' }} onClick={handleOpenChangeName}><EditOutlined style={{ margin: '0 10px' }} />{t('change_name')}</a>
                                        <a style={{ margin: '0 10px' }} onClick={handleOpenChangeEmail}><MailOutlined style={{ margin: '0 10px' }} />{t('change_email')}</a>
                                        <a style={{ margin: '0 10px' }} onClick={handleOpenChangePaswd}><EllipsisOutlined style={{ margin: '0 10px' }} />{t('change_password')}</a>
                                        <a style={{ margin: '0 10px' }} onClick={handleOpenChangePicture}><CameraOutlined style={{ margin: '0 10px' }} />{t('edit_picture')}</a>

                                        <a className='___health' style={{ margin: '0 10px' }} onClick={()=>navigate('/healthcheck')}>
                                            <>
                                            <svg style={{ margin: '0 10px', width:'20px' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M142.4 21.9c5.6 16.8-3.5 34.9-20.2 40.5L96 71.1V192c0 53 43 96 96 96s96-43 96-96V71.1l-26.1-8.7c-16.8-5.6-25.8-23.7-20.2-40.5s23.7-25.8 40.5-20.2l26.1 8.7C334.4 19.1 352 43.5 352 71.1V192c0 77.2-54.6 141.6-127.3 156.7C231 404.6 278.4 448 336 448c61.9 0 112-50.1 112-112V265.3c-28.3-12.3-48-40.5-48-73.3c0-44.2 35.8-80 80-80s80 35.8 80 80c0 32.8-19.7 61-48 73.3V336c0 97.2-78.8 176-176 176c-92.9 0-168.9-71.9-175.5-163.1C87.2 334.2 32 269.6 32 192V71.1c0-27.5 17.6-52 43.8-60.7l26.1-8.7c16.8-5.6 34.9 3.5 40.5 20.2zM480 224a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"/></svg></>
                                            Health Check</a>
                                        <input type="file" onChange={handleProfileInput} ref={hiddenChangeImg} style={{ display: 'none' }} />

                                        <Collapse
                                            ghost
                                            className='__collapse__items_settings'
                                            accordion
                                            activeKey={activePanel}
                                            onChange={onCollapseChange}
                                            expandIcon={({ isActive }) => <CrownOutlined />}
                                        >
                                            <Panel key={1} header={<a>{t('subscription')}</a>}>
                                                <div className='___subs_List'>
                                                    {
                                                        subscriptionList && subscriptionList?.map((item, idx) => {
                                                            return (
                                                                <Fade bottom duration={1000}>
                                                                    <div className='__sinlgle_sub_list_item'>
                                                                        <img src={item?.applicationIcon} alt="" />
                                                                        <div>
                                                                            <h1>{item?.appName}</h1>
                                                                            <p>{item?.subscriptionName}</p>
                                                                            <span>Expires on <b>{transformDate(item?.expiryDate)}</b></span>
                                                                        </div>
                                                                        <Popconfirm
                                                                            title={item?.appName}
                                                                            description="Do you want to unsubscribe"
                                                                            onConfirm={() => onConfirm(item?.appId)}
                                                                            onCancel={handleCancel}
                                                                            okText="Ok"  // Add okText prop
                                                                            okType="primary" // Add okType prop
                                                                            cancelText="Cancel" // Optional: Add cancelText prop
                                                                            onOpenChange={() => console.log('open change')}
                                                                            open={appId === item?.appId}
                                                                            okButtonProps={{ loading: appId === confirmLoadingAppId }}
                                                                        >
                                                                            <button onClick={() => openConfirm(item?.appId)}>{t('unsubscribe')}</button>
                                                                        </Popconfirm>

                                                                    </div>
                                                                </Fade>
                                                            )
                                                        })
                                                    }
                                                    {subscriptionList && subscriptionList.length === 0 ? 'No Subscriptions found.!' :''}
                                                </div>
                                            </Panel>
                                        </Collapse>

                                        {
                                            userDetails && (userDetails.markDeletion == 0) ?
                                                <a style={{ margin: '0 10px' }} onClick={handleOpenDeleteAccount}><DeleteOutlined style={{ margin: '0 10px' }} />{t('delete_account')}</a> :
                                                <a style={{ margin: '0 10px' }} onClick={handleOpenRestoreAccount}><HistoryOutlined style={{ margin: '0 10px' }} />{t('restore_account')}</a>
                                        }
                                        <a style={{ margin: '0 10px' }} onClick={() => setLogOutOpen(true)}><LogoutOutlined style={{ margin: '0 10px' }} />{t('sign_out')}</a>
                                    </div>
                                </div>
                                <ChangePassword open={openPswd} onClose={handleClosePaswd} />
                                <ChangeName open={openName} onClose={handleCloseName} />
                                <ChangeEmail open={openEmail} onClose={handleCloseEmail} />
                                <AddInvitation open={openInvitation} onClose={handleCloseInvitation} />
                                <DeleteAccount open={openDeleteAccount} onClose={handleCloseDeleteAccount} />
                                <RestoreAccount open={openRestoreAccount} onClose={handleCloseRestoreAccount} />
                                <LogOut open={openLogOut} onClose={() => setLogOutOpen(false)} />
                            </div>
                        </div>
                    </div>
                </div>
            </Fade>
        </>
    );
};


export default Settings;
