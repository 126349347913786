import {
  USER_DETAILS,
  SET_LANGUAGE,
  ARTICLES_LIST,
  PROJECTS_LIST,
  SET_LOCATION_BOOK
} from 'config/actionTypes';
import initialState from './initialState';

const commonData = (state = initialState, action) => {
  switch (action.type) {
    case USER_DETAILS:
      return {
        ...state,
        userDetails: action.value
      };
    case SET_LANGUAGE:
      return {
        ...state,
        currentLang: action.value
      };
    case ARTICLES_LIST:
      return {
        ...state,
        articlesList: action.value
      };
    case PROJECTS_LIST:
      return {
        ...state,
        projectList: action.value
      };
      case SET_LOCATION_BOOK:
      return {
        ...state,
        locationByBook: action.value
      };

    default:
      return state;
  }
};
export default commonData;
