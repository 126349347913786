// Core
import React, { useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Styles
import './styles.scss';
import { useSelector } from 'react-redux';
import Login from 'components/modals/Login';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { useEffect } from 'react';

import Button from 'components/commonComponents/Button';
import { ArrowRightOutlined, CheckCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { Fade } from 'react-reveal';
import InputField from 'components/commonComponents/InputField';
import { APP_ID } from 'config/constants';

// icons

const HealthCheck = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { userDetails, currentLang } = useSelector((store) => store.commonData);
  const [scanId, setScanId] = useState('')


  const getScanId = async () => {
    setLoading(true)
    let formData = new FormData()
    formData.append('appId', APP_ID)
    formData.append('language', currentLang?.id)
    formData.append('action', 'scanId')

    const res = await fetchData(restAPIs.healthScanFedo(formData))
    console.log(":rs", res?.scanId);
    setScanId(res?.scanId)
    setLoading(false)
  }

  useEffect(() => {
    getScanId();
  }, [])

  return (
    <>

      <div className='container-fluid __articles_list' style={{ paddingTop: '11vh', paddingBottom: '6%' }}>
        <div className='inner'>
          <div className="c-heading text-center">
            {/* <div className="c-heading__top">
              <h2 className="text-default"> {appData?.name} </h2>
            </div> */}
            <div className="c-heading__middle">
              <h3 className="heading-3 text-uppercase">Health Check</h3>
            </div>
            {/* <div className="c-heading__bottom">
              <div className="c-heading__short-desc">
                <span>{appData?.tagline}</span>
              </div>
            </div> */}
          </div>
        </div>

        <div className='block'>
          <div className='inner'>
            <div className='row justify-content-center mb-2'>
              <div className='col-md-12'>
                {loading ? <LoadingOutlined/> :<iframe style={{ width: '100%', height: '100vh' }} allow="camera; microphone" src={`https://fedo.ai/products/vitals/webapp/vitals?orgId=161&custId=${userDetails?.userid}&userId=781&tenantId=161&clientId=electronicvillage&scanId=${scanId && scanId}`} frameborder="0"></iframe>}
              </div>
            </div>
          </div>

        </div>
      </div>

    </>
  );
};

export default HealthCheck;