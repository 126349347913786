// Core
import { combineReducers } from 'redux';

/// Others
import commonData from './commonData.reducer';

const rootReducer = combineReducers({
  commonData
});
export default rootReducer;
