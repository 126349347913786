// Core
import styled, { css } from 'styled-components';

export const InputWrapper = styled.div`
  position : relative;
  overflow: hidden;
  label {
    color: ${({ theme }) => theme.inputLabelColor};
    font-size: 0.9rem;
    margin-bottom: 10px;
    display: block;
    font-weight: 600;
  }

  input {
    display: block;
    width: 100%;
    border: 1px solid #939f3273;
    outline: none;
    height: 48px;
    font-size: 0.9rem;
    padding: 0px 20px;
    border-radius: 25px;
    color: rgb(110, 110, 110);
    transition: all 0.1s ease 0s;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  .__icon {
    position: absolute;
    right: 16px;
    top: 11px;
    z-index: 1;
    cursor: pointer;
    svg {
      font-size: 19px;
      color: #555;
  }
}

  ${({ error }) =>
    error &&
    css`
      label {
        color: #e35061;
        font-weight: 600;
      }
      p {
        color: #e35061;
        font-weight: 400;
        margin: 0;
        font-size: 14px;
        margin-left: 20px;
        margin-top:2px;
        display: flex;
    }
    input {
      border-color: #e35061 !important;
    }
      }
    `}
`;
