// Core
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/commonComponents/Button';
import PropTypes from 'prop-types';

// Styles
import './styles.scss';
import { Modal } from 'antd';

const InfoModal = (props) => {
  const { onClose, open, title, description } = props;
  const { t } = useTranslation();

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      onClose();
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className='__common_model_class'
        footer={null}
        closable={false}
      >
        <div className='__common_model_content'>
          <h3 className='____common_model_class_head' style={{marginBottom:'10px'}}> {title}</h3>
          <div className='.____common_model_class_desc'>
            <p style={{ whiteSpace: 'pre-line', marginBottom: 0 }} dangerouslySetInnerHTML={{ __html: description }}></p>
          </div>
          <div className='d-flex align-items-center justify-content-end' style={{ marginTop: ' 15px', gridGap: '10px' }}>
            <Button
              label={t('cancel')}
              handleOnClick={() => handleClose(false)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default InfoModal;

InfoModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
};
