// Core
import React, { useEffect, useState } from 'react';

// Styles
import './styles.scss';

// Assets
import { Carousel } from "react-responsive-carousel";
import { AndroidOutlined, IeOutlined, AppleOutlined } from '@ant-design/icons';
import { ArrowLeftOutlined, CrownOutlined, ShareAltOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';
import { Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SocialShare from 'components/modals/SocialShare';
import Button from 'components/commonComponents/Button';
import ReactPlayer from 'react-player';

const ViewApp = () => {
    const { currentLang } = useSelector((store) => store.commonData);
    const navigate = useNavigate();
    const { appId } = useParams();
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState([])
    const { t } = useTranslation()
    const [openShareSocial, setOpenShareSocial] = useState(false);
    const [isPremium, setIsPremium] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchApp();
        checkSubscription();
    }, [currentLang]);


    const fetchApp = async () => {
        setIsLoading(true)
        const res = await fetchData(restAPIs.applicationListing(currentLang?.id, 1, appId));
        setData(res.applications[0])
        setIsLoading(false);
    }

    const handleOpenShareSocial = () => {
        setOpenShareSocial(true);
    };
    const handleCloseShareSocial = () => {
        setOpenShareSocial(false);
    };



    const checkSubscription = async () => {

        const formData = new FormData();
        formData.append('language', currentLang?.id)
        formData.append('appId', appId)
        const res = await fetchData(restAPIs.subscriptionForm(formData));
        setIsPremium(res?.isPremium)
    };

    return (
        <>
            {isLoading ?
                <>
                    <div className='pt-5'>
                        <div className='container' style={{ paddingTop: '10%', paddingBottom: '6%' }}>
                            <div className='inner'>
                                <div className="head-toolbar is-sm-center">
                                    <div className="head-toolbar__left">
                                        <a className="bt-ico-left"> <Skeleton.Button active /></a>
                                    </div>
                                    <div className="head-toolbar__right">
                                        <div className="head-toolbar__right">

                                            <ul className="toolbar-bts">
                                                <li key={1}>
                                                    <Skeleton.Button active />
                                                </li>
                                                {/* <li  key={2}>
                                                    <Skeleton.Button active />
                                                </li> */}
                                            </ul>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="inner">
                                <div className="c-work" style={{ height: '80vh' }}>
                                    <div className="c-work__left" >
                                        <div className="m-asset">
                                            <div className="m-asset__header" style={{ width: '100%' }}>
                                                <h3 className="m-asset__title"><Skeleton.Button active style={{ width: '300px' }} /></h3>
                                            </div>
                                            <div className="m-asset__buy">
                                                <div className="m-asset__from">
                                                    <a >
                                                        <div><small><Skeleton.Button active /></small></div>
                                                    </a>
                                                </div>
                                                <div className="m-asset__from">
                                                    <a >
                                                        <div><small><Skeleton.Button active /></small></div>
                                                    </a>
                                                </div>
                                                <div className="m-asset__from">
                                                    <a >
                                                        <div><small><Skeleton.Button active /></small></div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="m-asset__desc">
                                                <Skeleton active />
                                            </div>
                                            <div className="m-asset__footer">
                                                <div className="m-asset__curated">
                                                    <Skeleton.Button active />
                                                </div>
                                                <div className="m-asset__date">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="c-work__right __inside_detail" >
                                        <div style={{ width: '100%' }}>
                                            <Skeleton.Image active style={{ height: '60vh', width: '100% ' }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                :
                <div className='pt-5'>
                    <div className='container' style={{ paddingTop: '10%', paddingBottom: '6%' }}>
                        <div className='inner'>
                            <div className="head-toolbar is-sm-center">
                                <div className="head-toolbar__left">
                                    <a className="bt-ico-left" onClick={() => navigate(-1)}> <ArrowLeftOutlined style={{ paddingBottom: '2px' }} />{t('back')}</a>
                                </div>
                                <div className="head-toolbar__right">
                                    <div className="head-toolbar__right">

                                        <ul className="toolbar-bts">
                                            <li key={1}>
                                                <ShareAltOutlined onClick={handleOpenShareSocial} />
                                            </li>
                                            {/* <li key={2}>
                                                <InfoCircleOutlined />
                                            </li> */}
                                        </ul>

                                        <SocialShare
                                            open={openShareSocial}
                                            onClose={handleCloseShareSocial}
                                            title={data?.name}
                                            description={data?.description}
                                            image={data?.coverImage}
                                            url={window.location.href}
                                            summary={data?.description}
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="inner" style={{ minHeight: '80vh' }}>
                            <div className="c-work" style={{ height: 'auto' }}>
                                <div className="c-work__left">
                                    <div className="m-asset">
                                        <div>
                                            {data?.tagline}
                                        </div>
                                        <div className="m-asset__header">
                                            <h3 className="m-asset__title">{data?.name}</h3>
                                        </div>
                                        <div>
                                            Genre : {data?.typeName}
                                        </div>
                                        <div className="m-asset__buy">
                                            <div className="m-asset__from">
                                                <a href={data?.googleStoreLink} target='_blank'>
                                                    <div><small>{t('playstore')}</small></div>
                                                    <div className="m-asset__price"><AndroidOutlined /></div>
                                                </a>
                                            </div>
                                            <div className="m-asset__from">
                                                <a href={data?.appStoreLink} target="_blank">
                                                    <div><small>{t('appstore')}</small></div>
                                                    <div className="m-asset__price">  <AppleOutlined /></div>
                                                </a>
                                            </div>
                                            <div className="m-asset__from">
                                                <a href={data?.websiteLink} target="_blank">
                                                    <div><small>{t('web')}</small></div>
                                                    <div className="m-asset__price"> <IeOutlined />
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="m-asset__desc">
                                            <p dangerouslySetInnerHTML={{ __html: data?.description }} ></p>
                                        </div>
                                        <div className="m-asset__footer">
                                            <div className="m-asset__date">
                                                <p style={{ marginBottom: '10px' }}>{t('diveIntoACuratedCollectionOfExclusiveContentAvailableOnlyToOurPremiumSubscribers')}</p>
                                                {isPremium ? <a className='__unsub'>You are already Subscribed</a> : <><Button icon={<CrownOutlined />} label='Subscribe' handleOnClick={() => navigate('/subscribe/' + appId)} /></>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="c-work__right __inside_detail"  dir='ltr'>
                                    <Carousel showArrows={false} showStatus={false} showIndicators={false} swipeable emulateTouch infiniteLoop>
                                        {data?.screenshots?.map((item, idx) => {
                                            return (
                                                <div style={{ borderRadius: '10px', overflow: "hidden" }} key={idx}>
                                                    <img alt="" src={item} style={{ objectFit: 'contain' }} />
                                                </div>
                                            )
                                        })
                                        }
                                    </Carousel>

                                    {data && data?.videoURL != '' && <div className='__video_sec mt-5 pt-5'>
                                        <div className='player-wrapper'>
                                            <ReactPlayer url={data?.videoURL} controls={true}
                                                className='react-player'
                                                width='100%'
                                                height='100%'
                                            />
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            }
        </>
    );
};

export default ViewApp;
