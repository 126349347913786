// Core
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// components

// Styles
import './styles.scss';

// Assets

import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';
import { Skeleton } from 'antd';
import { APP_ID } from 'config/constants';
import { Fade } from 'react-reveal';

const About = () => {
    const { currentLang } = useSelector((store) => store.commonData);
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [aboutData, setAboutData] = useState({});
    const [founderData, setFounderData] = useState('');
    const { t } = useTranslation();
    const [teams, setTeams] = useState([])
// eslint-disable-next-line
    const fetchAboutData = async () => {
        setLoading(true)
        const res = await fetchData(restAPIs.applicationListing(currentLang?.id, 1, APP_ID));
        setAboutData(res.applications[0]);
        setLoading(false)
    };// eslint-disable-next-line

// eslint-disable-next-line
    const fetchFounder = async () => {
        setLoading(true)
        const res = await fetchData(restAPIs.getFounderInfo(currentLang?.id));
        setFounderData(res.founder[0]);
        setLoading(false)
    };// eslint-disable-next-line
// eslint-disable-next-line
    const fetchTeams = async () => {
        const res = await fetchData(restAPIs.getEmployeeList(2));
        setTeams(res.entities);// eslint-disable-next-line
    }

// eslint-disable-next-line
    useEffect(() => {
        window.scrollTo(0, 0);
        fetchAboutData();
        fetchFounder();
        fetchTeams()
    }, [currentLang]);// eslint-disable-next-line

    return (
        <>
            <Fade bottom >
                <div className='container __articles_list' style={{ paddingTop: '20vh', paddingBottom: '6%' }}>
                    <div className='inner'>
                        <div className="c-heading text-center">
                            <div className="c-heading__top">
                                <h2 className="text-default"> {aboutData?.tagline}</h2>
                            </div>
                            <div className="c-heading__middle">
                                <h3 className="heading-3 text-uppercase">{aboutData?.name}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="block __articles">
                        <div className="inner">
                            <figure>
                                <img className='__cover_im' src={aboutData?.coverImage} alt="" />
                            </figure>
                            <p className='_____descr' dangerouslySetInnerHTML={{ __html: aboutData?.description }} ></p>
                        </div>
                    </div>
                    <div className='block mt-5'>
                        <div className='inner'>
                            <div className="card-about pt-5">
                                <div className="card-about__left">
                                    <div className="card-about__info">
                                        <p>{t('founder_n_CEO')}</p>
                                        <h3 className="heading-5 card-about__title">{t('muhammed_ahmed_suwaidi')}</h3>
                                        <div className="card-about__excerpt">
                                            <p dangerouslySetInnerHTML={{ __html: founderData?.description }}></p>
                                        </div>
                                        <div className="text-medium hidden-sm">
                                            <a className="bt-ico-left">
                                                <strong className="link-underlined" onClick={() => { navigate('/founder') }}>{t('more_info')}</strong>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-about__right">
                                    <figure>
                                        <a>
                                            <img className="card-about__media" src="https://data.electronicvillage.org/images/mohammedsuwaidi.jpg" alt="" />
                                        </a>
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='inner mt-5'>
                        <div className="c-heading text-center pt-5">
                            <div className="c-heading__top">
                                <h2 className="text-default"> Our Leading, Strong And Creative Team</h2>
                            </div>
                            <div className="c-heading__middle">
                                <h3 className="heading-3 text-uppercase">Teams</h3>
                            </div>
                        </div>

                        <div className='block'>
                            <div className='__teams_list'>
                                {isLoading ? <>
                                    {[...Array(4)].map((item, idx) => {
                                        return (
                                            <div className='__team_single'>
                                                <figure>
                                                    <Skeleton.Button active block style={{ height: '200px' }} />
                                                </figure>
                                                <div className='__content'>
                                                    <Skeleton active />
                                                </div>
                                            </div>
                                        )
                                    })}
                                </> :
                                    <>
                                        {teams.map((item, idx) => {
                                            return (
                                                <div className='__team_single'>
                                                    <figure>
                                                        <img className="card-about__media" src={item?.picture} alt="" />
                                                    </figure>
                                                    <div className='__content'>
                                                        <h3>{item?.name}</h3>
                                                        <p>{item?.designation}</p>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </>
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </Fade>
        </>
    );
};

export default About;