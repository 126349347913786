import { APP_ID, BASE_URL, USER_HOST } from 'config/constants';
import { method } from 'lodash';

export const restAPIs = {
  login: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_login.php`
  }),
  signUp: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_userRegistration.php`
  }),
  changePassword: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_user_changePassword`
  }),
  changeProfPic: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_user_changeProfilePic`
  }),
  changeLanguage: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_user_changeLanguage`
  }),
  otpVerify: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_otpverify`
  }),
  forgotPassword: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_forgotPassword`
  }),
  resetPassword: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_user_resetPassword`
  }),
  changeName: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_user_changeUsername.php`
  }),
  getWallet: (body) => ({
    method: 'get',
    endpoint: `${USER_HOST}/wallet.php`
  }),
  getCountryList: (body) => ({
    method: 'get',
    endpoint: `${USER_HOST}/countryinfos.php`
  }),
  contactUs: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_contactus.php`
  }),
  changeEmail: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_user_changeEmail`
  }),
  changeEmailOTP: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_sendOtp`
  }),
  deleteAccount: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_user_deleteUser`
  }),
  etisalatEncryption: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/etisalat_encrypt.php`
  }),
  getAppInfo: (appId, language) => ({
    method: 'get',
    endpoint: `${BASE_URL}/json_appInfo.php?appId=${appId}&language=${language}`
  }),
  getFounderInfo: (language) => ({
    method: 'get',
    endpoint: `${BASE_URL}/json_founder.php?language=${language}`
  }),
  getEVApps: (language) => ({
    method: 'get',
    endpoint: `${BASE_URL}/ev_json_applications.php?language=${language}`
  }),
  getAstroInDetail: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_astro_indetail.php`
  }),
  translateData: (client, sl, tl, dt, q) => ({
    method: 'get',
    endpoint: `https://translate.googleapis.com/translate_a/single?client=${client}&sl=${sl}&tl=${tl}&dt=${dt}&q=${q}`
  }),
  getSubscriptions: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_subscriptions.php`
  }),
  subscriptionForm: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_subscription_form.php`
  }),
  encryption: (data) => ({
    method: 'post',
    endpoint: `${USER_HOST}/etisalat_encrypt.php?data=${data}`
  }),
  etisalatTerms: (pageId, language) => ({
    method: 'get',
    endpoint: `${BASE_URL}/json_evPages.php?pageId=${pageId}&language=${language}`
  }),
  simBilling: (body) => ({
    method: 'post',
    body,
    endpoint: `${BASE_URL}/etisalatsimbilling.php`
  }),
  getEVPages: (language, pageId) => ({
    method: 'get',
    endpoint: `${BASE_URL}/json_evPages.php?language=${language}&pageId=${pageId}`
  }),
  helpAndSupport: (language) => ({
    method: 'get',
    endpoint: `${USER_HOST}/json_appHelpSupport.php?appId=${APP_ID}&language=${language}`
  }),
  myWallet: (language) => ({
    method: 'get',
    endpoint: `${BASE_URL}/json_page.php?pageId=39&language=${language}`
  }),
  countryInfos: (language) => ({
    method: 'get',
    endpoint: `${USER_HOST}/countryinfos.php?language=${language}`
  }),
  unSubscribe: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/unsubscribe.php`,
  }),

  articlesListing: (language, page, articleId, appId, categoryId) => ({
    method: 'get',
    endpoint: `${BASE_URL}/json_articles.php?language=${language}${page ? '&page=' +page : ''}${appId ? '&appId=' +appId : ''}${articleId ? '&articleId=' +articleId : ''}${categoryId ? '&categoryId=' +categoryId : ''}`
  }),
  articleDetail: (articleid) => ({
    method: 'get',
    endpoint: `${BASE_URL}/ev_json_article.php?articleid=${articleid}`
  }),

  applicationListing: (language, page, appId=null) => ({
    method: 'get',
    endpoint: `${USER_HOST}/json_applications.php?language=${language}${page ? '&page=' +page : ''}${appId? '&appId='+appId : ''}`
  }),

  getEmployeeList: (language) =>({
    method: 'get',
    endpoint: `${USER_HOST}/json_emp_list.php?language=${language}`
  }),

  categoryListings: (language) => ({
    method: 'get',
    endpoint: `${BASE_URL}/json_categories.php?language=${language}`
  }),

  awardsList:(language, categoryId, year, page) => ({
    method : 'get',
    endpoint : `https://ib.electronicvillage.org/json_ibAwards.php?language=${language}${categoryId ? `&categoryId=${categoryId}` : ''}${year ? `&year=${year}` : ''}${page ? `&page=${page}` : ''}`
  }),
  awardsByLocation:(language) => ({
    method : 'get',
    endpoint : `https://ib.electronicvillage.org/json_ibawards_locations.php?language=${language}`
  }),

  awardsByType:(language, type) => ({
    method : 'get',
    endpoint : `https://ib.electronicvillage.org/json_ibAwards_byType.php?language=${language}&groupType=${type}`
  }),
  bookInfo:(bookId, language) =>({
    method : 'get',
    endpoint :`https://alwaraq.net/json_booklist.php?ibAwards=1&bookId=${bookId}&language=${language}`
  }),
  bookByLocation:(language, latitude, longitude) =>({
    method : 'get',
    endpoint :`https://alwaraq.net/json_booklist.php?ibAwards=1&latitude=${latitude}&language=${language}&longitude=${longitude}`
  }),


  likeArticle: (body) => ({
    method: 'post',
    body,
    endpoint: `${BASE_URL}/json_article_like_form.php`
  }),
  
  commentArticle: (body) => ({
    method: 'post',
    body,
    endpoint: `${BASE_URL}/json_article_comment_form.php`
  }),

  articlesSearch:(searchtext, option, page, language) =>({
    method : 'get',
    endpoint :`${BASE_URL}/Corehe/search/ev_search?WordForm=1&searchtext=${searchtext}&option=${option}&page=${page}&offset=${page}&language=${language}`
  }),

  alwaraqSearch:(searchtext, option, page, language) =>({
    method : 'get',
    endpoint :`${BASE_URL}/json_searchAlwaraq.php?WordForm=1&sortOrder=asc&searchtext=${searchtext}&option=${option}&page=${page}&offset=${page}&language=${language}`
  }),

  ibnBattutaSearch: (body) => ({
    method: 'post',
    body,
    endpoint: `https://ib.electronicvillage.org/json_searchAll.php`
  }),

  scienceMuseumSearch: (searchtext, option, page, language) => ({
    method: 'get',
    endpoint: `https://sciencemuseum.electronicvillage.org/json_museum.php?searchtext=${searchtext}&option=${option}&page=${page}&offset=${page}&language=${language}`
  }),

  allProjectsSearch: (searchtext, option, page, language) => ({
    method: 'get',
    endpoint: `https://ib.electronicvillage.org/json_museum.php?searchtext=${searchtext}&option=${option}&page=${page}&offset=${page}&language=${language}`
  }),

  cinemapediaSearch: (searchtext, option, page, language) => ({
    method: 'get',
    endpoint: `https://cinemapediaapi.electronicvillage.org/search.php?searchtext=${searchtext}&option=${option}&page=${page}&language=${language}`
  }),

  uaehistorySearch: (searchtext, option, page, language) => ({
    method: 'get',
    endpoint: `https://uaehistory.ae/api/json_articles?searchtext=${searchtext}&searchOption=${option}&page=${page}&language=${language}`
  }),

  chatGPT :(body)=>({
    method:'post',
    body,
    endpoint:`https://evusers.electronicvillage.org/chatgpt`,
  }),

  
  getChatFAQ:(subjectId, language, subSubjectId) =>({
    method:'get',
    endpoint: `${USER_HOST}/json_chatGPT_list.php?appId=${APP_ID}&subjectId=${subjectId}&language=${language}${subSubjectId ? `&subSubjectId=${subSubjectId}` : ''}`
  }),
  
  subscriptionList :(body)=>({
    method:'post',
    body,
    endpoint:`${USER_HOST}/json_user_subscriptionsList.php`
  }),

  healthScanFedo:(body) =>({
    method:'post',
    body,
    endpoint:`${USER_HOST}/json_healthScanFedo.php`
  }),

  galleryPhotoList:(categoryId, language, page)=>({
    method:'get',
    endpoint:`https://www.electronicvillage.org/json_photos.php?categoryId=${categoryId}&language=${language}&page=${page}`
  }),

  galleryCategories:(language)=>({
    method:'get',
    endpoint:`https://www.electronicvillage.org/json_photocategories.php?language=${language}`
  }),


  
};
