// Core
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Styles
import './styles.scss';
import Button from 'components/commonComponents/Button';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { Modal } from 'antd';

const EtisalatTerms = (props) => {
  const { onClose, open, pageId } = props;
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation();

  const handleClose = (event, reason) => {
    onClose();
  };

  useEffect(() => {
    fetchTerms();
  }, []);

  const fetchTerms = async () => {
    setIsLoading(true)
    const res = await fetchData(restAPIs.etisalatTerms(pageId));
    setData(res.page);
    setIsLoading(false);
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className='__common_model_class'
        footer={null}
        closable={false}
      >
        <div className='__common_model_content'>
          <h3 className='____common_model_class_head' style={{ marginBottom: '10px' }}> {data?.name}</h3>
          <div className='.____common_model_class_desc'>
            <p style={{ whiteSpace: 'pre-line', marginBottom: 0 }} dangerouslySetInnerHTML={{ __html: data?.description }}></p>
          </div>
          <div className='d-flex align-items-center justify-content-end' style={{ marginTop: ' 15px', gridGap: '10px' }}>
            <Button
              label={t('cancel')}
              handleOnClick={() => handleClose(false)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EtisalatTerms;

EtisalatTerms.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  pageId: PropTypes.any
};
