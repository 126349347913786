// Core
import React, { useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Styles
import './styles.scss';
import { useSelector } from 'react-redux';
import Login from 'components/modals/Login';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { useEffect } from 'react';

import Button from 'components/commonComponents/Button';

import CryptoJS from 'crypto-js';
import $ from 'jquery';
import * as moment from 'moment-timezone';
import { Spin } from 'antd';
import { ArrowRightOutlined, CreditCardOutlined, MobileOutlined } from '@ant-design/icons';
import { Fade } from 'react-reveal';

const SubscribePayment = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const { userDetails, currentLang } = useSelector((store) => store.commonData);
  const [data, setData] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [selectedType, setType] = useState(0)
  const { subId, appId } = useParams();
  const [orderId, setOrderId] = useState('')
  const [callbackURLSuccess, setCallbackURLSuccess] = useState('')
  const [callbackURLFailure, setCallbackURLFailure] = useState('')
  const [subs, setSubs] = useState('')
  const [dateTime, setDateTime] = useState('')
  const [hashExtended, setHashExtented] = useState('')

  const [referralCode, setreferralCode] = useState('')
  const [discountCode, setdiscountCode] = useState('')
  const [searchParams] = useSearchParams();

  const [appData, setAppData] = useState('')

  const fetchSubscriptions = async () => {
    const formData = new FormData();
    formData.append('language', currentLang?.id)
    formData.append('appId', appId)
    if (searchParams.get('refcode'))
      formData.append('refCode', searchParams.get('refcode'))
    if (searchParams.get('discountCode'))
      formData.append('discountCode', searchParams.get('discountCode'))
    const res = await fetchData(restAPIs.getSubscriptions(formData));
    for (let i = 0; i < res.info.length; i++) {
      if (subId == res.info[i].subscriptionId)
        setSubs(res.info[i])
    }
  };

  const handleCloseModel = () => {
    setShow(false);
  };

  const fetchPaymentMethods = async () => {
    setLoading(true)
    const formData = new FormData();
    formData.append('language', currentLang?.id)
    formData.append('appId', appId)
    const res = await fetchData(restAPIs.subscriptionForm(formData));
    setData(res.paymentMethods);
    setLoading(false)
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setreferralCode(searchParams.get('refcode'))
    setdiscountCode(searchParams.get('discountCode'))
    fetchApp();
    fetchSubscriptions();
    fetchPaymentMethods();
    preLoad();
  }, [currentLang]);

  const preLoad = async () => {

    const formData = new FormData();
    formData.append('appId', appId)
    formData.append('action', 'orderNow')
    formData.append('subscriptionId', subId)
    formData.append('paymentMethodId', '1')
    const res = await fetchData(restAPIs.subscriptionForm(formData));
    setOrderId(res.orderId)
    let callBackUrlSuccess = 'https://alwaraq.net/payment/orderSubComplete.php?returnUrl=https://electronicvillage.org/thanks/&orderId=' + res.orderId + '&sessiontoken=' + userDetails?.sessionToken + '&appId=' + appId + '&transactionStatus=1'
    let callBackUrlFailure = 'https://alwaraq.net/payment/orderSubComplete.php?returnUrl=' + window.location.href + '&orderId=' + res.orderId + '&sessiontoken=' + userDetails?.sessionToken + '&appId=' + appId + '&transactionStatus=0'
    setCallbackURLSuccess(callBackUrlSuccess)
    setCallbackURLFailure(callBackUrlFailure)


    $(function () {
      function updateDatetime() {
        $("input[name='txndatetime']").val(moment().tz('Asia/Dubai').format('YYYY:MM:DD-HH:mm:ss'));
        setDateTime(moment().tz('Asia/Dubai').format('YYYY:MM:DD-HH:mm:ss'))
      }
      $("select[name='timezone']").change(function () {
        updateDatetime();
      });

      $("#paymentForm").submit(function (event) {
        var environmentUrl = 'https://www.ipg-online.com/connect/gateway/processing';
        var paymentForm = $("#paymentForm");
        paymentForm.attr('action', environmentUrl);
        var paymentParameters = paymentForm.serializeArray().filter(function (item) {
          return item.value !== "";
        }).reduce(function (obj, item) {
          obj[item.name] = item.value;
          return obj;
        }, {});
        const sharedSecret = "F'5sQ\\5ESn";
        var messageSignatureContent = [];
        const ignoreSignatureParameteres = ["hashExtended"];
        Object.keys(paymentParameters).filter(key => !ignoreSignatureParameteres.includes(key)).sort().forEach(function (key, index) {
          messageSignatureContent.push(paymentParameters[key]);
        });

        const messageSignature = CryptoJS.HmacSHA256(messageSignatureContent.join("|"), sharedSecret);
        const messageSignatureBase64 = CryptoJS.enc.Base64.stringify(messageSignature);
        $("input[name='hashExtended']").val(messageSignatureBase64);
        setHashExtented(messageSignatureBase64)
      });

      updateDatetime();
    });

  }

  const handlePaymentmode = () => {
    referralCode ? navigate('/etisalat/' + subId + '?refcode=' + referralCode) : discountCode ? navigate('/etisalat/' + subId + '?discountCode=' + discountCode) : navigate('/etisalat/' + subId);
  }

  const fetchApp = async () => {
    setLoading(true)
    const res = await fetchData(restAPIs.applicationListing(currentLang?.id, 1, appId));
    setAppData(res.applications[0])
    setLoading(false);
  }

  return (
    <>


      <div className='container-fluid __articles_list' style={{ paddingTop: '11vh', paddingBottom: '6%' }}>
        <div className='inner'>
          <div className="c-heading text-center">
            <div className="c-heading__top">
              <h2 className="text-default"> {appData?.name} </h2>
            </div>
            <div className="c-heading__middle">
              <h3 className="heading-3 text-uppercase">{t('subscription')}</h3>
            </div>
            <div className="c-heading__bottom">
              <div className="c-heading__short-desc">
                <span>{appData?.tagline}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="block ">
          <div className="inner">
            <div className="has-sticker">
              {isLoading ?
                <div className="row justify-content-center">
                  <div className='text-center'>
                    <Spin className='__spin_color' size="large" tip="Loading" />
                  </div>
                </div> :
                <>
                  <div className="row justify-content-center">
                    {data && data.map((item, idx) => {
                      return (
                        <Fade left cascade>
                          <div className="col-md-5 mb-4" key={idx}>
                            <div className="__sub_p_Block" onClick={() => setType(idx)} style={{ border: selectedType === idx ? '2px solid #e3a83a' : '' }}>
                              {selectedType === idx ? <span className='recommend'>SELECTED</span> : ''}
                              {idx === 0 ? <CreditCardOutlined style={{ fontSize: 60, marginBottom: '20px' }} color="secondary" /> : <MobileOutlined style={{ fontSize: 60, marginBottom: '20px' }} color="secondary" />}
                              <h1>{item?.name}</h1>
                              <h2>{item?.description}</h2>
                            </div>
                          </div>
                        </Fade>
                      )
                    })}
                  </div>
                  {
                    selectedType === 0 ?
                      <div >
                        <fieldset style={{ display: 'none' }}>
                          <legend>IPG Connect Request Prerequisites</legend>
                          <select name="environment">
                            <option value="https://www.ipg-online.com/connect/gateway/processing">Prod</option>
                          </select>
                          <p>
                            <label for="sharedsecret">Shared Secret:</label>
                            <input type="text" id='sharedsecret' name="sharedsecret" value="F'5sQ\5ESn" />
                          </p>
                        </fieldset>
                        <form id="paymentForm" method="post" action="#">
                          <input type="hidden" name="hash_algorithm" value="HMACSHA256" />
                          <input type="hidden" name="checkoutoption" value="combinedpage" />
                          <input type="hidden" name="language" value="en_US" />
                          <input type="hidden" name="hashExtended" value={hashExtended} />
                          <input type="hidden" name="storename" value="811189812" />
                          <fieldset style={{ display: 'none' }}>
                            <select name="full_bypass">
                              <option value="false">false</option>
                              <option value="true">true</option>
                            </select>
                            <select name="paymentMethod">
                              <option value="">-</option>
                            </select>
                            <select name="timezone">
                              <option value="Asia/Dubai">Asia/Dubai</option>
                              <option value="Europe/Berlin">Europe/Berlin</option>
                            </select>
                            <input type="text" name="txndatetime" value={dateTime} />
                            <select name="txntype">
                              <option value="sale">Sale</option>
                            </select>
                            <input type="text" name="chargetotal" value={subs?.price} />
                            <select name="authenticateTransaction">
                              <option value="true">true</option>
                              <option value="false">false</option>
                            </select>
                            <select name="currency">
                              <option value="784">AED (784)</option>
                            </select>
                            <input type="text" name="referencedSchemeTransactionId" value="" />
                            <select name="unscheduledCredentialOnFileType">
                              <option value=""></option>
                              <option value="FIRST">FIRST</option>
                              <option value="CARDHOLDER_INITIATED">CARDHOLDER_INITIATED</option>
                              <option value="MERCHANT_INITIATED">MERCHANT_INITIATED</option>
                            </select>
                            <input type="text" name="responseFailURL" size="60" value={callbackURLFailure} />
                            <input type="text" name="responseSuccessURL" size="60" value={callbackURLSuccess} />
                            <select name="assignToken">
                              <option value="false">false</option>
                              <option value="true">true</option>
                            </select>
                            <input type="text" name="hosteddataid" size="60" value="" />
                          </fieldset>
                          <Button
                            id="submit"
                            label='Continue'
                            icon={<ArrowRightOutlined />}
                            className='mt-4'
                            type="submit"
                            style={{ margin: '0 auto' }}
                          />
                        </form>
                      </div>
                      : <Button
                        label='Continue'
                        icon={<ArrowRightOutlined />}
                        className='mt-4'
                        handleOnClick={handlePaymentmode}
                        style={{ margin: '0 auto' }}
                      />
                  }
                </>
              }
            </div>
          </div>
        </div>
      </div>
      <Login
        open={show}
        onClose={handleCloseModel}
      />
    </>
  );
};

export default SubscribePayment;