import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { useSelector } from 'react-redux';
import Login from 'components/modals/Login';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { useEffect } from 'react';
import { Skeleton } from 'antd';
import { Fade } from 'react-reveal';
import NoResult from "assets/images/no_result.png"
import ViewArticleModal from 'components/modals/ViewArticleModal';
import Highlight from 'components/commonComponents/Highlight';

const SearchPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const { userDetails, currentLang } = useSelector((store) => store.commonData);
  const [results, setResults] = useState('')
  const [isLoading, setLoading] = useState(false);
  const [isLastPage, setIsLastPage] = useState(true);
  const [isLoadingPage, setLoadingPage] = useState(false);
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState('');
  const [totalLength, setTotalLength] = useState(0)

  const [searchParams] = useSearchParams();
  const [searchIn, setSearchIn] = useState(searchParams.get('searchIn'))

  const handleCloseModel = () => {
    setShow(false);
  };

  const fetchResults = async () => {
    setLoading(true)
    let searchtext = btoa(unescape(encodeURIComponent(searchParams.get('searchText'))));
    let option = searchParams.get('searchFor')
    let res;
    let searchInTemp = searchParams.get('searchIn');

    if (searchInTemp == 1) {
      res = await fetchData(restAPIs.articlesSearch(searchtext, option, 1, currentLang?.id));
      setResults(res.data)
    }
    if (searchInTemp == 2) {
      res = await fetchData(restAPIs.alwaraqSearch(searchtext, option, 1, currentLang?.id));
      setResults(res.exsearchlist)
    }
    if (searchInTemp == 3) {
      const formData = new FormData();
      formData.append('language', currentLang?.lang);
      formData.append('searchPhrase', searchParams.get('searchText'));
      formData.append('page', 1);
      res = await fetchData(restAPIs.ibnBattutaSearch(formData));
      const newData = res.data.map(item => {
        return {
          ...item, // Spread existing properties of the item
          publicationIcon: item.coverImage,
          name: item.tripName
        };
      });
      setResults(newData)
    }
    if (searchInTemp == 4) {
      res = await fetchData(restAPIs.scienceMuseumSearch(searchtext, option, 1, currentLang?.id));
      const newData = res.articles.map(item => {
        return {
          ...item, // Spread existing properties of the item
          publicationIcon: item.coverImage,
          name: item.name,
          creationdate: item.birthDate
        };
      });
      setResults(newData)
    }
    if (searchInTemp == 5) {
      res = await fetchData(restAPIs.cinemapediaSearch(searchtext, option, 1, currentLang?.id));
      const newData = res.items.map(item => {
        return {
          ...item, // Spread existing properties of the item
          publicationIcon: item.picture,
          name: item.title,
          creationdate: item.displayDate
        };
      });
      setResults(newData)
    }
    if (searchInTemp == 6) {
      res = await fetchData(restAPIs.allProjectsSearch(searchtext, option, 1, currentLang?.id));
      const newData = res.articles.map(item => {
        return {
          ...item, // Spread existing properties of the item
          publicationIcon: item.coverImage,
          name: item.articleName,
          creationdate: item.displayDate
        };
      });
      setResults(newData)
    }
    if (searchInTemp == 7) {
      res = await fetchData(restAPIs.uaehistorySearch(searchtext, option, 1, currentLang?.id));
      const newData = res.articles.map(item => {
        return {
          ...item, // Spread existing properties of the item
          publicationIcon: item.picture,
          name: item.articleName,
          creationdate: item.displayDate,
          categoryName: item.categoryName
        };
      });
      setResults(newData)
    }

    setIsLastPage(res?.isLastPage)
    setLoading(false)
    setTotalLength(res?.total)
  }

  const handleArticleNavigate = (id) => {
    navigate('/article/' + id + '?searchkey=' + searchParams.get('searchText'));
  }
  const handleOpenViewArticle = (data) => {
    setContent(data);
    setOpen(true);
  };

  const handlePageClick = () => {
    let curren = page
    setPage(curren + 1);
    fetchResultsPaginate(curren + 1)
  }

  const fetchResultsPaginate = async (page) => {
    setLoadingPage(true)
    let searchtext = btoa(unescape(encodeURIComponent(searchParams.get('searchText'))));
    let option = searchParams.get('searchFor')
    let res;
    let searchInTemp = searchParams.get('searchIn');

    if (searchInTemp == 1) {
      res = await fetchData(restAPIs.articlesSearch(searchtext, option, page, currentLang?.id));
      setResults(results.concat(res.data))
    }
    if (searchInTemp == 2) {
      res = await fetchData(restAPIs.alwaraqSearch(searchtext, option, page, currentLang?.id));
      setResults(results.concat(res.exsearchlist))
    }
    if (searchInTemp == 3) {
      const formData = new FormData();
      formData.append('language', currentLang?.lang);
      formData.append('searchPhrase', searchParams.get('searchText'));
      formData.append('page', page);
      res = await fetchData(restAPIs.ibnBattutaSearch(formData));
      const newData = res.data.map(item => {
        return {
          ...item, // Spread existing properties of the item
          publicationIcon: item.coverImage,
          name: item.tripName
        };
      });
      setResults(results.concat(newData))
    }
    if (searchInTemp == 4) {
      res = await fetchData(restAPIs.scienceMuseumSearch(searchtext, option, page, currentLang?.id));
      const newData = res.articles.map(item => {
        return {
          ...item, // Spread existing properties of the item
          publicationIcon: item.coverImage,
          name: item.name,
          creationdate: item.birthDate
        };
      });
      setResults(results.concat(newData))
    }
    if (searchInTemp == 5) {
      res = await fetchData(restAPIs.cinemapediaSearch(searchtext, option, page, currentLang?.id));
      const newData = res.items.map(item => {
        return {
          ...item, // Spread existing properties of the item
          publicationIcon: item.picture,
          name: item.title,
          creationdate: item.displayDate
        };
      });
      setResults(results.concat(newData))
    }
    if (searchInTemp == 6) {
      res = await fetchData(restAPIs.allProjectsSearch(searchtext, option, page, currentLang?.id));
      const newData = res.articles.map(item => {
        return {
          ...item, // Spread existing properties of the item
          publicationIcon: item.coverImage,
          name: item.articleName,
          creationdate: item.displayDate
        };
      });
      setResults(results.concat(newData))
    }
    if (searchInTemp == 7) {
      res = await fetchData(restAPIs.uaehistorySearch(searchtext, option, page, currentLang?.id));
      const newData = res.articles.map(item => {
        return {
          ...item, // Spread existing properties of the item
          publicationIcon: item.picture,
          name: item.articleName,
          creationdate: item.displayDate,
          categoryName: item.categoryName
        };
      });
      setResults(results.concat(newData))
    }

    setIsLastPage(res?.isLastPage)
    setLoadingPage(false)
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    setSearchIn(searchParams.get('searchIn'))
    setPage(1)
    fetchResults();
  }, [currentLang, searchParams]);

  const transformDate = (date) => {
    const currentDate = new Date(date);
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const day = currentDate.getDate();
    const month = currentDate.getMonth();
    const year = currentDate.getFullYear();
    const formattedDate = `${day} ${monthNames[month]}, ${year}`;
    return formattedDate;
  };


  const highlightText = (text, highlight) => {
    const parts = text?.split(new RegExp(`(${highlight})`, 'gi'));
    let highlightedHTML = '';
    parts?.forEach((part, i) => {
      if (part.toLowerCase() === highlight.toLowerCase()) {
        highlightedHTML += `<span key=${i} style="font-weight: bold; color: red;">${part}</span>`;
      } else {
        highlightedHTML += part;
      }
    });
    return highlightedHTML;
  };

  return (
    <>
      <>
        <Fade bottom >
          <div className='container __articles_list' style={{ paddingTop: '20vh', paddingBottom: '6%' }}>
            <div className='inner'>
              <div className="c-heading text-center">
                <div className="c-heading__middle">
                  <h3 className="heading-3 text-uppercase">{t('search')} - {searchParams.get('name')}</h3>
                </div>
                <div className="c-heading__bottom">
                  <div className="c-heading__short-desc">
                    <span style={{ fontSize: '2rem', display: 'flex', gap: '10px', justifyContent: 'center', alignItems: 'center' }}>{searchParams.get('searchText')}{totalLength != 0 && <span>({totalLength})</span>}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className='block __articles mt-5'>
              <div className='inner'>
                {searchIn && searchIn == 1 &&
                  <div className="has-sticker">
                    <Fade bottom duration={800} delay={500}>
                      <ul className="grid-courses grid-courses--one-row __search_list">
                        {
                          isLoading ?
                            <>
                              {[...Array(4)].map((item, idx) => {
                                return (
                                  <li className="grid-academy__item">
                                    <div className="card-academy">
                                      <figure className="card-academy__figure">
                                        <Skeleton.Button active block style={{ height: '200px' }} />
                                      </figure>
                                      <div className="card-academy__info">
                                        <div className="card-academy__header">
                                          <div className="card-academy__row">
                                            <Skeleton active />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>

                                )
                              })}
                            </>
                            :
                            <>
                              <>
                                {results && results?.map((item, idx) => (
                                  <li className="grid-academy__item" key={idx}>
                                    <div className="card-academy">
                                      <figure className="card-academy__figure">
                                        <a onClick={() => handleArticleNavigate(item?.id)}>
                                          <img className="card-academy__media lazy lazy--loaded" src={item?.publicationIcon} alt={item?.name} />
                                        </a>
                                      </figure>
                                      <div className="card-academy__info">
                                        <div className="card-academy__header">
                                          <div className="card-academy__row">
                                            <p>{item?.categoryName}</p>
                                            {item?.creationdate && <a style={{ color: '#97a51a' }}>{transformDate(item?.creationdate)}</a>}
                                            <h3 className="card-academy__title">
                                              <a onClick={() => handleArticleNavigate(item?.id)}><Highlight text={item?.name ? item?.name : ''} highlight={searchParams.get('searchText')} /></a>
                                            </h3>
                                            <span className="card-academy__desc" dangerouslySetInnerHTML={{ __html: highlightText(item?.subbody, searchParams.get('searchText')) }} onClick={() => handleArticleNavigate(item?.id)}></span>
                                            <a className="__anchor" onClick={() => handleOpenViewArticle(item)}>
                                              {t('view_article')}
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                ))}
                              </>
                              {isLoadingPage ?
                                <>
                                  {[...Array(4)].map((item, idx) => {
                                    return (
                                      <li className="grid-academy__item">
                                        <div className="card-academy">
                                          <figure className="card-academy__figure">
                                            <Skeleton.Button active block style={{ height: '200px' }} />
                                          </figure>
                                          <div className="card-academy__info">
                                            <div className="card-academy__header">
                                              <div className="card-academy__row">
                                                <Skeleton active />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    )
                                  })}
                                </>
                                : ''
                              }
                              {results?.length != 0 && <> <li></li>
                                <li></li>
                                <li></li>
                                <li></li> </>}
                              {results?.length == 0 && <div className='__no_result'><img src={NoResult} /> <strong>No Articles found.!</strong> <span>Please change your selection.</span></div>}
                            </>
                        }



                      </ul>
                      {isLoading ? '' :
                        <div className='__pagination' style={{ justifyContent: 'center' }}>
                          {isLastPage ? '' : <button onClick={handlePageClick}>{t('more')} </button>}
                        </div>
                      }
                    </Fade>
                  </div>
                }
                {
                  searchIn && searchIn == 2 &&
                  <div className="has-sticker">
                    {isLoading ?
                      <div className='__awards_list'>
                        {[...Array(5)].map((item, idx) => {
                          return (
                            <a className="singleBook" key={idx} >
                              <div style={{ position: 'relative', background: '#0001' }}>
                                <Skeleton.Button active block style={{ height: '250px' }} />
                              </div>
                              <div className="singleBookInner">
                                <Skeleton active />
                              </div>
                            </a>
                          )
                        })}
                      </div>
                      :
                      <div className='__awards_list'>
                        {
                          results && results?.map((item, i) => {
                            return (
                              <a className="singleBook" key={i} href={'https://alwaraq.net/books/' + item?.bookid + '?title=' + item.bookname} target='_blank'>
                                <div style={{ position: 'relative', background: '#0001' }}>
                                  <img src={item.coverImage} />
                                  <div className="bookBind"></div>
                                  <span className="subJect">{item.subjectName}</span>
                                </div>
                                <div className="singleBookInner">
                                  <h1><Highlight text={item?.bookname ? item?.bookname : ''} highlight={searchParams.get('searchText')} /></h1>
                                  <h5>{item.author}</h5>
                                </div>
                              </a>
                            )
                          })
                        }
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>

                        {isLoadingPage ?
                          <>
                            {[...Array(3)].map((item, idx) => {
                              return (
                                <a className="singleBook" key={idx} >
                                  <div style={{ position: 'relative', background: '#0001' }}>
                                    <Skeleton.Button active block style={{ height: '250px' }} />
                                  </div>
                                  <div className="singleBookInner">
                                    <Skeleton active />
                                  </div>
                                </a>
                              )
                            })}
                          </>
                          : ''
                        }
                      </div>
                    }
                    {results?.length == 0 && <div className='__no_result'><img src={NoResult} /> <strong>No Books found.!</strong> <span>Please change your selection.</span></div>}
                    {isLoading ? '' :
                      <div className='__pagination' style={{ justifyContent: 'center' }}>
                        {isLastPage ? '' : <button onClick={handlePageClick}>{t('more')} </button>}
                      </div>
                    }
                  </div>
                }

                {searchIn && (searchIn == 3 || searchIn == 6) &&
                  <div className="has-sticker">
                    <Fade bottom duration={800} delay={500}>
                      <ul className="grid-courses grid-courses--one-row __search_list">
                        {
                          isLoading ?
                            <>
                              {[...Array(4)].map((item, idx) => {
                                return (
                                  <li className="grid-academy__item">
                                    <div className="card-academy">
                                      <figure className="card-academy__figure">
                                        <Skeleton.Button active block style={{ height: '200px' }} />
                                      </figure>
                                      <div className="card-academy__info">
                                        <div className="card-academy__header">
                                          <div className="card-academy__row">
                                            <Skeleton active />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>

                                )
                              })}
                            </>
                            :
                            <>
                              <>
                                {results && results?.map((item, idx) => (
                                  <li className="grid-academy__item" key={idx}>
                                    <div className="card-academy">
                                      <figure className="card-academy__figure">
                                        <a href={'https://ibnbatuta.ae/trip/' + item?.tripId} target='_blank'>
                                          <img className="card-academy__media lazy lazy--loaded" src={item?.publicationIcon} alt={item?.name} />
                                        </a>
                                      </figure>
                                      <div className="card-academy__info">
                                        <div className="card-academy__header">
                                          <div className="card-academy__row">
                                            <p><a href={'https://ibnbatuta.ae/trip/' + item?.tripId} target='_blank'>{item?.categoryName}</a></p>
                                            {item?.creationdate && <a style={{ color: '#97a51a' }} href={'https://ibnbatuta.ae/trip/' + item?.tripId} target='_blank'>{transformDate(item?.creationdate)}</a>}
                                            <h3 className="card-academy__title">
                                              <a href={'https://ibnbatuta.ae/trip/' + item?.tripId} target='_blank'><Highlight text={item?.name ? item?.name : ''} highlight={searchParams.get('searchText')} /></a>
                                            </h3>
                                            <a className="card-academy__desc" dangerouslySetInnerHTML={{ __html: highlightText(item?.subbody, searchParams.get('searchText')) }} href={'https://ibnbatuta.ae/trip/' + item?.tripId} target='_blank'></a>
                                            <a className="__anchor" onClick={() => handleOpenViewArticle(item)}>
                                              {t('view_article')}
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                ))}
                              </>

                              <>
                                {isLoadingPage ?
                                  <>
                                    {[...Array(4)].map((item, idx) => {
                                      return (
                                        <li className="grid-academy__item">
                                          <div className="card-academy">
                                            <figure className="card-academy__figure">
                                              <Skeleton.Button active block style={{ height: '200px' }} />
                                            </figure>
                                            <div className="card-academy__info">
                                              <div className="card-academy__header">
                                                <div className="card-academy__row">
                                                  <Skeleton active />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </li>
                                      )
                                    })}
                                  </>
                                  : ''
                                }</>

                              {results?.length != 0 && <> <li></li>
                                <li></li>
                                <li></li>
                                <li></li> </>}
                              {results?.length == 0 && <div className='__no_result'><img src={NoResult} /> <strong>No Results found.!</strong> <span>Please change your selection.</span></div>}
                            </>
                        }



                      </ul>
                      {isLoading ? '' :
                        <div className='__pagination' style={{ justifyContent: 'center' }}>
                          {isLastPage ? '' : <button onClick={handlePageClick}>{t('more')} </button>}
                        </div>
                      }
                    </Fade>
                  </div>
                }

                {searchIn && searchIn == 4 &&
                  <div className="has-sticker">
                    <Fade bottom duration={800} delay={500}>
                      <ul className="grid-courses grid-courses--one-row __search_list">
                        {
                          isLoading ?
                            <>
                              {[...Array(4)].map((item, idx) => {
                                return (
                                  <li className="grid-academy__item">
                                    <div className="card-academy">
                                      <figure className="card-academy__figure">
                                        <Skeleton.Button active block style={{ height: '200px' }} />
                                      </figure>
                                      <div className="card-academy__info">
                                        <div className="card-academy__header">
                                          <div className="card-academy__row">
                                            <Skeleton active />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>

                                )
                              })}
                            </>
                            :
                            <>
                              <>
                                {results && results?.map((item, idx) => (
                                  <li className="grid-academy__item" key={idx}>
                                    <div className="card-academy">
                                      <figure className="card-academy__figure">
                                        <a onClick={() => navigate('/project/9')}>
                                          <img className="card-academy__media lazy lazy--loaded" src={item?.publicationIcon} alt={item?.name} />
                                        </a>
                                      </figure>
                                      <div className="card-academy__info">
                                        <div className="card-academy__header">
                                          <div className="card-academy__row">
                                            <p><a onClick={() => navigate('/project/9')}>{item?.categoryName}</a></p>
                                            {item?.creationdate && <a style={{ color: '#97a51a' }} onClick={() => navigate('/project/9')} >{transformDate(item?.creationdate)}</a>}
                                            <h3 className="card-academy__title">
                                              <a onClick={() => navigate('/project/9')}><Highlight text={item?.name ? item?.name : ''} highlight={searchParams.get('searchText')} /></a>
                                            </h3>
                                            <a className="card-academy__desc" dangerouslySetInnerHTML={{ __html: highlightText(item?.subbody, searchParams.get('searchText')) }} onClick={() => navigate('/project/9')} ></a>
                                            <a className="__anchor" onClick={() => handleOpenViewArticle(item)}>
                                              {t('view_article')}
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                ))}
                              </>

                              {isLoadingPage ?
                                <>
                                  {[...Array(4)].map((item, idx) => {
                                    return (
                                      <li className="grid-academy__item">
                                        <div className="card-academy">
                                          <figure className="card-academy__figure">
                                            <Skeleton.Button active block style={{ height: '200px' }} />
                                          </figure>
                                          <div className="card-academy__info">
                                            <div className="card-academy__header">
                                              <div className="card-academy__row">
                                                <Skeleton active />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    )
                                  })}
                                </>
                                : ''
                              }

                              {results?.length != 0 && <> <li></li>
                                <li></li>
                                <li></li>
                                <li></li> </>}
                              {results?.length == 0 && <div className='__no_result'><img src={NoResult} /> <strong>No Results found.!</strong> <span>Please change your selection.</span></div>}
                            </>
                        }



                      </ul>
                      {isLoading ? '' :
                        <div className='__pagination' style={{ justifyContent: 'center' }}>
                          {isLastPage ? '' : <button onClick={handlePageClick}>{t('more')} </button>}
                        </div>
                      }
                    </Fade>
                  </div>
                }

                {searchIn && searchIn == 7 &&
                  <div className="has-sticker">
                    <Fade bottom duration={800} delay={500}>
                      <ul className="grid-courses grid-courses--one-row __search_list">
                        {
                          isLoading ?
                            <>
                              {[...Array(4)].map((item, idx) => {
                                return (
                                  <li className="grid-academy__item">
                                    <div className="card-academy">
                                      <figure className="card-academy__figure">
                                        <Skeleton.Button active block style={{ height: '200px' }} />
                                      </figure>
                                      <div className="card-academy__info">
                                        <div className="card-academy__header">
                                          <div className="card-academy__row">
                                            <Skeleton active />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>

                                )
                              })}
                            </>
                            :
                            <>
                              <>
                                {results && results?.map((item, idx) => (
                                  <li className="grid-academy__item" key={idx}>
                                    <div className="card-academy">
                                      <figure className="card-academy__figure">
                                        <a href={'https://uaehistory.ae/#/article/' + item?.articleId} target='_blank'>
                                          <img className="card-academy__media lazy lazy--loaded" src={item?.publicationIcon} alt={item?.name} />
                                        </a>
                                      </figure>
                                      <div className="card-academy__info">
                                        <div className="card-academy__header">
                                          <div className="card-academy__row">
                                            <p><a href={'https://uaehistory.ae/#/article/' + item?.articleId} target='_blank'>{item?.categoryName}</a></p>
                                            {item?.creationdate && <a style={{ color: '#97a51a' }} href={'https://uaehistory.ae/#/article/' + item?.articleId} target='_blank'>{transformDate(item?.creationdate)}</a>}
                                            <h3 className="card-academy__title">
                                              <a href={'https://uaehistory.ae/#/article/' + item?.articleId} target='_blank'><Highlight text={item?.name ? item?.name : ''} highlight={searchParams.get('searchText')} /></a>
                                            </h3>
                                            <a className="card-academy__desc" dangerouslySetInnerHTML={{ __html: highlightText(item?.subbody, searchParams.get('searchText')) }} href={'https://uaehistory.ae/#/article/' + item?.articleId} target='_blank'></a>
                                            <a className="__anchor" onClick={() => handleOpenViewArticle(item)}>
                                              {t('view_article')}
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                ))}
                              </>

                              {isLoadingPage ?
                                <>
                                  {[...Array(4)].map((item, idx) => {
                                    return (
                                      <li className="grid-academy__item">
                                        <div className="card-academy">
                                          <figure className="card-academy__figure">
                                            <Skeleton.Button active block style={{ height: '200px' }} />
                                          </figure>
                                          <div className="card-academy__info">
                                            <div className="card-academy__header">
                                              <div className="card-academy__row">
                                                <Skeleton active />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    )
                                  })}
                                </>
                                : ''
                              }

                              {results?.length != 0 && <> <li></li>
                                <li></li>
                                <li></li>
                                <li></li> </>}
                              {results?.length == 0 && <div className='__no_result'><img src={NoResult} /> <strong>No Results found.!</strong> <span>Please change your selection.</span></div>}
                            </>
                        }



                      </ul>
                      {isLoading ? '' :
                        <div className='__pagination' style={{ justifyContent: 'center' }}>
                          {isLastPage ? '' : <button onClick={handlePageClick}>{t('more')} </button>}
                        </div>
                      }
                    </Fade>
                  </div>
                }


                {searchIn && searchIn == 5 &&
                  <div className="has-sticker">
                    <Fade bottom duration={800} delay={500}>
                      <ul className="grid-courses grid-courses--one-row __search_list">
                        {
                          isLoading ?
                            <>
                              {[...Array(4)].map((item, idx) => {
                                return (
                                  <li className="grid-academy__item">
                                    <div className="card-academy">
                                      <figure className="card-academy__figure">
                                        <Skeleton.Button active block style={{ height: '200px' }} />
                                      </figure>
                                      <div className="card-academy__info">
                                        <div className="card-academy__header">
                                          <div className="card-academy__row">
                                            <Skeleton active />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>

                                )
                              })}
                            </>
                            :
                            <>
                              <>
                                {results && results?.map((item, idx) => (
                                  <li className="grid-academy__item" key={idx}>
                                    <div className="card-academy">
                                      <figure className="card-academy__figure">
                                        <a href={`https://cinemapedia.net/${item?.focusType == 1 ? ('movie/' + item?.movieId) : ('artist/' + item?.artistId)}`} target='_blank'>
                                          <img className="card-academy__media lazy lazy--loaded" src={item?.publicationIcon} alt={item?.name} />
                                        </a>
                                      </figure>
                                      <div className="card-academy__info">
                                        <div className="card-academy__header">
                                          <div className="card-academy__row">
                                            <p><a href={`https://cinemapedia.net/${item?.focusType == 1 ? ('movie/' + item?.movieId) : ('artist/' + item?.artistId)}`} target='_blank'>{item?.categoryName}</a></p>
                                            {item?.creationdate && <a style={{ color: '#97a51a' }} href={`https://cinemapedia.net/${item?.focusType == 1 ? ('movie/' + item?.movieId) : ('artist/' + item?.artistId)}`} target='_blank'>{transformDate(item?.creationdate)}</a>}
                                            <h3 className="card-academy__title">
                                              <a href={`https://cinemapedia.net/${item?.focusType == 1 ? ('movie/' + item?.movieId) : ('artist/' + item?.artistId)}`} target='_blank'><Highlight text={item?.name ? item?.name : ''} highlight={searchParams.get('searchText')} /></a>
                                            </h3>
                                            <a className="card-academy__desc" dangerouslySetInnerHTML={{ __html: highlightText(item?.subbody, searchParams.get('searchText')) }} href={`https://cinemapedia.net/${item?.focusType == 1 ? ('movie/' + item?.movieId) : ('artist/' + item?.artistId)}`} target='_blank'></a>
                                            <a className="__anchor" onClick={() => handleOpenViewArticle(item)}>
                                              {t('view_article')}
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                ))}
                              </>

                              {isLoadingPage ?
                                <>
                                  {[...Array(4)].map((item, idx) => {
                                    return (
                                      <li className="grid-academy__item">
                                        <div className="card-academy">
                                          <figure className="card-academy__figure">
                                            <Skeleton.Button active block style={{ height: '200px' }} />
                                          </figure>
                                          <div className="card-academy__info">
                                            <div className="card-academy__header">
                                              <div className="card-academy__row">
                                                <Skeleton active />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    )
                                  })}
                                </>
                                : ''
                              }

                              {results?.length != 0 && <> <li></li>
                                <li></li>
                                <li></li>
                                <li></li> </>}
                              {results?.length == 0 && <div className='__no_result'><img src={NoResult} /> <strong>No Results found.!</strong> <span>Please change your selection.</span></div>}
                            </>
                        }



                      </ul>
                      {isLoading ? '' :
                        <div className='__pagination' style={{ justifyContent: 'center' }}>
                          {isLastPage ? '' : <button onClick={handlePageClick}>{t('more')} </button>}
                        </div>
                      }
                    </Fade>
                  </div>
                }

                <Login
                  open={show}
                  onClose={handleCloseModel}
                />
                <ViewArticleModal open={open} onClose={() => setOpen(false)} content={content} searchkey={searchParams.get('searchText')} />
              </div>
            </div>
          </div>
        </Fade>
      </>
    </>
  );
};

export default SearchPage;