// Core
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

// // Styles
import './styles.scss';

// // Assets

import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';
import { Skeleton } from 'antd';

import { Fade } from 'react-reveal';

const BookView = () => {
    const { currentLang } = useSelector((store) => store.commonData);
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState('');
    const { bookId } = useParams();
    const { t } = useTranslation();

    const fetchBook = async () => {
        setLoading(true)
        const res = await fetchData(restAPIs.bookInfo(bookId, currentLang?.id))
        setData(res.books[0])
        setLoading(false);
    }
    useEffect(() => {
        fetchBook();
    }, [currentLang]);

    return (
        <>
            {
                isLoading ?
                    <div className='container __articles_list' style={{ paddingTop: '20vh', paddingBottom: '6%' }}>
                        <div className='inner text-center'>
                            <Skeleton.Input active style={{ width: '340px', marginBottom: '10px' }} />
                            <Skeleton.Button active block style={{ height: '340px', marginBottom: '10px' }} />
                            <Skeleton active />
                        </div>
                    </div>
                    :
                    <Fade bottom >
                        <div className='container __articles_list' style={{ paddingTop: '20vh', paddingBottom: '6%' }}>
                            <div className='inner'>
                                <div className="c-heading text-center">
                                    <div className="c-heading__top">
                                        <a href={`https://alwaraq.net/books-under/${data?.subjectName}/${data?.subjectId}`} target='_blank' ><h2 className="text-default"> {data?.subjectName}</h2></a>
                                    </div>
                                    <div className="c-heading__middle">
                                        <h3 className="heading-3 text-uppercase">{data?.name}</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="block __articles">
                                <div className="inner">
                                    <figure>
                                        <img className='__cover_im_book imageLoader' src={data?.coverImage} alt={data?.name} />
                                    </figure>
                                    <p className='_____descr_book' dangerouslySetInnerHTML={{ __html: data?.addinfo }} ></p>
                                    <p className='mt-3'><a className='__read_now' href={'https://alwaraq.net/books/' + data?.bookid} target='_blank' >{t('learn_more')}</a></p>
                                </div>
                            </div>

                            <div className='inner mt-5'>
                                <div className='d-flex flex-column align-items-center'>
                                    <img className='imageLoader' src={data?.authorImage} alt={data?.author} style={{ width: '150px', height: '150px', objectFit: 'cover', borderRadius: '15px' }} />
                                    <a className='mt-3' href={'https://alwaraq.net/books/author/' + data?.authorid} target='_blank' >{data?.author}</a>
                                </div>
                            </div>
                        </div>
                    </Fade>
            }
        </>
    );
};

export default BookView;
