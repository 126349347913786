// Core
import React, { useEffect, useState } from 'react';

// Styles
import './styles.scss';

// Assets
import { useNavigate } from 'react-router-dom';
import { Fade } from 'react-reveal';
import { Skeleton } from 'antd';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowRightOutlined,CrownOutlined } from '@ant-design/icons';
import ViewAppModal from 'components/modals/ViewAppModal';
import { useTranslation } from 'react-i18next';

import { PlusOutlined } from '@ant-design/icons';
import Button from 'components/commonComponents/Button';

const Projects = () => {
    const { currentLang, projectList } = useSelector((store) => store.commonData);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingPage, setLoadingPage] = useState(false);
    const [isLastPage, setIsLastPage] = useState(false);
    const [page, setPage] = useState(1);
    const [data, setData] = useState([])
    const [content, setContent] = useState('')
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchApplications();
    }, [currentLang]);

    const handleNavigate = (id) => {
        navigate('/project/' + id)
    }

    const fetchApplications = async () => {
        setIsLoading(true)
        const res = await fetchData(restAPIs.applicationListing(currentLang?.id, 1));
        setData(res.applications)

        if (res?.statusCode === 200) {
            dispatch({
                type: 'projectList',
                value: res.applications
            });
            setIsLastPage(res.isLastPage)
        }
        setIsLoading(false);
    }

    const handleOpenViewModal = (data) => {
        setContent(data)
        setOpen(true)
    }

    const fetchPaginateApplications = async (page) => {
        setLoadingPage(true)
        const res = await fetchData(restAPIs.applicationListing(currentLang?.id, page));
        setData(res.articles)
        setLoadingPage(false);

        if (res?.statusCode === 200) {
            dispatch({
                type: 'projectList',
                value: projectList.concat(res.applications)
            });
            setIsLastPage(res.isLastPage)
        }
    }

    const handlePageClick = () => {
        let curren = page
        setPage(curren + 1);
        fetchPaginateApplications(curren + 1)
    }


    return (
        <>
            <div className='container-fluid __articles_list' style={{ paddingTop: '11vh', paddingBottom: '6%' }}>
                <div className='inner'>
                    <div className="c-heading text-center">
                        <div className="c-heading__top">
                            <h2 className="text-default"></h2>
                        </div>
                        <div className="c-heading__middle">
                            <h3 className="heading-3 text-uppercase">{t('projects')}</h3>
                        </div>
                        <div className="c-heading__bottom">
                            <div className="c-heading__short-desc">
                                <span>{t('delveIntoInformativeArticlesDiscussingTheRichContentAndThemesExploredWithinEachProject')}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="block ">                    
                    <div className="inner">            
                        <div className="has-sticker">
                            <Fade bottom duration={800} delay={300}>
                                <ul className="grid-courses grid-courses--one-row">
                                    {
                                        isLoading ?
                                            <>
                                                {[...Array(4)].map((item, idx) => {
                                                    return (
                                                        <li className="grid-academy__item">
                                                            <div className="card-academy">
                                                                <figure className="card-academy__figure">
                                                                    <Skeleton.Button active block style={{ height: '200px' }} />
                                                                </figure>
                                                                <div className="card-academy__info">
                                                                    <div className="card-academy__header">
                                                                        <div className="card-academy__row">
                                                                            <Skeleton active />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>

                                                    )
                                                })}
                                            </>
                                            :
                                            projectList?.map((item, idx) => {
                                                return (
                                                    <li className="grid-academy__item">
                                                        <div className="card-academy" style={{ background: '#cccccc0f' }}>
                                                            <figure className="card-academy__figure" style={{ cursor: 'pointer' }} onClick={() => handleNavigate(item?.appId)}>
                                                                <a >
                                                                    <img className="imageLoader card-academy__media lazy lazy--loaded" data-controller="lazyload-image" src={item?.coverImage} />
                                                                </a>
                                                            </figure>
                                                            <div className="card-academy__info">
                                                                <div className="card-academy__header" onClick={() => handleNavigate(item?.appId)} style={{ cursor: 'pointer' }}>
                                                                    <div className="card-academy__row d-flex flex-column">
                                                                        <h3 className="card-academy__title">
                                                                            <a>{item?.name}</a>
                                                                        </h3>
                                                                        <span className="card-academy__desc" dangerouslySetInnerHTML={{ __html: item?.description }} ></span>
                                                                    </div>
                                                                </div>
                                                                <div className="card-academy__footer">
                                                                    <div className="card-academy__row">
                                                                        <div className="card-academy__by">
                                                                            <img src={item?.applicationIcon} style={{ width: '35px', height: '35px', borderRadius: '40px', objectFit: 'cover' }} />
                                                                            <strong style={{ margin: '0 10px' }}>{item?.name}</strong>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-academy__row">
                                                                        <div className="card-academy__stats">
                                                                            {item?.googleStoreLink && <div className="card-academy__stat">
                                                                                <strong><a href={item?.googleStoreLink} target="_blank">{t('playstore')}</a></strong>
                                                                            </div>}
                                                                            {item?.appStoreLink && <div className="card-academy__stat">
                                                                                <strong><a href={item?.appStoreLink} target="_blank">{t('appstore')}</a></strong>
                                                                            </div>}
                                                                            {item?.websiteLink && <div className="card-academy__stat">
                                                                                <strong><a href={item?.websiteLink} target="_blank">{t('web')}</a></strong>
                                                                            </div>}
                                                                        </div>
                                                                        <div className="card-academy__more">
                                                                            <a onClick={() => handleOpenViewModal(item)}>
                                                                                <ArrowRightOutlined />
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                )
                                            })
                                    }

                                    {isLoadingPage ?
                                        <>
                                            {[...Array(4)].map((item, idx) => {
                                                return (
                                                    <li className="grid-academy__item">
                                                        <div className="card-academy">
                                                            <figure className="card-academy__figure">
                                                                <Skeleton.Button active block style={{ height: '200px' }} />
                                                            </figure>
                                                            <div className="card-academy__info">
                                                                <div className="card-academy__header">
                                                                    <div className="card-academy__row">
                                                                        <Skeleton active />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>

                                                )
                                            })}
                                        </>
                                        : ''

                                    }

                                </ul>
                            </Fade>
                        </div>
                    </div>
                </div>
                {isLoading ? '' : <div className="__block">
                    <div className="inner">
                        <div className="c-view-all">
                            <div className="c-view-all__row  text-medium">
                                {isLastPage ? '' : <a onClick={() => handlePageClick()} className="bt-ico-left">
                                    <strong className="link-underlined">{t('more')}</strong>
                                    <PlusOutlined />
                                </a>}
                            </div>
                        </div>
                    </div>

                    <div className="__subscribe_fn">
                        <p>{t('diveIntoACuratedCollectionOfExclusiveContentAvailableOnlyToOurPremiumSubscribers')} <br/><b>{t('toSubscribeAllEvApps')}</b></p>
                        <Button icon={<CrownOutlined />} label='Subscribe' handleOnClick={() => navigate('/subscribe/100')} />
                    </div>
                </div>
                }
            </div>
            <ViewAppModal open={open} onClose={() => setOpen(false)} content={content} />
        </>
    );
};

export default Projects;
