// Core
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Styles
import './styles.scss';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { Modal } from 'antd';

import { Carousel } from "react-responsive-carousel";
import { useEffect } from 'react';

import { AndroidOutlined, IeOutlined, AppleOutlined } from '@ant-design/icons';

const ViewAppModal = (props) => {
  const { onClose, open, content } = props;
  const { t } = useTranslation();
  return (
    <>
      <Modal
        open={open}
        onCancel={onClose}
        width={1000}
        style={{ borderRadius: '10px', top: 20 }}
        className="__detail_modal"
        keyboard={false}
        footer={null}
        closable={false}
        centered
      >

        <div className="inner">

        </div>
        <div className="inner" style={{paddingTop:'50px', paddingBottom:'50px'}}>
          <div className="c-work">
            <div className="c-work__left">
              <div className="m-asset">
                <div>
                  {content?.tagline}
                </div>
                <div className="m-asset__header">
                  <h3 className="m-asset__title">{content?.name}</h3>
                </div>
                <div className="m-asset__buy">
                  <div className="m-asset__from">
                    <a href={content?.googleStoreLink} target='_blank'>
                    <div><small>{t('playstore')}</small></div>
                    <div className="m-asset__price"><AndroidOutlined /></div>
                    </a>
                  </div>
                  <div className="m-asset__from">
                    <a href={content?.appStoreLink} target="_blank">
                    <div><small>{t('appstore')}</small></div>
                    <div className="m-asset__price">  <AppleOutlined /></div>
                    </a>
                  </div>
                  <div className="m-asset__from">
                    <a href={content?.websiteLink} target="_blank">
                    <div><small>{t('web')}</small></div>
                    <div className="m-asset__price"> <IeOutlined />
                    </div>
                    </a>
                  </div>
                </div>
                <div className="m-asset__desc">
                  <p dangerouslySetInnerHTML={{ __html: content?.description }} ></p>
                </div>
                <div className="m-asset__footer">
                  <div className="m-asset__curated">
                    Developed by Electronicvillage
                  </div>
                  <div className="m-asset__date">
                  </div>
                </div>
              </div>
            </div>
            <div className="c-work__right"  dir='ltr'>
              <Carousel showArrows={false} showStatus={false} showIndicators={false} swipeable emulateTouch infiniteLoop>
                {content?.screenshots?.map((item, idx) => {
                  return (
                    <div style={{ borderRadius: '10px', overflow: "hidden" }}>
                      <img alt="" src={item} style={{objectFit:'contain'}}/>
                    </div>
                  )
                })
                }
              </Carousel>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ViewAppModal;

ViewAppModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
