// Core
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/commonComponents/Button';
import PropTypes from 'prop-types';

// Styles
import './styles.scss';
import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';
import { useState } from 'react';
import InputField from 'components/commonComponents/InputField';
import { useDispatch } from 'react-redux';
import { APP_ID } from 'config/constants';
import { Alert, Modal } from 'antd';

const AddInvitation = (props) => {
  const { onClose, open } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [dataSet, setData] = useState({});
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { invitationkey } = dataSet;
  const [errorAlert, setErrorAlert] = useState({ msg: '', key: Math.random(), open: false, severity: '' })

  const handleOnChange = (key) => (_value) => {
    if (_value) {
      setError({
        ...error,
        [key]: null
      });
    }
    setData({
      ...dataSet,
      [key]: _value
    });
  };


  const handleSave = async () => {
    const errors = {
      invitationkey: !invitationkey,
    };

    setError(errors);
    let isFormValid = Object.values(errors).every((item) => !item);
    if (!isFormValid) return;
    setIsLoading(true);
    const formData = new FormData();
    formData.append('action', 'add')
    formData.append('appId', APP_ID)
    formData.append('invitationCode', invitationkey)
    const res = await fetchData(restAPIs.subscriptionForm(formData));
    if (res.statusCode === 200) {
      if (res.isPremium) {
        setData({});
        setError({});
        setErrorAlert({ msg: "Subscribed successfully. !", key: Math.random(), open: true, severity: 'success' });
        setTimeout(() => {
          setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
        }, 3000);
        dispatch({
          type: 'userDetails',
          value: res
        });
        localStorage.setItem('userDetails', JSON.stringify(res));
        onClose();
      } else {
        setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true, severity: 'error' });
        setTimeout(() => {
          setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
        }, 3000);
      }
    }
    setIsLoading(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      onClose();
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className='__common_model_class'
        keyboard={false}
        maskClosable={false}
        footer={null}
        closable={false}
      >
        <div className='__common_model_content'>
        <h3 className='____common_model_class_head'> {t('add_invitation_key')}</h3>
        <div style={{ marginBottom: '20px', width: '100%' }}>
          <InputField
            onChange={handleOnChange('invitationkey')}
            value={invitationkey}
            placeholder='Enter the Key'
            type='text'
            error={error.invitationkey}
          />
        </div>
        <div className='d-flex align-items-center justify-content-end' style={{ marginTop: ' 15px', gridGap: '10px' }}>
          <a onClick={() => handleClose(false)} style={{ cursor: 'pointer', margin: '0 10px' }}>{t('cancel')}</a>
          <Button
            withLoader
            loading={isLoading}
            disabled={isLoading}
            handleOnClick={handleSave}
            label={t('submit')}
          />
        </div>
        {errorAlert && errorAlert.open ?
          <Alert severity={errorAlert.severity} sx={{ width: '100%' }}>
            {errorAlert.msg}
          </Alert> : ''}
        </div>
      </Modal>
    </>
  );
};

export default AddInvitation;

AddInvitation.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
