// Core
import React from 'react';

// Components
import AppRouter from 'routes';

// Style
import 'assets/styles/globalstyles.scss';
import "react-responsive-carousel/lib/styles/carousel.min.css";

const App = () => {
  return <AppRouter />;
};

export default App;
